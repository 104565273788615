import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory, useLocation } from "react-router-dom";
import { navigateTo, createMultiPartFormBody, getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, Status, AccessRight, AlertTypes, DataName, ViewMode } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import LeadAppointment from '../../components/custom/LeadAppointment';
import CompleteLead from '../../components/custom/CompleteLead'; 
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import { useForm } from "react-hook-form";
import ApiEngine from '../../util/ApiEngine.js';
import moment from 'moment';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageLead = props => {
	const { t } = useTranslation();
	let _history = useHistory();
	var _location = useLocation();
	const [leadData, setLeadData] = useState([]);
	const [selectedLeadId, setSelectedLeadId] = useState(null);
	const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);
	const _language = useSelector(state => state.appState.language);
	const [appointmentModalVisible, setAppointmentModalVisible] = useState(false);
	var _dispatch = useDispatch();
	const _userData = useSelector(state => state.authState.userData);
	const { handleSubmit, register } = useForm();

	let _tableColumns = useMemo(() => [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => {
				return (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
							<DropdownMenu>
								{_userData.userId == row.original.userId && (!row.original.isComplete || row.original.hasAppointment) && <DropdownItem onClick={() => {
									setSelectedLeadId(row.original.id);
									setAppointmentModalVisible(true);
								}}><span className="text-warning">{t(row.original.hasAppointment ? "VIEW_APPOINTMENT" : "CREATE_APPOINTMENT")}</span></DropdownItem>}
								<DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_LEAD_DETAIL, row.original) }}><span className="text-warning">{t((checkIfPermissionExist(AccessRight._APPOINTMENT_CONTROL_PERMISSION, true) || _userData.userId == row.original.userId) && !row.original.isComplete ? "EDIT" : "VIEW")}</span></DropdownItem>
								{(_userData.isSuperAdmin || _userData.userId == row.original.userId) && stringIsNullOrEmpty(row.original.customerId) && <DropdownItem onClick={() => { saveAsCustomer(row.original.id) }}><span className="text-warning">{t("SAVE_TO_CUSTOMER")}</span></DropdownItem>}
								{(_userData.isSuperAdmin || _userData.userId == row.original.userId) && !row.original.isComplete && <DropdownItem onClick={() => {
									setSelectedLeadId(row.original.id);
									setIsCompleteModalVisible(true)
								}}><span className="text-success">{t("COMPLETE")}</span></DropdownItem>}
								{checkIfPermissionExist(AccessRight._LEAD_CONTROL_PERMISSION, true) && row.original.isComplete && _userData.isAdmin && <DropdownItem onClick={() => { reopenLead(row.original.id) }}><span className="text-warning">{t("REOPEN")}</span></DropdownItem>}
								{checkIfPermissionExist(AccessRight._LEAD_CONTROL_PERMISSION, true) && !row.original.isComplete && (_userData.userId == row.original.createdById || _userData.isAdmin) && <DropdownItem onClick={() => { deleteLead(row.original) }}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				)
			},
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" }
		},
		{
			Header: t("CUSTOMER_NAME"),
			accessor: "customerName"
		},
		{
			Header: t("PHONE"),
			accessor: "phoneNumber"
		},
		{
			Header: t("ADDRESS"),
			accessor: "address"
		},
		{
			Header: t("EXPECTED_SERVICE_TIME"),
			accessor: "serviceTime"
		},
		{
			Header: t("END_SERVICE_TIME"),
			accessor: "endServeTime",
			Cell: ({ row }) => {
				if (stringIsNullOrEmpty(row.original.endServeTime)) {
					return "-";
				}
				else {
					var endServiceTime = moment(row.original.endServeTime, "YYYY-MM-DD HH:mm");
					var expectedService = moment(row.original.serviceTime, "YYYY-MM-DD HH:mm");

					var badge = { color: "red" };
					if (expectedService.isAfter(endServiceTime)) {
						badge = {color:"green"};
					}
					return <span style={badge}>{row.original.endServeTime}</span>;
                }
			},
		},
		{
			Header: t("APPOINTED"),
			Cell: ({ row }) => {
				if (row.original.isComplete) {
					return <span className="badge badge-secondary badge-green">{t("COMPLETE")}</span>;
				}
				else if (row.original.hasAppointment) {
					return <span className="badge badge-secondary badge-info">{t("APPOINTED")}</span>;
				}
				else {
					return <span className="badge badge-secondary badge-warning">{t("NO_APPOINTMENT")}</span>
				}
			},
		},
	], [_language]);

	const reopenLead = async (leadId) => {
		var responseJson = await ApiEngine.post(ApiUrl._API_REOPEN_LEAD + "?leadId=" + leadId);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			handleSubmit(onSubmit)();
		}

		_dispatch(showMessage({
			type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
			content: t(responseJson[ApiKey._API_MESSAGE_KEY])
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	const saveAsCustomer = async (leadId) => {
		var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_CUSTOMER_FROM_LEAD + "?leadId=" + leadId);

		_dispatch(showMessage({
			type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
			content: t(responseJson[ApiKey._API_MESSAGE_KEY])
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	useEffect(() => {
		handleSubmit(onSubmit)();
	}, []);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function deleteLead(lead) {
		_dispatch(showMessage({
			type: AlertTypes._WARNING,
			content: t("DELETE_SETTING_CONFIRMATION"),
			onConfirm: () => performDeleteLead(lead),
			showCancel: true
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function performDeleteLead(lead) {
		let params = {
			"status": false,
			"id": lead.id
		}

		var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._LEAD_ASSIGNMENT, createMultiPartFormBody(params));

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			handleSubmit(onSubmit)();
		}

		_dispatch(showMessage({
			type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
			content: t(responseJson[ApiKey._API_MESSAGE_KEY])
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	const onSubmit = async (data, e) => {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_LEAD + "?viewMode=" + data.viewMode);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setLeadData(responseJson[ApiKey._API_DATA_KEY]);
		}
	};

	return (
		<div>
			<div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
				<h1 className="page-header">{t("LEAD")}</h1>
				<div>
					{
						checkIfPermissionExist(AccessRight._LEAD_CONTROL_PERMISSION, true) &&
						<NavigationButton history={_history} url={WebUrl._URL_LEAD_DETAIL} buttonText={t("ADD_NEW_LEAD")} />
					}
				</div>
			</div>
			<Panel>
				<PanelBody>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-lg-3">
								<div className="form-group">
									<label>{t("TASK_TYPE")}</label>
									<select class="form-control" name="viewMode" ref={register} defaultValue={_location.state?.ViewMode}>
										<option value="">{t("ALL")}</option>
										<option value={ViewMode._VIEW_MODE_CREATED}>{t("CREATED_LEAD")}</option>
										<option value={ViewMode._VIEW_MODE_ASSIGNED}>{t("ASSIGNED_LEAD")}</option>
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-3">
								<div className="form-group form-button">
									<button type="submit" class="btn btn-primary">{t('APPLY_FILTER')}</button>
								</div>
							</div>
						</div>
					</form>
				</PanelBody>
			</Panel>
			<Panel>
				<PanelBody>
					<ReactTable data={leadData} columns={_tableColumns} filterable={true} />
				</PanelBody>
			</Panel>
			<LeadAppointment isModalVisible={appointmentModalVisible} leadId={selectedLeadId} onFinish={(e) => {
				if (e) {
					handleSubmit(onSubmit)();
                }
				setAppointmentModalVisible(false)
			}} />

			<CompleteLead isModalVisible={isCompleteModalVisible} leadId={selectedLeadId} onFinish={(e) => {
				setIsCompleteModalVisible(false);
				if (e) {
					handleSubmit(onSubmit)();
				}
			}} />
		</div>
	)
}

export default ManageLead;