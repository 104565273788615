import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { navigateTo, createMultiPartFormBody, getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, Role, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Select from "react-select";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';
import { useLocation } from "react-router-dom";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageCustomer = props => {
	const { t } = useTranslation();
	let _history = useHistory();
	var _location = useLocation();
	const _language = useSelector(state => state.appState.language);
	const [birthdayOnly, setBirthdayOnly] = useState(_location.state ? _location.state.isBirthdayOnly : false);
	const [selectedPic, setSelectedPic] = useState([]);
	const [fixPic, setFixPic] = useState([]);
	const [userOption, setUserOption] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState();
	const [selectedUser, setSelectedUser] = useState('');
	var _dispatch = useDispatch();
	const [postData, setPostData] = useState({});
	const [apiUrl, setApiUrl] = useState('');
	const [assignPicVisible, setAssignPicVisible] = useState(false);
	const { register, handleSubmit, errors, setValue, unregister, setError, triggerValidation } = useForm();
	const _userData = useSelector(state => state.authState.userData);

	let _tableColumns = useMemo(() => [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => {
				return (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_CUSTOMER_DETAIL, row.original) }}><span className="text-warning">{t(checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
								{checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, true) && (_userData.userRoleId == Role._MANAGER || _userData.isAdmin) && <DropdownItem onClick={() => { loadPic(row.original) }}><span className="text-warning">{t("ASSIGN_PIC")}</span></DropdownItem>}
								{checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, true) && _userData.isAdmin && <DropdownItem onClick={() => { deleteCustomer(row.original) }}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				)
			},
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" }
		},
		{
			Header: t("CUSTOMER_NAME"),
			accessor: "username"
		},
		{
			Header: t("PHONE"),
			accessor: "phoneNumber"
		},
		{
			Header: t("EMAIL"),
			accessor: "email"
		},
		{
			Header: t("BIRTHDATE"),
			accessor: "birthDate"
		},
		{
			Header: t("STATUS"),
			accessor: "isActive",
			Cell: ({ row }) => {
				return <span>{row.original.isActive == 1 ? <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span className="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>}</span>
			},
			minWidth: 100
		},
	], [_language, userOption]);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function loadPic(customer) {
		var customerData = null;
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._CUSTOMER+"&id="+customer.id);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setSelectedCustomer(responseJson[ApiKey._API_DATA_KEY]);
			customerData = responseJson[ApiKey._API_DATA_KEY];
		}

		var picList = [];
		var fixedPicList = [];

		customerData.personInCharge.forEach(function (user) {
			if (userOption.filter(i => i.value == user.id).length != 0) {
				picList.push(user.id);
			}
			else {
				fixedPicList.push(user.id);
			}
		});

		setFixPic(fixedPicList);
		setSelectedPic(picList);
		setAssignPicVisible(true);
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function savePic() {
		var data = {};

		data['customer'] = selectedCustomer.id;

		data['personInCharge'] = [...selectedPic, ...fixPic].join(",");
		var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_CUSTOMER_PIC, createMultiPartFormBody(data));

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setAssignPicVisible(false);
        }

		_dispatch(showMessage({
			type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
			content: t(responseJson[ApiKey._API_MESSAGE_KEY])
		}));
    }

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function init() {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?userId=" + _userData.userId + "&includeSelf=true");
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			const userData = [];
			responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
				userData.push({ label: user.username, value: user.id });
			});

			setUserOption(userData);
		}
		handleSubmit(submitForm)();
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function deleteCustomer(customer) {
		_dispatch(showMessage({
			type: AlertTypes._WARNING,
			content: t("DELETE_SETTING_CONFIRMATION"),
			onConfirm: () => performDeleteCustomer(customer),
			showCancel: true
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function performDeleteCustomer(customer) {
		let params = {
			"status": false,
			"id": customer.id
		}

		var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._CUSTOMER, createMultiPartFormBody(params));

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			handleSubmit(submitForm)();
		}

		_dispatch(showMessage({
			type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
			content: t(responseJson[ApiKey._API_MESSAGE_KEY])
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	const submitForm = async (data, e) => {
		var params = {
			"Username": data.username,
			"PersonInCharge": selectedUser,
			"IsBirthdayAroundOnly": birthdayOnly,
			"checkForActivatedOnly":false,
		};

		setPostData(params);
		setApiUrl(ApiUrl._API_GET_CUSTOMER);
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	const PaginationTable = useMemo(() =>
		<ReactTable
			className="col-nowrap"
			initialPageSize={10}
			columns={_tableColumns}
			fetchUrl={apiUrl} fileName={t("CUSTOMER")} exportRequired={true}
			postData={postData} />
		, [apiUrl, postData])

	return (
		<div>
			<div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
				<h1 className="page-header">{t("CUSTOMER")}</h1>
				<div>
					{
						checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, true) && _userData.userRoleId != Role._SALES_REP &&
						<NavigationButton history={_history} url={WebUrl._URL_CUSTOMER_DETAIL} buttonText={t("ADD_NEW_CUSTOMER")} />
					}
				</div>
			</div>
			<Panel>
				<div className="row">
					<div className="col-lg-12">
						<PanelBody>
							<div className="row">
								<div className="col-lg-3">
									<div className="form-group">
										<label>{t("CUSTOMER_NAME")}</label>
										<input className="form-control" id="username" name="username" type="text" ref={register} />
									</div>
								</div>
								<div className="col-lg-3">
									<div className="form-group">
										<label>{t("PERSON_IN_CHARGE")}</label>
										<Select name="user" options={userOption}
											placeholder={(userOption.filter(option => option.value == selectedUser)[0] !== undefined) ? (userOption.filter(option => option.value == selectedUser)[0].label) : ""}
											value={userOption.filter(option => option.value == selectedUser)}
											isClearable={true}
											onChange={(e) => {
												if (e != null) {
													setSelectedUser(e.value);
												}
												else {
													setSelectedUser("");
												}
											}} />
									</div>
								</div>
								<div className="col-lg-3">
									<div className="form-group">
										<label><b>{t('BIRTHDAY_ONLY')}</b></label><br />
										<div className="switcher">
											<input type="checkbox" name="birthdayOnly" id="birthdayOnly"
												onChange={(e) => setBirthdayOnly(e.target.checked)}
												value={true}
												checked={birthdayOnly}
												ref={register} />
											<label htmlFor="birthdayOnly"></label>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-3">
									<button className="btn btn-primary" onClick={() => { handleSubmit(submitForm)() }}>{t('APPLY_FILTER')}</button>
								</div>
							</div>
						</PanelBody>
					</div>
				</div>
			</Panel>
			<Panel>
				<PanelBody>
					{PaginationTable}
				</PanelBody>
			</Panel>
			<Modal isOpen={assignPicVisible} fade={true}>
				<ModalHeader toggle={() => { setAssignPicVisible(!assignPicVisible) }}>
					{t("PERSON_IN_CHARGE")}
				</ModalHeader>
				<ModalBody>
					<div className="form-group row m-b-15">
						<div className="col-md-12">
							<DualListBox
								canFilter
								selected={selectedPic}
								options={userOption}
								onChange={(e) => { setSelectedPic(e) }}
							/>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<button type="button" className="btn btn-primary" onClick={() => { savePic()}}>{t('BTN_SAVE')}</button>
					<button type="button" className="btn btn-danger" onClick={() => { setAssignPicVisible(!assignPicVisible); }}>{t('BTN_CLOSE')}</button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default ManageCustomer;