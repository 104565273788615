import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, getValidationMessage, isObjectEmpty, stringIsNullOrEmpty } from "../../util/Util";
import Select from "react-select";
import { ApiKey, Role, ApiUrl, AccessRight, AlertTypes, DataName, Status } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import ApiEngine from '../../util/ApiEngine.js';
import moment from 'moment';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from "react-router-dom";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageAppointment = props => {
    const { t } = useTranslation();
    var _location = useLocation();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [appointmentApplication, setAppointmentApplication] = useState({});
    const [customerId, setCustomerId] = useState('');
    const [cityId, setCityId] = useState('');
    const [userId, setUserId] = useState('');
    const [appointmentTypeId, setAppointmentTypeId] = useState();
    const [appointmentDate, setAppointmentDate] = useState("");
    const [appointmentType, setAppointmentType] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [fullCustomers, setFullCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [cities, setCities] = useState([]);
    const [rawCityOption, setRawCityOption] = useState([]);
    const [appointmentData, setAppointmentData] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [enableEdit, setEnableEdit] = useState(true);
    var _dispatch = useDispatch();
    const [event, setEvent] = useState([]);
    const { register, handleSubmit, errors, setValue, triggerValidation, reset, watch } = useForm();
    const _language = useSelector(state => state.appState.language);
    const [appointmentRequestModalVisible, setAppointmentRequestModalVisible] = useState(false);
    const [isLeadAppointment, setIsLeadAppointment] = useState(false);
    const _userData = useSelector(state => state.authState.userData);
    const [selectedFilterCustomer, setSelectedFilterCustomer] = useState("");
    const [selectedSalesRep, setSelectedSalesRep] = useState(_location.state && _location.state.viewSelf ? _userData.userId : "");
    const [selectedAppointmentType, setSelectedAppointmentType] = useState("");

    let _tableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                return (<div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { showAppointmentForm(row.original); }}><span>{t("VIEW")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>);
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "SERVICER",
            accessor: "servicer",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("CUSTOMER"),
            accessor: "customer",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("SERVICE_TYPE"),
            accessor: "serviceType",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("APPOINTMENT_TIME"),
            accessor: "appointmentTime",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("CITY"),
            accessor: "city",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return (row.original.cityModel.name);
            }
        },
        {
            Header: t("STATUS"),
            Cell: ({ row }) => {
                if (row.original.isComplete) {
                    return <span className="badge badge-secondary badge-green">{t("COMPLETE")}</span>;
                }
                else {
                    return <span className="badge badge-secondary badge-info">{t("PENDING")}</span>;
                }
            },
        },
    ], [_language, enableEdit]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (startDate != null && endDate != null) {
            initAppointment();
        }
    }, [startDate, endDate]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        updateCustomerList();
    }, [userId]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const updateCustomerList = useCallback(async () => {
        if (userId && userId.length != 0) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._USER + "&id=" + userId;

            var responseJson = await ApiEngine.get(apiUrl);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setCustomers([...fullCustomers.filter(i => responseJson[ApiKey._API_DATA_KEY].customer.filter(k => k == i.value).length != 0)]);
                if (enableEdit) {
                    setCustomerId("");
                    setValue("customer", "");
                }
            }
        }
    }, [userId, enableEdit])

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function showAppointmentForm(appointment) {
        reset();

        register({ name: 'appointmentDate' });
        register({ name: 'customer' });
        register({ name: 'servicer' }, { required: true });
        register({ name: 'cityId' }, { required:true });

        if (appointment !== undefined) {
            setAppointmentApplication(appointment);
            setEnableEdit((appointment["createdBy"] == _userData.userId || appointment["userId"] == _userData.userId) && !appointment.isComplete);
            if (!stringIsNullOrEmpty(appointment["appointmentTime"])) {
                setAppointmentDate(moment(appointment["appointmentTime"], 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm"));
                setValue('appointmentDate', moment(appointment["appointmentTime"], 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm"));
            }

            setCityId(appointment["cityId"])
            setValue('cityId', appointment["cityId"]);
            setUserId(appointment["userId"]);
            setCustomerId(appointment["customerId"]);
            setValue('customer', appointment["customerId"]);
            setValue('servicer', appointment["userId"]);
            setAppointmentTypeId(appointment["serviceTypeId"]);

            setIsLeadAppointment(appointment.leadId != null);
        }
        else {
            setEnableEdit(true);
            setAppointmentApplication({});

            if (!stringIsNullOrEmpty(selectedData?.startStr)) {
                setAppointmentDate(selectedData?.startStr + " " + moment().format("HH:mm"));
                setValue('appointmentDate', selectedData?.startStr +" "+ moment().format("HH:mm"));
            }
            else {
                setAppointmentDate(moment().format("YYYY-MM-DD HH:mm"));
                setValue('appointmentDate', moment().format("YYYY-MM-DD HH:mm"));
            }
            setIsLeadAppointment(false);
            setUserId(_userData.userId);
            setValue('servicer', _userData.userId);
            setCityId(rawCityOption[0].value)
            setValue('cityId', rawCityOption[0].value);
        }

        setAppointmentRequestModalVisible(true);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_APPOINTMENT_RELATED_DATA);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var customerList = responseJson[ApiKey._API_DATA_KEY]["customerList"];
            var userList = responseJson[ApiKey._API_DATA_KEY]["userList"];
            var appointmentTypeList = responseJson[ApiKey._API_DATA_KEY]["serviceTypeList"];
            var cityList = responseJson[ApiKey._API_DATA_KEY]["cityList"];

            const userData = [];
            const loginUserData = userList.find(i => i.id == _userData.userId);

            if (checkIfPermissionExist(AccessRight._APPOINTMENT_CONTROL_PERMISSION, true) && loginUserData === undefined) {
                userData.push({ label: _userData.username, value: _userData.userId });
            }

            userList.forEach(function (user) {
                userData.push({ label: user.username, value: user.id });
            });

            setUsers(userData);

            const customerData = [];
            customerList.forEach(function (customer) {
                customerData.push({ label: customer.username + (stringIsNullOrEmpty(customer?.phoneNumber) ? "" :" (" + customer.phoneNumber + ")"), value: customer.id });
            });

            setFullCustomers(customerData);
            setCustomers(customerData);

            const appointmentTypeData = [];
            appointmentTypeList.forEach(function (type) {
                appointmentTypeData.push({ label: type.name, value: type.id, visitRequired: type.isVisitRequired });
            });

            setAppointmentType(appointmentTypeData);
            
            const cityData = [];
            const fullCityData = [];

            for (const [key, value] of Object.entries(cityList)) {
                var subList = [];

                value.forEach(function (city) {
                    subList.push({ label: city.name, value: city.id });
                    fullCityData.push({ label: city.name, value: city.id });
                });
                cityData.push({ label: key, options: subList });
            }

            setCities(cityData);
            setRawCityOption(fullCityData);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function initAppointment() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_APPOINTMENT_DATA + "?StartDate=" + startDate.format("YYYY-MM-DD") + "&EndDate=" + endDate.format("YYYY-MM-DD") +
            "&UserId=" + selectedSalesRep + "&CustomerId=" + selectedFilterCustomer + "&ServiceTypeId=" + selectedAppointmentType);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var appointmentList = responseJson[ApiKey._API_DATA_KEY];

            var parsedEvent = [];
            setAppointmentData(appointmentList);

            for (var i = 0; i < appointmentList.filter(i => i.appointmentTime != " - ").length; i++) {
                parsedEvent.push({
                    id: appointmentList[i]['id'],
                    title: appointmentList[i].userId == _userData.userId ? appointmentList[i]['cityModel']["name"] : appointmentList[i]["servicer"]+" - "+ appointmentList[i]['cityModel']["name"],
                    start: appointmentList[i]['appointmentTime'],
                    end: appointmentList[i]['appointmentTime'],
                    display: "list-item",
                    color: appointmentList[i].userId == _userData.userId ? "#3B32A9" : "#32a932"
                });
            }

            setEvent([...parsedEvent]);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const generateAppointmentPdf = useCallback(async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GENERATE_APPOINTMENT_PDF + "?StartDate=" + startDate.format("YYYY-MM-DD") + "&EndDate=" + endDate.format("YYYY-MM-DD") +
            "&UserId=" + selectedSalesRep + "&CustomerId=" + selectedFilterCustomer + "&ServiceTypeId=" + selectedAppointmentType);

        console.log(responseJson)
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let pdfWindow = window.open("Appointment")
            pdfWindow.document.write(responseJson[ApiKey._API_DATA_KEY]);
        }
    }, [startDate, endDate, selectedSalesRep, selectedFilterCustomer, selectedAppointmentType])

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            userId: data.servicer ?? "",
            customerId: data.customer ?? "",
            appointmentTime: data.appointmentDate,
            serviceTypeId: data.appointmentType,
            remark: data.remark,
            cityId:cityId
        }

        if (!isObjectEmpty(appointmentApplication)) {
            params["id"] = appointmentApplication.id;
        }
        else {
            params["status"] = true;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_APPOINTMENT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && initAppointment()
        }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setAppointmentRequestModalVisible(false);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function updateAppointmentComplete(appointmentId) {
        let params = {
            "isComplete": true,
            "id": appointmentId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_APPOINTMENT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            initAppointment();
            setAppointmentRequestModalVisible(false);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        }));
    }

    return (
        <div>
            {selectedData != null && checkIfPermissionExist(AccessRight._APPOINTMENT_CONTROL_PERMISSION, true) &&
                <div>
                    <ContextMenu id="rightClickEvent">
                        {selectedData.startStr && selectedData.endStr &&
                            <MenuItem onClick={() => { showAppointmentForm(); }}>
                                {t("ADD_APPOINTMENT")}
                            </MenuItem>
                        }
                        {selectedData.event &&
                            <MenuItem>
                                {t("VIEW_APPOINTMENT")}
                            </MenuItem>
                        }
                    </ContextMenu>
                </div>
            }
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('SERVICE_TYPE')}</b></label>
                                        <select className="form-control m-b-5" onChange={(e) => setSelectedAppointmentType(e.target.value)}>
                                            <option value={""}>{t("ALL")}</option>
                                            {
                                                appointmentType.map((data, key) => {
                                                    return (<option value={data.value}>{data.label}</option>);
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('CUSTOMER')}</b></label>
                                        <Select name="user" options={customers}
                                            placeholder={(customers.filter(option => option.value == selectedFilterCustomer)[0] !== undefined) ? (customers.filter(option => option.value == selectedFilterCustomer)[0].label) : ""}
                                            value={customers.filter(option => option.value == selectedFilterCustomer)}
                                            isClearable={true}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setSelectedFilterCustomer(e.value);
                                                }
                                                else {
                                                    setSelectedFilterCustomer("");
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('SALES_REP')}</b></label>
                                        <Select name="user" options={users}
                                            placeholder={(users.filter(option => option.value == selectedSalesRep)[0] !== undefined) ? (users.filter(option => option.value == selectedSalesRep)[0].label) : ""}
                                            value={users.filter(option => option.value == selectedSalesRep)}
                                            isClearable={true}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setSelectedSalesRep(e.value);
                                                }
                                                else {
                                                    setSelectedSalesRep("");
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <button className="btn btn-primary" onClick={() => { initAppointment(); }}>{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <PanelBody>
                    <ContextMenuTrigger id="rightClickEvent" classnames="off-thu">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: '',
                                center: 'title',
                                right: (checkIfPermissionExist(AccessRight._APPOINTMENT_CONTROL_PERMISSION, true) ? "custom1 custom2 " : "") + 'prev,next'
                            }}
                            initialView="dayGridMonth"
                            locale={_language}
                            events={event}
                            viewClassNames={"calendarDiv"}
                            customButtons={{
                                custom1: {
                                    text: t("ADD_APPOINTMENT"),
                                    click: function () {
                                        showAppointmentForm();
                                    },
                                },
                                custom2: {
                                    text: t("PRINT"),
                                    click: function () {
                                        generateAppointmentPdf();
                                    },
                                },
                            }}
                            eventClick={(e) => {
                                var selectedAppointmentData = appointmentData.filter(i => i.id == e.event.id)[0];
                                showAppointmentForm(selectedAppointmentData);
                            }}
                            select={(e) => { setSelectedData(e); }}
                            editable={true}
                            selectable={true}
                            datesSet={(e) => {
                                var viewingDate = moment("01 " + e.view.title, "DD MMMM YYYY");

                                setStartDate(viewingDate.clone());
                                setEndDate(viewingDate.clone().endOf('month'));
                            }}
                        />
                    </ContextMenuTrigger>
                </PanelBody>
            </Panel>
            {
                appointmentData.length != 0 &&
                <Panel>
                    <PanelHeader>{t("APPOINTMENT")}</PanelHeader>
                    <PanelBody>
                        <ReactTable data={appointmentData} columns={_tableColumns} globalFilterable={false} />
                    </PanelBody>
                </Panel>
            }
            <Modal isOpen={appointmentRequestModalVisible} toggle={() => { setAppointmentRequestModalVisible(!appointmentRequestModalVisible) }} size="lg">
                <form onSubmit={handleSubmit(submitForm)}>
                    <ModalHeader toggle={() => { setAppointmentRequestModalVisible(!appointmentRequestModalVisible) }}>
                        <span style={{marginRight:"15px"}}>{t("APPOINTMENT")}</span>

                        {!isObjectEmpty(appointmentApplication) && appointmentApplication.isComplete && <span className="badge badge-secondary badge-green">{t("COMPLETE")}</span>}
                        {!isObjectEmpty(appointmentApplication) && !appointmentApplication.isComplete && <span className="badge badge-secondary badge-info">{t("PENDING")}</span>}
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("CITY")}</b></label>
                                    <div className="col-md-8">
                                        <Select name="cityId" options={cities} isDisabled={!enableEdit || isLeadAppointment}
                                            placeholder={(rawCityOption.filter(option => option.value == cityId)[0] !== undefined) ? (rawCityOption.filter(option => option.value == cityId)[0].label) : ""}
                                            value={rawCityOption.filter(option => option.value == cityId)}
                                            isClearable={false}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setCityId(e.value);
                                                    setValue("cityId", e.value);
                                                }
                                                else {
                                                    setCityId("");
                                                    setValue("cityId", "");
                                                }
                                            }} />
                                        {errors.cityId && <div className="invalid-feedback">{t(getValidationMessage(errors.cityId))}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("SERVICER")}</b></label>
                                    <div className="col-md-8">
                                        <Select name="servicer" options={users} isDisabled={!enableEdit || isLeadAppointment}
                                            placeholder={(users.filter(option => option.value == userId)[0] !== undefined) ? (users.filter(option => option.value == userId)[0].label) : ""}
                                            value={users.filter(option => option.value == userId)}
                                            isClearable={false}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setUserId(e.value);
                                                    setValue("servicer", e.value);
                                                }
                                                else {
                                                    setUserId("");
                                                    setValue("servicer", "");
                                                }
                                            }} />
                                        {errors.servicer && <div className="invalid-feedback">{t(getValidationMessage(errors.servicer))}</div>}
                                    </div>
                                </div>
                            </div>
                            {!isLeadAppointment &&
                                <div className="col-lg-12">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label"><b>{t("CUSTOMER")}</b></label>
                                        <div className="col-md-8">
                                            <Select name="customer" options={customers} isDisabled={!enableEdit}
                                                placeholder={(customers.filter(option => option.value == customerId)[0] !== undefined) ? (customers.filter(option => option.value == customerId)[0].label) : ""}
                                                value={customers.filter(option => option.value == customerId)}
                                                isClearable={false}
                                                onChange={(e) => {
                                                    if (e != null) {
                                                        setCustomerId(e.value);
                                                        setValue("customer", e.value);
                                                    }
                                                    else {
                                                        setCustomerId("");
                                                        setValue("customer", "");
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {isLeadAppointment &&
                                <div className="col-lg-12">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label"><b>{t("CUSTOMER")}</b></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="customerName" readOnly={isLeadAppointment} defaultValue={appointmentApplication.customer} ref={register} />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("APPOINTMENT_TIME")}</b></label>
                                    <div className="col-md-8">
                                        <DateTime
                                            value={appointmentDate}
                                            dateFormat="YYYY-MM-DD"
                                            closeOnSelect={true}
                                            timeFormat={"HH:mm"}
                                            inputProps={{
                                                readOnly: true,
                                                disabled: !enableEdit,
                                                className: 'form-control',
                                                style: { cursor: 'pointer', background: enableEdit ? "transparent" : "#e9ecef" }
                                            }}
                                            isValidDate={(e) => {
                                                return e.isSameOrAfter(moment().subtract(1, "days").format("YYYY-MM-DD HH:mm"))
                                            }}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setAppointmentDate(e.format("YYYY-MM-DD HH:mm"));
                                                    setValue("appointmentDate", e.format("YYYY-MM-DD HH:mm"));
                                                    triggerValidation("appointmentDate")
                                                }
                                                else {
                                                    setAppointmentDate("");
                                                    setValue("appointmentDate", "");
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("APPOINTMENT_TYPE")}</b></label>
                                    <div className="col-md-8">
                                        <select className="form-control" ref={register({ required: true })} disabled={!enableEdit} name="appointmentType" value={appointmentTypeId} onChange={(e) => { setAppointmentTypeId(e.target.value) }}>
                                            <option value=" ">{ t("PLEASE_SELECT")}</option>
                                            {
                                                appointmentType.map((data, key) => {
                                                    return (<option value={data.value}>{data.label}</option>);
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {(!stringIsNullOrEmpty(appointmentApplication.remark) || enableEdit) && <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("REMARK")}</b></label>
                                    <div className="col-md-8">
                                        <textarea type="text" className="form-control" name="remark" readOnly={!enableEdit || isLeadAppointment} defaultValue={appointmentApplication.remark} ref={register} />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {enableEdit && <button type="submit" className="btn btn-primary" style={{ marginRight: "10px" }}>{t("SAVE")}</button>}
                        {!isObjectEmpty(appointmentApplication) && !appointmentApplication.isComplete && appointmentApplication["userId"] == _userData.userId && appointmentApplication.allowDirectComplete &&
                            <button type="button" className="btn btn-success" style={{ marginRight: "10px" }} onClick={() => updateAppointmentComplete(appointmentApplication.id)}>{t("COMPLETE")}</button>
                        }
                        {enableEdit && <button type="button" className="btn btn-danger" onClick={() => { setAppointmentRequestModalVisible(false); }}>{t('BTN_CANCEL')}</button>}
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default ManageAppointment;