import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage, isValidHttpUrl } from "../../util/Util";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName, WebUrl } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import Select from "react-select";
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ProductBrochureDetail = props => {
    var _history = useHistory();
    var { t } = useTranslation();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var { register, handleSubmit, errors, unregister, setValue, getValues, reset, triggerValidation } = useForm();

    const _writePermission = checkIfPermissionExist(AccessRight._PRODUCT_CONTROL_PERMISSION, true);
    const [imageToRemoved, setImageToRemoved] = useState([]);
    const [image, setImage] = useState([]);
    const [imageFile, setImageFile] = useState([]);


    const [productId, setProductId] = useState(null);
    const [productCode, setProductCode] = useState("");
    const [description, setDescription] = useState("");
    const [categoryId, setCategoryId] = useState("");

    const [isActive, setIsActive] = useState(true);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const _userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        register({ name: 'categoryId' }, { required: true });

        var dataResponseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._PRODUCT_CATEGORY);
        if (dataResponseJson[ApiKey._API_SUCCESS_KEY]) {
            const categoryList = [];
            dataResponseJson[ApiKey._API_DATA_KEY].forEach(function (category) {
                categoryList.push({ label: category.name, value: category.id });
            });

            setCategoryOptions(categoryList);
        }

        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._PRODUCT + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setProductId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                setIsActive(responseJson[ApiKey._API_DATA_KEY]["isActive"]);
                setCategoryId(responseJson[ApiKey._API_DATA_KEY]["categoryId"]);
                setProductCode(responseJson[ApiKey._API_DATA_KEY]["productCode"]);

                unregister('categoryId');
                var newImageList = [];
                responseJson[ApiKey._API_DATA_KEY]["image"].map(function (image) {
                    if (image.id !== 0) {
                        newImageList.push({ id: image.id, data: image.image, name: image.fileName });
                    }
                });
                responseJson[ApiKey._API_DATA_KEY]["attachment"].map(function (image) {
                    newImageList.push({ id: image.id, data: image.image, name: image.fileName });
                });

                setImage([...newImageList]);
                setImageFile([...newImageList]);
            }
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            categoryId: categoryId,
            "imageToRemoved": imageToRemoved,
            "longDescription": data.description,
            "name": data.productCode,
            "IsBrochure":true,
        }

        if (!stringIsNullOrEmpty(productId)) {
            params["id"] = productId;
        }
        else {
            params["status"] = true;
        }

        imageFile.map(function (file, i) {
            params["image" + i] = file;
        })

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_PRODUCT_BROCHURE, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_PRODUCT_BROCHURE)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const fileDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            imageFile.push(file);
            setImageFile([...imageFile]);

            const reader = new FileReader();
            reader.onloadend = () => {
                image.push({ id: undefined, data: reader.result });
                setImage([...image]);
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={_writePermission ? () => { handleSubmit(submitForm)() } : undefined}>
                            {productId ? t("EDIT_PRODUCT_BROCHURE") : t("ADD_NEW_PRODUCT_BROCHURE")}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <PanelBody>
                                    {(_writePermission || image.length != 0) && <div className="row m-b-5">
                                        <div className="col-lg-3" style={{ display: "flex", alignItems:"center" }}>
                                                <label><b>{t("PRODUCT_BROCHURE")}</b></label><br />
                                        </div>

                                        <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                            <div className="form-group" style={{width:"100%"}}>

                                                    <Dropzone accept={'.png,.jpg,.jpeg,.pdf'} onDrop={acceptedFiles => fileDrop(acceptedFiles)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div className="dropzone" style={{ textAlign: "center", height: "40px" }} {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <b style={{ color: "grey", margin: "10px" }}>{t("UPLOAD_BROCHURE")}</b>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {imageFile.length != 0 &&
                                        <>
                                            <div className="row m-b-5">
                                                <div className="col-lg-9">
                                                <table style={{ width: "100%", tableLayout: "fixed" }} className="table table-bordered">
                                                        <thead style={{ textAlign: "center" }}>
                                                        <tr>
                                                            <th style={{ borderBottom: "1px solid #ddd", textAlign:"center", width: "15%" }}>{t("ACTION")}</th>
                                                            <th style={{ borderBottom: "1px solid #ddd", textAlign: "left", width: "70%" }}>{t("FILE_NAME")}</th>
                                                            <th style={{ borderBottom: "1px solid #ddd", textAlign: "center", width: "15%" }}>{t("VIEW")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            imageFile.map((item, i) => {
                                                                return (<tr>
                                                                    <td style={{ textAlign: "center" }}><i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => {
                                                                        if (item.id !== undefined) {
                                                                            imageToRemoved.push(item.id);
                                                                            setImageToRemoved([...imageToRemoved]);
                                                                        }
                                                                        image.splice(i, 1);
                                                                        imageFile.splice(i, 1);

                                                                        setImage([...image]);
                                                                        setImageFile([...imageFile]);
                                                                    }}></i></td>
                                                                    <td style={{ textAlign: "left" }}>{item.name}</td>
                                                                    <td style={{ textAlign: "center" }}><i className="fas fa-eye" style={{ fontSize: "x-large", cursor: "pointer", color: "orange" }} onClick={() => {
                                                                        let pdfWindow = window.open("")
                                                                        console.log(image[i].data)
                                                                        pdfWindow.document.write("<iframe width='100%' height='100%' src='" + image[i].data + "'></iframe>");
                                                                    }}></i></td>
                                                                </tr>);
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <hr />
                                    <div className="row m-b-20">
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("PRODUCT_BROCHURE_CODE")}</b></label>
                                                <input type="text" name="productCode"
                                                    ref={register({
                                                        required: true
                                                    })}
                                                    defaultValue={productCode}
                                                    className="form-control m-b-5" />
                                                {errors.productCode && <div className="invalid-feedback">{t(getValidationMessage(errors.productCode))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="form-group">
                                                <label><b>{t("DESCRIPTION")}</b></label>
                                                <input type="text" name="description"
                                                    defaultValue={description}
                                                    ref={register({ required: true })}
                                                    className="form-control m-b-5" />
                                                {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-b-20">
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("CATEGORY")}</b></label>
                                                <Select name="categoryId" options={categoryOptions}
                                                    placeholder={(categoryOptions.filter(option => option.value == categoryId)[0] !== undefined) ? (categoryOptions.filter(option => option.value == categoryId)[0].label) : ""}
                                                    value={categoryOptions.filter(option => option.value == categoryId)}
                                                    onChange={(e) => {
                                                        unregister('categoryId');
                                                        setCategoryId(e.value);
                                                    }} />
                                                {errors.categoryId && <div className="invalid-feedback">{t(getValidationMessage(errors.categoryId))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("IS_ACTIVE")}</b></label><br />
                                                <div className="switcher">
                                                    <input type="checkbox" name="isActive" id="isActive"
                                                        onChange={(e) => setIsActive(e.target.checked)}
                                                        value={true}
                                                        checked={isActive}
                                                        ref={register} />
                                                    <label htmlFor="isActive"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </PanelBody>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default ProductBrochureDetail;