import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { _ROUTES, _LOGIN_ROUTE } from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { AccessRight, Status } from './../../util/Constant'
import { connect } from "react-redux";

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i=0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = 'CSL CRM | ' + routeArray[i].title;
		}
	}
    document.title = (pageTitle) ? pageTitle : 'CSL CRM';
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: _ROUTES
        };
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    PrivateRoute(index, routeData) {
        return (
            <Route
                key={index}
                exact={routeData.authRequired && !this.props.authState.isLoggedIn ? _LOGIN_ROUTE.exact : routeData.exact}
                path={routeData.authRequired && !this.props.authState.isLoggedIn ? _LOGIN_ROUTE.path : routeData.path}
                component={routeData.authRequired && !this.props.authState.isLoggedIn ? _LOGIN_ROUTE.component : routeData.component}
            />
        );
    }

    componentDidMount() {
        setTitle(this.props.history.location.pathname, Route);
    }

    componentWillMount() {
        this.props.history.listen(() => {
            setTitle(this.props.history.location.pathname, Route);
        });
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    filterMenuByPermission() {
        var filteredRoutes = _ROUTES;

        if (this.props.authState.userData != null && this.props.authState.userData.accessString !== undefined) {
            var userPermissionData = this.props.authState.userData.accessString.split(";");
            var permissionArrKey = [];

            for (var i = 0; i < userPermissionData.length; i++) {
                var keyValuePermission = userPermissionData[i].split(":");
                if (keyValuePermission.length == 2) {
                    if (keyValuePermission[1][AccessRight._READ_BIT_POSITION] == Status._ENABLED.toString()) {
                        permissionArrKey.push(keyValuePermission[0] + AccessRight._READ_PERMISSION_STRING);
                    }
                }
            }

            filteredRoutes = filteredRoutes.filter(item => {
                let permission = item.permission;
                if (permission !== undefined) {
                    return permissionArrKey.filter(item => item == permission).length != 0;
                }
                else {
                    return true;
                }
            });
        }

        this.state.routes = filteredRoutes;
    }

    render() {
        this.filterMenuByPermission();

		return (
			<PageSettings.Consumer>
				{({pageContentFullWidth, pageContentClass, pageContentInverseMode}) => (
					<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
						{this.state.routes.map((route, index) => (
                            this.PrivateRoute(index, route)
						))}
					</div>
				)
			}
			</PageSettings.Consumer>
		)
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect(
	mapStateToProps,
	null
)(withRouter(Content));