import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language, SessionKey } from "../util/Constant";

import common_en from "./en/common.json";
import common_cn from "./cn/common.json";

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>

const _resources = {};
_resources[Language._ENGLISH] = { common: common_en };
_resources[Language._CHINESE] = { common: common_cn };

i18n
    .use(initReactI18next)
    .init({
        defaultNS: "common",
        debug: false,
        resources : _resources,
        lng: sessionStorage.getItem(SessionKey._LANGUAGE) || Language._ENGLISH,
        fallbackLng: [Language._ENGLISH, Language._CHINESE],
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;