import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import { createMultiPartFormBody, getValidationMessage, createFormBody } from "../../util/Util";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary> 
const ManageSystemSettings = props => {
    var _dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors, setValue } = useForm();
    const [systemSettingsData, setSystemSettingsData] = useState([]);
    const [showEditSettingModal, setShowEditSettingModal] = useState(false);
    const [systemSetting, setSystemSetting] = useState('');
    const _language = useSelector(state => state.appState.language);

    let _systemSettingsTableColumns = useMemo(() => [
        {
            Header: "ACTION",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => loadSystemSettings(row.original)}><span>{t('EDIT_SETTING')}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: "DESCRIPTION",
            accessor: "description",
            disableSortBy: true,
        },
        {
            Header: "VALUE",
            accessor: "value",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row }) => {
                return <span>{row.original.value.length >= 50 ? row.original.value.substring(0, 50) + "..." : row.original.value}</span>
            }
        },
        {
            Header: "LAST_MODIFIED",
            accessor: "updatedTime",
            disableSortBy: true,
        },
        {
            Header: "MODIFIED_BY",
            accessor: "updatedBy",
            disableSortBy: true,
        },
    ], [systemSettingsData, _language]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        getSystemSettings();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getSystemSettings() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._SYSTEM_SETTING);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSystemSettingsData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function loadSystemSettings(data) {
        setSystemSetting(data);
        setShowEditSettingModal(!showEditSettingModal);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitSystemSettingForm = async (data, e) => {
        let formCollection = {
            id: systemSetting.id,
            description: data.systemSettingDescription,
            value: data.systemSettingValue
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._SYSTEM_SETTING, createMultiPartFormBody(formCollection));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getSystemSettings();
            setShowEditSettingModal(!showEditSettingModal)
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    return (
        <div>
            <h1 className="page-header">{t('MANAGE_SETTINGS')}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable data={systemSettingsData} columns={_systemSettingsTableColumns} globalFilterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <div className="row">
                <Modal isOpen={showEditSettingModal}>
                    <form onSubmit={handleSubmit(submitSystemSettingForm)}>
                        <ModalHeader toggle={() => { setShowEditSettingModal(!showEditSettingModal) }}>
                            {t("EDIT_SETTING_INFORMATION")}
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("SETTING_NAME")}</b></label>
                                <div className="col-md-7">
                                    <input type="text"
                                        readOnly={true}
                                        defaultValue={systemSetting.name}
                                        autoComplete="off"
                                        className="form-control" />
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("DESCRIPTION")}</b></label>
                                <div className="col-md-7">
                                    <textarea
                                        className="form-control"
                                        autoComplete="off"
                                        rows="2"
                                        name="systemSettingDescription"
                                        ref={register({ required:true })}
                                        placeholder={t("ENTER_SETTING_DESCRIPTION")}
                                        defaultValue={systemSetting.description} />
                                    {errors.systemSettingDescription && <div className="invalid-feedback">{t(getValidationMessage(errors.systemSettingDescription))}</div>}
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("VALUE")}</b></label>
                                <div className="col-md-7">
                                    <textarea
                                        className="form-control"
                                        autoComplete="off"
                                        rows="4"
                                        name="systemSettingValue"
                                        ref={register({ required: true })}
                                        placeholder={t("ENTER_SETTING_VALUE")}
                                        defaultValue={systemSetting.value} />
                                    {errors.systemSettingValue && <div className="invalid-feedback">{t(getValidationMessage(errors.systemSettingValue))}</div>}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                            <button type="button" className="btn btn-danger" onClick={() => { setShowEditSettingModal(!showEditSettingModal); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </div>
    )
}

export default ManageSystemSettings;