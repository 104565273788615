import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody } from "../../util/Util";
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageSystemMessage = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const [messageData, setMessageData] = useState([]);
    var _dispatch = useDispatch();
    const _language = useSelector(state => state.appState.language);

    let _tableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                return (<div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_SYSTEM_MESSAGE_DETAIL, row.original) }}><span>{t(checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
                            {checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) && <DropdownItem onClick={() => setAnnouncementStatus(row.original.id, row.original.isActive)}><span>{row.original.isActive ? t("UNPUBLISH") : t("PUBLISH")}</span></DropdownItem>}
                            {checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) && <DropdownItem onClick={() => deleteAnnouncement(row.original.id)}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>);
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "TITLE",
            accessor: "title",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 300
        },
        {
            Header: t("STATUS"),
            accessor: "status",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return <span>{row.original.isActive ? <span className="badge badge-secondary badge-green">{t("PUBLISHED")}</span> : <span className="badge badge-secondary badge-danger">{t("UNPUBLISHED")}</span>}</span>
            },
            minWidth: 100
        },
    ], [_language]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        getAnnouncement();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getAnnouncement() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._SYSTEM_MESSAGE);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setMessageData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function setAnnouncementStatus(announcementId, isActive) {
        let params = {
            "isActive": !isActive,
            "id": announcementId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._SYSTEM_MESSAGE, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getAnnouncement();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function performDeleteAnnouncement(announcementId) {
        let params = {
            "status": false,
            "id": announcementId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._SYSTEM_MESSAGE, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getAnnouncement();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function deleteAnnouncement(announcementId) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            content: t("DELETE_SETTING_CONFIRMATION"),
            onConfirm: () => performDeleteAnnouncement(announcementId),
            showCancel: true
        }));
    }

    return (
        <div>
            <div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("SYSTEM_MESSAGE")}</h1>
                {
                    checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_SYSTEM_MESSAGE_DETAIL} buttonText={t("ADD_SYSTEM_MESSAGE")} />
                }
            </div>
            <Panel>
                <PanelBody>
                    <ReactTable data={messageData} columns={_tableColumns} filterable={true} />
                </PanelBody>
            </Panel>
        </div>
    );
}

export default ManageSystemMessage;