import React, { useEffect, useContext, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { PageSettings } from '../../config/page-settings.js';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ApiKey, WebUrl, ApiUrl, Status, AccessRight, Role, ViewMode } from "../../util/Constant";
import moment from 'moment';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import ApiEngine from '../../util/ApiEngine.js';
import { navigateTo } from '../../util/Util';

const Dashboard = props => {
	let _history = useHistory();
	const { t, i18n } = useTranslation();
	const _context = useContext(PageSettings);
	const _dispatch = useDispatch();
	const [salesRep, setSalesRep] = useState('');
	const [birthdayCustomer, setBirthdayCustomer] = useState('');
	const [totalCreatedTask, setTotalCreatedTask] = useState('');
	const [totalCustomer, setTotalCustomer] = useState('');
	const [totalCreatedLead, setTotalCreatedLead] = useState('');
	const [assignedTask, setAssignedTask] = useState('');
	const [assignedLead, setAssignedLead] = useState('');
	const [appointmentData, setAppointmentData] = useState('');
	const [announcement, setAnnouncement] = useState([]);
	const _language = useSelector(state => state.appState.language);

	let _announcmentTableColumns = useMemo(() => [
		{
			Header: "TITLE",
			accessor: "title",
			disableSortBy: true,
			disableFilters: true,
			minWidth: 300
		},
		{
			Header: t("ACTION"),
			id: "action",
			Cell: ({ row }) => {
				return (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_SYSTEM_MESSAGE_DETAIL, row.original) }}><span>{t("VIEW")}</span></DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				);
			},
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" }
		}
	], [_language]);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function init() {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_DASHBOARD_DATA);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setAssignedLead(responseJson[ApiKey._API_DATA_KEY]["assignedLeadCount"]);
			setAssignedTask(responseJson[ApiKey._API_DATA_KEY]["assignedTaskCount"]);
			setAppointmentData(responseJson[ApiKey._API_DATA_KEY]["appointmentCount"]);
			setAnnouncement(responseJson[ApiKey._API_DATA_KEY]["announcement"]);

			setSalesRep(responseJson[ApiKey._API_DATA_KEY]["salesRep"].length);
			setTotalCreatedLead(responseJson[ApiKey._API_DATA_KEY]["createdLead"].length);
			setTotalCreatedTask(responseJson[ApiKey._API_DATA_KEY]["createdTask"].length);
			setBirthdayCustomer(responseJson[ApiKey._API_DATA_KEY]["birthDayCustomer"].length);
			setTotalCustomer(responseJson[ApiKey._API_DATA_KEY]["customer"].length);
		}
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function navigateToCustomer() {
		var viewState = { isBirthdayOnly: true };
		navigateTo(_history, WebUrl._URL_MANAGE_CUSTOMER, viewState)
	}

	return (
		<div>
			<div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
				<h1 className="page-header">{t("DASHBOARD")}</h1>
			</div>

			<div className="row">
				{checkIfPermissionExist(AccessRight._SALESREP_ACCOUNT_CONTROL_PERMISSION, false) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-red">
							<div className="stats-icon"><i className="fa fa-user"></i></div>
							<div className="stats-info">
								<h4>{t("TOTAL_SALES_REP")}</h4>
								<p>{salesRep}</p>
							</div>
							<div className="stats-link">
								<Link to={WebUrl._URL_MANAGE_SALES_REP}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></Link>
							</div>
						</div>
					</div>
				}
				{checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, false) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-orange">
							<div className="stats-icon"><i className="fa fa-user"></i></div>
							<div className="stats-info">
								<h4>{t("TOTAL_CUSTOMER")}</h4>
								<p>{totalCustomer}</p>
							</div>
							<div className="stats-link">
								<Link to={WebUrl._URL_MANAGE_CUSTOMER}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></Link>
							</div>
						</div>
					</div>
				}
				{checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, false) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-black-lighter">
							<div className="stats-icon"><i className="fa fa-ake-candles"></i></div>
							<div className="stats-info">
								<h4>{t("BIRTHDAY_CUSTOMER")}</h4>
								<p>{birthdayCustomer}</p>
							</div>
							<div className="stats-link">
								<a style={{ cursor: "pointer" }} onClick={() => { navigateToCustomer() }}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></a>
							</div>
						</div>
					</div>
				}
				{checkIfPermissionExist(AccessRight._LEAD_CONTROL_PERMISSION, true) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-aqua">
							<div className="stats-icon"><i className="fa fa-clipboard-list"></i></div>
							<div className="stats-info">
								<h4>{t("CREATED_LEAD")}</h4>
								<p>{totalCreatedLead}</p>
							</div>
							<div className="stats-link">
								<a style={{ cursor: "pointer" }} onClick={() => {
								var viewState = { ViewMode: ViewMode._VIEW_MODE_CREATED };
									navigateTo(_history, WebUrl._URL_MANAGE_LEAD, viewState)
								}}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></a>
							</div>
						</div>
					</div>
				}
				{checkIfPermissionExist(AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION, true) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-lime">
							<div className="stats-icon"><i className="fa fa-list-check"></i></div>
							<div className="stats-info">
								<h4>{t("CREATED_TASK")}</h4>
								<p>{totalCreatedTask}</p>
							</div>
							<div className="stats-link">
								<a style={{ cursor: "pointer" }} onClick={() => {
								var viewState = { ViewMode: ViewMode._VIEW_MODE_CREATED };
									navigateTo(_history, WebUrl._URL_MANAGE_TASK, viewState)
								}}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></a>
							</div>
						</div>
					</div>
				}

				{checkIfPermissionExist(AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION, false) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-silver">
							<div className="stats-icon"><i className="fa fa-list-check"></i></div>
							<div className="stats-info">
								<h4>{t("ASSIGNED_TASK")}</h4>
								<p>{assignedTask || 0}</p>
							</div>
							<div className="stats-link">
								<a style={{ cursor: "pointer" }} onClick={() => {
								var viewState = { ViewMode: ViewMode._VIEW_MODE_ASSIGNED };
									navigateTo(_history, WebUrl._URL_MANAGE_TASK, viewState)
								}}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></a>
							</div>
						</div>
					</div>
				}

				{checkIfPermissionExist(AccessRight._LEAD_CONTROL_PERMISSION, false) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-primary">
							<div className="stats-icon"><i className="fa fa-list-check"></i></div>
							<div className="stats-info">
								<h4>{t("ASSIGNED_LEAD")}</h4>
								<p>{assignedLead || 0}</p>
							</div>
							<div className="stats-link">
								<a style={{ cursor: "pointer" }} onClick={() => {
								var viewState = { ViewMode: ViewMode._VIEW_MODE_ASSIGNED };
									navigateTo(_history, WebUrl._URL_MANAGE_LEAD, viewState)
								}}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></a>
							</div>
						</div>
					</div>
				}

				{checkIfPermissionExist(AccessRight._APPOINTMENT_CONTROL_PERMISSION, false) &&
					<div className="col-xl-3 col-md-6">
						<div className="widget widget-stats bg-secondary">
							<div className="stats-icon"><i className="fa fa-list-check"></i></div>
							<div className="stats-info">
								<h4>{t("APPOINTMENT")}</h4>
								<p>{appointmentData || 0}</p>
							</div>
							<div className="stats-link">
								<a style={{ cursor: "pointer" }} onClick={() => {
									var viewState = { viewSelf:true };
									navigateTo(_history, WebUrl._URL_MANAGE_APPOINTMENT, viewState)
								}}>{t("VIEW_DETAIL")} <i className="fa fa-arrow-alt-circle-right"></i></a>
							</div>
						</div>
					</div>
				}
			</div>
			<div className="row">
				{
					announcement.length != 0 &&
					<div className="col-lg-12">
						<Panel>
							<PanelHeader><i className="fas fa-lg fa-fw me-10px fa-bullhorn"></i> {t("ANNOUNCEMENT")}</PanelHeader>
							<PanelBody>
								<ReactTable data={announcement} columns={_announcmentTableColumns} globalFilterable={false} initialPageSize={5} showOverallInfo={false} />
							</PanelBody>
						</Panel>
					</div>
				}
			</div>
		</div>
	)
}

export default withRouter(Dashboard);