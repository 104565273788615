import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl, AccessRight, DataName, Role } from "./../util/Constant";

import Dashboard from '../pages/home/Dashboard.js';
import Login from '../pages/home/Login.js';
import RoleDetail from '../pages/account/RoleDetail.js';
import ManageRole from '../pages/account/ManageRole.js';
import ManageCustomer from '../pages/account/ManageCustomer.js';
import CustomerDetail from '../pages/account/CustomerDetail.js';
import ManageSystemSettings from '../pages/configuration/ManageSystemSetting.js';
import SystemMessageDetail from '../pages/configuration/SystemMessageDetail.js';
import ManageSystemMessage from '../pages/configuration/ManageSystemMessage.js';

import ManageQuickRemark from '../pages/configuration/ManageQuickRemark.js';
import QuickRemarkDetail from '../pages/configuration/QuickRemarkDetail.js';
import ManageConfiguration from '../pages/configuration/ManageConfiguration.js';

import ManageServiceType from '../pages/configuration/ManageServiceType.js';

import AccountDetail from '../pages/account/AccountDetail.js';
import ManageAccount from '../pages/account/ManageAccount.js';
import ChangePassword from '../pages/account/ChangePassword.js';

import ManageProduct from '../pages/customer/ManageProduct.js';
import ProductDetail from '../pages/customer/ProductDetail.js';
import ManageProductBrochure from '../pages/customer/ManageProductBrochure.js';
import ProductBrochureDetail from '../pages/customer/ProductBrochureDetail.js';

import ManageLead from '../pages/customer/ManageLead.js';
import LeadDetail from '../pages/customer/LeadDetail.js';
import ManageTask from '../pages/work/ManageTask.js';
import TaskDetail from '../pages/work/TaskDetail.js';
import ManageAppointment from '../pages/customer/ManageAppointment.js';
import ManageOperationLog from '../pages/configuration/ManageOperationLog.js';

import TaskHistoryReport from '../pages/report/TaskHistoryReport.js';
import VisitHistoryReport from '../pages/report/VisitHistoryReport.js';
import SalesKpiReport from '../pages/report/SalesKpiReport.js';
import MarketSurveyReport from '../pages/report/MarketSurveyReport.js';
import VisitSummaryReport from '../pages/report/VisitSummaryReport.js';
import SalesOrderReport from '../pages/report/SalesOrderReport.js';
import CustomerVisitSummaryReport from '../pages/report/CustomerVisitSummaryReport';
import LeadSummaryReport from '../pages/report/LeadSummaryReport';

export const _LOGIN_ROUTE =
{
    path: WebUrl._URL_MAIN,
    exact: false,
    authRequired: false,
    component: () => <Redirect to={WebUrl._URL_LOGIN} />
}

export const _ROUTES = [
    {
        path: WebUrl._URL_LOGIN,
        title: 'Login',
        authRequired: false,
        component: () => <Login />
    },
    {
        path: WebUrl._URL_MAIN,
        exact: true,
        authRequired: true,
        component: () => <Redirect to={WebUrl._URL_DASHBOARD} />
    },
    {
        path: WebUrl._URL_DASHBOARD,
        exact: true,
        authRequired: true,
        title: 'Dashboard',
        component: () => <Dashboard />
    },
    {
        path: WebUrl._URL_MANAGE_ROLE,
        exact: true,
        authRequired: true,
        title: 'Manage Role',
        permission: AccessRight._ACCESS_LEVEL_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageRole />
    },
    {
        path: WebUrl._URL_ROLE_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Role Detail',
        component: () => <RoleDetail />
    },
    {
        path: WebUrl._URL_SYSTEM_SETTINGS,
        exact: true,
        authRequired: true,
        title: 'System Settings',
        permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageSystemSettings />
    },
    {
        path: WebUrl._URL_SYSTEM_MESSAGE,
        exact: true,
        authRequired: true,
        title: 'Manage Announcement',
        permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageSystemMessage />
    },
    {
        path: WebUrl._URL_SYSTEM_MESSAGE_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Announcement Detail',
        component: () => <SystemMessageDetail />
    },
    {
        path: WebUrl._URL_QUICK_REMARK,
        exact: true,
        authRequired: true,
        title: 'Manage Quick Remark',
        permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageQuickRemark />
    },
    {
        path: WebUrl._URL_QUICK_REMARK_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Quick Remark Detail',
        component: () => <QuickRemarkDetail />
    },
    {
        path: WebUrl._URL_MANAGE_PRODUCT_CATEGORY,
        exact: true,
        authRequired: true,
        permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Product Category',
        component: () => <ManageConfiguration configurationType={DataName._PRODUCT_CATEGORY} />
    },
    {
        path: WebUrl._URL_CHECKLIST,
        exact: true,
        authRequired: true,
        permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Checklist Remark',
        component: () => <ManageConfiguration configurationType={DataName._SERVICE_CHECK_LIST} />
    },
    {
        path: WebUrl._URL_ATTACHMENT_TYPE,
        exact: true,
        authRequired: true,
        permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Attachment Type',
        component: () => <ManageConfiguration configurationType={DataName._ATTACHMENT_TYPE} />
    },
    {
        path: WebUrl._URL_SERVICE_TYPE,
        exact: true,
        authRequired: true,
        permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Attachment Type',
        component: () => <ManageServiceType />
    },
    {
        path: WebUrl._URL_MANAGE_ADMIN,
        exact: true,
        authRequired: true,
        title: 'Manage Admin',
        permission: AccessRight._ADMIN_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageAccount role={Role._ADMIN_SPECIFIC} isAdminRole={true} />
    },
    {
        path: WebUrl._URL_ADMIN_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Admin Detail',
        component: () => <AccountDetail role={Role._ADMIN_SPECIFIC} isAdminRole={ true} />
    },
    {
        path: WebUrl._URL_MANAGE_MANAGER,
        exact: true,
        authRequired: true,
        title: 'Manage Manager',
        permission: AccessRight._MANAGER_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageAccount role={Role._MANAGER} isAdminRole={false} />
    },
    {
        path: WebUrl._URL_MANAGER_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Manager Detail',
        component: () => <AccountDetail role={Role._MANAGER} isAdminRole={false} />
    },
    {
        path: WebUrl._URL_MANAGE_SALES_REP,
        exact: true,
        authRequired: true,
        title: 'Manage Sales Rep',
        permission: AccessRight._SALESREP_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageAccount role={Role._SALES_REP} isAdminRole={false} />
    },
    {
        path: WebUrl._URL_SALES_REP_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Sales Rep Detail',
        component: () => <AccountDetail role={Role._SALES_REP} isAdminRole={false} />
    },
    {
        path: WebUrl._URL_MANAGE_USER,
        exact: true,
        authRequired: true,
        title: 'Manage User',
        permission: AccessRight._USER_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageAccount role={Role._USER_SPECIFIC} isAdminRole={false} />
    },
    {
        path: WebUrl._URL_USER_DETAIL,
        exact: true,
        authRequired: true,
        title: 'User Detail',
        component: () => <AccountDetail role={Role._USER_SPECIFIC} isAdminRole={false} />
    },
    {
        path: WebUrl._URL_USER_CHANGE_PASSWORD,
        exact: true,
        authRequired: true,
        title: 'Change Password',
        component: () => <ChangePassword />
    },
    {
        path: WebUrl._URL_MANAGE_PRODUCT_BROCHURE,
        exact: true,
        authRequired: true,
        title: 'Manage Product Brochure',
        permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageProductBrochure />
    },
    {
        path: WebUrl._URL_MANAGE_PRODUCT_BROCHURE_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Product Brochure Detail',
        component: () => <ProductBrochureDetail />
    },
    {
        path: WebUrl._URL_MANAGE_PRODUCT,
        exact: true,
        authRequired: true,
        title: 'Manage Product',
        permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageProduct role={Role._SALES_REP} />
    },
    {
        path: WebUrl._URL_MANAGE_PRODUCT_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Product Detail',
        component: () => <ProductDetail role={Role._SALES_REP} />
    },
    {
        path: WebUrl._URL_MANAGE_CUSTOMER,
        exact: true,
        authRequired: true,
        title: 'Manage Product',
        permission: AccessRight._CUSTOMER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageCustomer />
    },
    {
        path: WebUrl._URL_CUSTOMER_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Product Detail',
        component: () => <CustomerDetail />
    },
    {
        path: WebUrl._URL_MANAGE_TASK,
        exact: true,
        authRequired: true,
        title: 'Manage Task',
        permission: AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageTask />
    },
    {
        path: WebUrl._URL_TASK_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Task Detail',
        component: () => <TaskDetail />
    },
    {
        path: WebUrl._URL_MANAGE_LEAD,
        exact: true,
        authRequired: true,
        title: 'Manage Lead',
        permission: AccessRight._LEAD_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        component: () => <ManageLead />
    },
    {
        path: WebUrl._URL_LEAD_DETAIL,
        exact: true,
        authRequired: true,
        title: 'Lead Detail',
        component: () => <LeadDetail />
    },
    {
        path: WebUrl._URL_MANAGE_APPOINTMENT,
        exact: true,
        authRequired: true,
        permission: AccessRight._APPOINTMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Manage Appointment',
        component: () => <ManageAppointment />
    },
    {
        path: WebUrl._URL_OPERATION_LOG,
        exact: true,
        authRequired: true,
        permission: AccessRight._OPERATION_LOG_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Operation Log',
        component: () => <ManageOperationLog />
    },
    {
        path: WebUrl._URL_MANAGE_TASK_HISTORY_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._EMP_WORK_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Task History Report',
        component: () => <TaskHistoryReport />
    },
    {
        path: WebUrl._URL_MANAGE_VISIT_HISTORY_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._VISIT_HISTORY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Visit History Report',
        component: () => <VisitHistoryReport />
    },
    {
        path: WebUrl._URL_MANAGE_VISIT_SUMMARY_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._VISIT_HISTORY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Visit Summary Report',
        component: () => <VisitSummaryReport />
    },
    {
        path: WebUrl._URL_MANAGE_SALES_ORDER_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._SALES_ORDER_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Visit Summary Report',
        component: () => <SalesOrderReport />
    },
    {
        path: WebUrl._URL_MANAGE_KPI_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._SALES_KPI_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Sales KPI Report',
        component: () => <SalesKpiReport />
    },
    {
        path: WebUrl._URL_MARKET_SURVEY_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._MARKET_SURVEY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Market Sirvey Report',
        component: () => <MarketSurveyReport />
    },
    {
        path: WebUrl._URL_MANAGE_CUSTOMER_VISIT_SUMMARY_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._CUSTOMER_VISIT_SUMMARY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Customer Visit Summary Report',
        component: () => <CustomerVisitSummaryReport />
    },
    {
        path: WebUrl._URL_MANAGE_LEAD_SUMMARY_REPORT,
        exact: true,
        authRequired: true,
        permission: AccessRight._LEAD_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING,
        title: 'Lead Summary Report',
        component: () => <LeadSummaryReport />
    }
];