import { AlertTypes } from '../util/Constant.js';
import i18n from '../localization/i18n';

export const _BUSY = 'busy';
export const _IDLE = 'fail';
export const _LANGUAGE = 'language';

export const _ALERT_TYPES = {
    SET_ALERT: 'SetAlert',
    NONE: 'AlertNone',
};

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export const setBusy = (message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message
        });
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export const showResponseMessage = (status, message, translateRequired = true) => {
    return dispatch => {
        var messageToShow = message;
        if (translateRequired) {
            messageToShow = i18n.t(message);
        }
        dispatch(showMessage({ type: (status ? AlertTypes._SUCCESS : AlertTypes._ERROR), content: messageToShow }));
    }
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export const showMessage = (alertProps) => {
    return dispatch => {
        const customConfirm = alertProps.onConfirm;
        alertProps.onConfirm = async (...args) => {
            disposeMessage()(await dispatch);
            if (customConfirm) {
                customConfirm(...args);
            }
        }

        const customCancel = alertProps.onCancel;
        alertProps.onCancel = async (...args) => {
            disposeMessage()(await dispatch);
            if (customCancel) {
                customCancel(...args);
            }
        }

        alertProps.cancelBtnBsStyle = 'danger';
        alertProps.title = alertProps.title ?? "";
        alertProps.allowEscape = false;
        alertProps.reverseButtons = true;

        dispatch({ type: _ALERT_TYPES.SET_ALERT, alert: alertProps });
    }
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export const disposeMessage = () => {
    return dispatch => {
        dispatch({ type: _ALERT_TYPES.NONE });
    }
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export const updateLanguage = (language) => {
    return dispatch => {
        dispatch({ type: _LANGUAGE, content: language });
    }
}
