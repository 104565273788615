import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, AlertTypes } from "../../util/Constant";
import { createMultiPartFormBody, getValidationMessage, arrayGroupByKey } from "../../util/Util";
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import ReactTags from 'react-tag-autocomplete';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const SalesOrderReport = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const [emailConfigurationVisible, setEmailConfigurationVisible] = useState(false);
    const { register, handleSubmit, setValue, errors } = useForm();
    const { register: emailRegister, handleSubmit: handleEmailSubmit, unregister: emailUnregister, errors: emailErrors, setValue : emailSetValue } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [customerOption, setCustomerOption] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});
    const [selectedTarget, setSelectedTarget] = useState([]);
    const [selectedCc, setSelectedCc] = useState([]);
    const [selectedQuotationId, setSelectedQuotationId] = useState();

    var _dispatch = useDispatch();

    let _tableColumns = [
        {
            Header: t("ACTION"),
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                let pdfWindow = window.open("")
                                pdfWindow.document.write("<iframe width='100%' height='100%' src='" + row.original.document + "'></iframe>");
                            }}><span>{t("VIEW_DETAIL")}</span></DropdownItem>
                            <DropdownItem onClick={() => {
                                setSelectedQuotationId(row.original.originalId);
                                setEmailConfigurationVisible(true)
                            }}><span>{t("SEND_EMAIL")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "ORDER_ID",
            accessor: "id",
            disableSortBy: true
        },
        {
            Header: "SERVICER",
            accessor: "servicer",
            disableSortBy: true
        },
        {
            Header: "CUSTOMER",
            accessor: "customer",
            disableSortBy: true
        },
        {
            Header: "CREATED_TIME",
            accessor: "createdTime",
            disableSortBy: true
        },
        {
            Header: "EMAIL_GENERATED",
            accessor: "isEmailed",
            Cell: ({ row }) => {
                return <span>{row.original.isEmailed == 1 ? <span className="badge badge-secondary badge-green">{t("GENERATED")}</span> : <span className="badge badge-secondary badge-warning">{t("PENDING")}</span>}</span>
            },
            minWidth: 100
        }
    ]

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'user' });
        register({ name: 'customer' });
        register({ name: 'endDate' }, { required: true });

        setValue('user', []);
        setValue('customer', '');
        setValue('startDate', moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_CUSTOMER);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var customerList = [];
                responseJson[ApiKey._API_DATA_KEY].map(customer => {
                    customerList.push({ label: customer.username, value: customer.id });
                });
                setCustomerOption(customerList);
            }

            responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?includeSelf=true&role=" + Role._ROLE_WITH_CUSTOMER);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var userListByRole = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "roleName");
                var grouppedUserList = [];

                for (var key in userListByRole) {
                    var userList = [];

                    userListByRole[key].map(user => {
                        userList.push({ label: user.userFullName, value: user.id });
                    });

                    grouppedUserList.push({
                        label: key,
                        value: key,
                        options: userList
                    })
                }

                setUserOption(grouppedUserList);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "User": data.user,
            "Customer": data.customer,
            "ProductCode": data.productCode,
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_SALES_ORDER_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitEmailForm = async (data, e) => {
        let params = {
            quotationId: selectedQuotationId,
            toList: data.target.map((target) => target.name),
            ccList: (data.cc || []).map((target) => target.name),
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_GENERATE_EMAIL_FOR_SO, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setEmailConfigurationVisible(false)
                    handleSubmit(submitForm)();
                }
            }
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onDeleteTarget = function (i) {
        selectedTarget.splice(i, 1);
        setSelectedTarget([...selectedTarget]);
        emailSetValue("target", selectedTarget.length != 0 ? [...selectedTarget] : '');
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onAddTarget = function (i) {
        selectedTarget.push(i);

        setSelectedTarget([...selectedTarget]);
        emailSetValue("target", [...selectedTarget]);
    };


    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onDeleteCc = function (i) {
        selectedCc.splice(i, 1);
        setSelectedCc([...selectedCc]);
        emailSetValue("cc", selectedCc.length != 0 ? [...selectedCc] : '');
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onAddCc = function (i) {
        selectedCc.push(i);

        setSelectedCc([...selectedCc]);
        emailSetValue("cc", [...selectedCc]);
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (emailConfigurationVisible) {
            emailRegister({ name: 'target' }, { required: true });
            emailRegister({ name: 'cc' });
        }
        else {
            emailUnregister({ name: 'target' }, { required: true });
            emailUnregister({ name: 'cc' });
        }
    }, [emailConfigurationVisible]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} fileName={t("SALES_ORDER_REPORT")} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("SALES_ORDER_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("PRODUCT_CODE")}</label>
                                    <input type="text" name="productCode" ref={register} className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CUSTOMER")}</label>
                                    <Select name="customer" options={customerOption} isClearable={true}
                                        placeholder={(customerOption.filter(option => option.value == selectedCustomer)[0] !== undefined) ? (customerOption.filter(option => option.value == selectedCustomer)[0].label) : ""}
                                        value={customerOption.filter(option => option.value == selectedCustomer)}
                                        onChange={(e) => {
                                            if (e) {
                                                setValue('customer', e.value);
                                                setSelectedCustomer(e.value);
                                            }
                                            else {
                                                setValue('customer', '');
                                                setSelectedCustomer('');
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("SERVICER")}</label>
                                    <DualListBox
                                        canFilter
                                        name="user"
                                        options={userOption}
                                        selected={selectedUser}
                                        onChange={(e) => {
                                            setValue('user', e);
                                            setSelectedUser(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <div className="row">
                <Modal isOpen={emailConfigurationVisible}>
                    <form onSubmit={handleEmailSubmit(submitEmailForm)}>
                        <ModalHeader toggle={() => { setEmailConfigurationVisible(!emailConfigurationVisible) }}>
                            {t("GENERATE_SALES_ORDER_EMAIL")}
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("EMAIL_TARGET")}</b></label>
                                <div className="col-md-8">
                                    <ReactTags tags={selectedTarget} placeholder="" allowNew
                                        handleDelete={onDeleteTarget} handleAddition={onAddTarget} />
                                    {emailErrors.target && <div className="invalid-feedback">{t(getValidationMessage(emailErrors.target))}</div>}
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("EMAIL_CC")}</b></label>
                                <div className="col-md-8">
                                    <ReactTags tags={selectedCc} placeholder="" allowNew
                                        handleDelete={onDeleteCc} handleAddition={onAddCc} />
                                    {emailErrors.cc && <div className="invalid-feedback">{t(getValidationMessage(emailErrors.cc))}</div>}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="btn btn-primary">{t('BTN_GENERATE')}</button>
                            <button type="button" className="btn btn-danger" onClick={() => { setEmailConfigurationVisible(!emailConfigurationVisible); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </div>
    );
};

export default SalesOrderReport;