import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { PageSettings } from './../../config/page-settings.js';
import { ApiKey, WebUrl, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { performLogin } from "../../redux/AuthAction.js";
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import { useLocation, useParams } from "react-router-dom";
import background from "../../assets/image/loginBg.jpg";

const Login = props => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    var { email, token } = useParams();
    const _context = useContext(PageSettings);
    const [targetEmail, setTargetEmail] = useState("");
    const [targetToken, setTargetToken] = useState("");
    const [isForgetPasswordVisible, setIsForgetPasswordVisible] = useState(false);
    const _dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => ({
        isLoggedIn: state.authState.isLoggedIn
    }));
    const { register, handleSubmit, errors } = useForm();
    const { register: registerForgetPasswordForm, errors: errorsForgetPasswordForm, handleSubmit: handleForgetPasswordSubmit } = useForm();

    useEffect(() => {
        if (email != ":email") {
            setTargetEmail(email);
            setTargetToken(token);
        }

        window.history.pushState({}, null, `/login`);
        _context.handleSetPageSidebar(false);
        _context.handleSetPageHeader(false);
        _context.handleSetPageContentFullWidth(true);

        return (() => {
            _context.handleSetPageSidebar(true);
            _context.handleSetPageHeader(true);
            _context.handleSetPageContentFullWidth(false);
        });
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (isLoggedIn) {
            _history.replace(WebUrl._URL_MAIN);
        }
    }, [isLoggedIn]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(targetEmail) && !isLoggedIn) {
            performReset();
        }
    }, [targetEmail]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function performReset() {
        let formCollection = {
            "email": targetEmail,
            "token": targetToken
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_USER_RESET_PASSWORD, createMultiPartFormBody(formCollection));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        await _dispatch(performLogin(data.username, data.password));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForgetPasswordForm = async (data, e) => {
        let formCollection = {
            "email": data.email
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_USER_REQUEST_RESET_PASSWORD, createMultiPartFormBody(formCollection));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setIsForgetPasswordVisible(!isForgetPasswordVisible)
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    return (
        <>
            <div className="login login-v1" style={{ backgroundImage: `url(${background})` }}>
                <div className="login-container">
                    <div className="login-header" style={{ justifyContent: "center", textAlign: "center", display: "block", marginTop:"20vh" }}>
                        <img src={require("../../assets/image/logo.png")} style={{ height: "150px" }} />
                        <br />
                        <div className="brand">
                            <b>{t("LOGIN_PAGE_TITLE")}</b>
                        </div>
                    </div>
                    <div className="login-body">
                        <div className="login-content">
                            <form className="margin-bottom-0" onSubmit={handleSubmit(submitForm)}>
                                <div className="form-group m-b-20">
                                    <input type="text" className="form-control form-control-lg" name="username"
                                        ref={register({ required: true })} placeholder={t("USERNAME")} />
                                    {errors.username && <div className="invalid-feedback">{t(getValidationMessage(errors.username))}</div>}
                                </div>
                                <div className="form-group m-b-20">
                                    <input type="password" name="password"
                                        className="form-control form-control-lg"
                                        ref={register({ required:true })}
                                        placeholder={t("PASSWORD")} />
                                    {errors.password && <div className="invalid-feedback">{t(getValidationMessage(errors.password))}</div>}
                                </div>
                                <div className="login-buttons">
                                    <button type="submit" className="btn btn-success btn-block btn-lg">{t("LOGIN")}</button>
                                </div>
                                <div className="form-group m-b-20">
                                    <div class="text-gray-500" style={{ textAlign: "center", marginTop:"10px" }}>
                                        <a href="javascript:;" class="text-muted" onClick={() => setIsForgetPasswordVisible(!isForgetPasswordVisible)}><small>{t("FORGET_PASSWORD")}</small></a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div id="footer" class="footer" style={{ width: "100%", marginLeft: "0px", background:"#fff" }}>
                        <div class="container" style={{ textAlign: "center" }}>
                            <div class="footer-brand" style={{ marginBottom:"15px" }}>
                                © Copyright C.S.L Thean Yeang Aquarium (M) SDN. BHD. 2022
				            </div>
                            <p class="social-list">
                                <a href="https://play.google.com/store/apps/details?id=com.cslcrmapp" target="_blank">
                                    <img src={require("../../assets/image/playstore.png")} style={{ height: "50px", marginRight:"10px", objectFit: "contain" }} />   
                                </a>
                                <a href="https://apps.apple.com/us/app/csl-crm/id1632913356" target="_blank">
                                    <img src={require("../../assets/image/iosstore.png")} style={{ height: "50px", objectFit:"contain"}} />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isForgetPasswordVisible} fade={true}>
                <form onSubmit={handleForgetPasswordSubmit(submitForgetPasswordForm)}>
                    <ModalHeader toggle={() => { setIsForgetPasswordVisible(!isForgetPasswordVisible) }}>
                        {t("RESET_PASSWORD")}
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("EMAIL")}</b></label>
                            <div className="col-md-7">
                                <input type="text"
                                    ref={registerForgetPasswordForm({
                                        required: true, pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "INVALID_EMAIL"
                                        }
                                    })}
                                    name="email"
                                    autoComplete="off"
                                    className="form-control form-control-lg" />
                                {errorsForgetPasswordForm.email && <div className="invalid-feedback">{t(getValidationMessage(errorsForgetPasswordForm.email))}</div>}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_CONFIRM')}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setIsForgetPasswordVisible(!isForgetPasswordVisible); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default withRouter(Login);