import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../util/Util";
import { ApiKey, ApiUrl, AlertTypes, SystemMessageType, WebUrl, DataName, AccessRight } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from '../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import ReactTags from 'react-tag-autocomplete';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const QuickRemarkDetail = props => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, unregister } = useForm();
    const { register: registerOptionForm, errors: errorsOptionForm, handleSubmit: handleOptionSubmit, reset: resetOptionForm } = useForm();
    const [questionId, setQuestionId] = useState('');
    const [question, setQuestion] = useState('');
    const [ordering, setOrdering] = useState('1');
    const [isCompulsory, setIsCompulsory] = useState(true);
    const [hideUnuse, setHideUnuse] = useState(true);
    const [isSupportingRemarkNeeded, setIsSupportingRemarkNeeded] = useState(false);
    const [inputType, setInputType] = useState('Input');
    const [selectedOption, setSelectedOption] = useState([]);
    const [questionOptionModalVisible, setQuestionOptionModalVisible] = useState(false);
    const [questionDetailModalVisible, setQuestionDetailModalVisible] = useState(false);

    let _INPUT_OPTION = [
        {
            label: t("INPUT"),
            value: "Input",
            requiredOption: false
        },
        {
            label: t("RADIO"),
            value: "Radio",
            requiredOption: true
        },
        {
            label: t("SINGLE_SELECT"),
            value: "Select",
            requiredOption: true
        },
        {
            label: t("MULTI_SELECT"),
            value: "Multiselect",
            requiredOption: true
        }
    ];

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._SERVICE_QUICK_REMARK + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setQuestionId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setInputType(responseJson[ApiKey._API_DATA_KEY]["inputType"]);
                setOrdering(responseJson[ApiKey._API_DATA_KEY]["ordering"]);
                setQuestion(responseJson[ApiKey._API_DATA_KEY]["question"]);
                setIsCompulsory(responseJson[ApiKey._API_DATA_KEY]["isCompulsory"]);

                if (_INPUT_OPTION.filter(i => i.value == responseJson[ApiKey._API_DATA_KEY]["inputType"])[0].requiredOption) {
                    register({ name: 'option' }, { required: true });
                }
                else {
                    unregister('option');
                }

                var formattedOption = [];
                responseJson[ApiKey._API_DATA_KEY]["answerModel"].forEach(function (data) {
                    var id = data.answerLabel;
                    var name = data.answerLabel;
                    if (!stringIsNullOrEmpty(data.remarkQuestion)) {
                        id += ";" + data.remarkQuestion;
                        name += " (" + t("REMARK_NEEDED") + ")";
                    }

                    formattedOption.push({ id: id, name: name });
                });
                setSelectedOption(formattedOption);
                setValue("option", [...formattedOption]);
            }
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        if (!stringIsNullOrEmpty(questionId)) {
            params["id"] = questionId;
        }
        else {
            params["status"] = true;
        }

        if (data.option !== undefined) {
            params["rawOption"] = data.option.map((option) => option.id);
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_QUICK_REMARK, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_QUICK_REMARK)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitOptionForm = async (data, e) => {
        var id = data.optionName;
        var name = data.optionName;

        if (isSupportingRemarkNeeded) {
            id += ";" + data.remarkQuestion;
            name += " (" + t("REMARK_NEEDED") + ")";
        }

        selectedOption.push({ name: name, id: id });
        setSelectedOption([...selectedOption]);
        setQuestionOptionModalVisible(false);
        resetOptionForm();
        setValue("option", [...selectedOption]);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onDeleteOption = function (i) {
        selectedOption.splice(i, 1);
        setSelectedOption([...selectedOption]);
        setValue("option", selectedOption.length != 0 ? [...selectedOption] : '');
    };

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={checkIfPermissionExist(AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION, true) ? () => { handleSubmit(submitForm)() } : undefined}>
                            {question ? t("EDIT_QUICK_REMARK") : t("ADD_QUICK_REMARK")}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-3 col-form-label"><b>{t("QUESTION")}</b></label>
                                            <div className="col-md-9">
                                                <textarea type="text" className="form-control" name="question"
                                                    ref={register({ required: true })}
                                                    defaultValue={question} />
                                                {errors.question && <div className="invalid-feedback">{t(getValidationMessage(errors.question))}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-3 col-form-label"><b>{t("QUESTION_ORDER")}</b></label>
                                            <div className="col-md-3">
                                                <input type="number" name="ordering"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                    defaultValue={ordering}
                                                    className="form-control m-b-5" />
                                                {errors.ordering && <div className="invalid-feedback">{t(getValidationMessage(errors.ordering))}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-3 col-form-label"><b>{t("INPUT_TYPE")}</b></label>
                                            <div className="col-md-3">
                                                <select className="form-control" name="inputType" ref={register} value={inputType} onChange={(e) => {
                                                    setInputType(e.target.value);
                                                    if (_INPUT_OPTION.filter(i => i.value == e.target.value)[0].requiredOption) {
                                                        register({ name: 'option' }, { required: true });
                                                    }
                                                    else {
                                                        unregister('option');
                                                    }
                                                }}>
                                                    {
                                                        _INPUT_OPTION.map((data, key) => {
                                                            return (<option value={data.value}>{data.label}</option>);
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {_INPUT_OPTION.filter(i => i.value == inputType)[0].requiredOption &&
                                        <div className="col-lg-12">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-3 col-form-label"><b>{t("OPTION")}</b></label>
                                                <div className="col-md-6 m-b-15">
                                                    <ReactTags tags={selectedOption} placeholder="" handleDelete={onDeleteOption} name="optionTag" id="optionTag" inputAttributes={{ readOnly: true }} />
                                                    {errors.option && <div className="invalid-feedback">{t(getValidationMessage(errors.option))}</div>}
                                                </div>
                                                <div className="col-md-3">
                                                    <button type="button" style={{ height: "auto", marginRight: "15px", marginBottom: "5px" }} onClick={() => {
                                                        setQuestionOptionModalVisible(true);
                                                        setIsSupportingRemarkNeeded(false);
                                                        resetOptionForm();
                                                    }}
                                                        className={"btn btn-primary"}>
                                                        {t("ADD_OPTION")}
                                                    </button>
                                                    <button type="button" style={{ height: "auto", marginBottom: "5px" }} onClick={() => {
                                                        setQuestionDetailModalVisible(true);
                                                    }}
                                                        className={"btn btn-info"}>
                                                        {t("VIEW_OPTION")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-lg-12">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-3 col-form-label"><b>{t("IS_COMPULSORY")}</b></label>
                                            <div className="col-md-3">
                                                <div className="switcher">
                                                    <input type="checkbox" name="isCompulsory" id="isCompulsory"
                                                        onChange={(e) => setIsCompulsory(e.target.checked)}
                                                        value={true}
                                                        checked={isCompulsory}
                                                        ref={register} />
                                                    <label htmlFor="isCompulsory"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <Modal isOpen={questionOptionModalVisible} fade={true}>
                <form onSubmit={handleOptionSubmit(submitOptionForm)}>
                    <ModalHeader toggle={() => { setQuestionOptionModalVisible(!questionOptionModalVisible) }}>
                        {t("NEW_OPTION")}
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("OPTION_NAME")}</b></label>
                            <div className="col-md-8">
                                <input type="text"
                                    ref={registerOptionForm({ required: true })}
                                    name="optionName"
                                    autoComplete="off"
                                    className="form-control" />
                                {errorsOptionForm.optionName && <div className="invalid-feedback">{t(getValidationMessage(errorsOptionForm.optionName))}</div>}
                            </div>
                        </div>
                        {!hideUnuse && <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("IS_SUPPORTING_TEXT_REQUIRED")}</b></label>
                            <div className="col-md-8">
                                <div className="switcher">
                                    <input type="checkbox" name="supportingRemarkRequired" id="supportingRemarkRequired"
                                        onChange={(e) => setIsSupportingRemarkNeeded(e.target.checked)}
                                        value={true}
                                        checked={isSupportingRemarkNeeded}
                                        ref={registerOptionForm} />
                                    <label htmlFor="supportingRemarkRequired"></label>
                                </div>
                            </div>
                        </div>}
                        {isSupportingRemarkNeeded && !hideUnuse && <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("REMARK_QUESTION")}</b></label>
                            <div className="col-md-8">
                                <input type="text"
                                    ref={registerOptionForm({ required: true })}
                                    name="remarkQuestion"
                                    autoComplete="off"
                                    className="form-control" />
                                {errorsOptionForm.remarkQuestion && <div className="invalid-feedback">{t(getValidationMessage(errorsOptionForm.remarkQuestion))}</div>}
                            </div>
                        </div>}
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_ADD')}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setQuestionOptionModalVisible(!questionOptionModalVisible); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
            <Modal isOpen={questionDetailModalVisible} fade={true}>
                <ModalHeader toggle={() => { setQuestionDetailModalVisible(!questionDetailModalVisible) }}>
                    {t("DETAIL")}
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <div className="col-md-12">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{ t("OPTION")}</th>
                                        {!hideUnuse && <th>{t("REMARK_QUESTION")}</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedOption.map((data, key) => {
                                            var labelArr = data.id.split(";");
                                            return (
                                                <tr>
                                                    <td>{labelArr[0]}</td>
                                                    {!hideUnuse && <td>{labelArr.length == 2 ? labelArr[1] : " - "}</td>}
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={() => { setQuestionDetailModalVisible(!questionDetailModalVisible); }}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default withRouter(QuickRemarkDetail);