import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, createMultiPartFormBody } from "../../util/Util";
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';
import DateTime from 'react-datetime';
import moment from 'moment';
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageOperationLog = props => {
    const { t, i18n } = useTranslation();
    const [logUrl, setLogUrl] = useState('');
    var [count, setCount] = useState(0);
    var [filterAction, setFilterAction] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [filterUser, setFilterUser] = useState('');
    const [userOption, setUserOption] = useState([]);
    const [filterDate, setFilterDate] = useState('');
    const [actionTypes, setActionTypes] = useState([]);
    const _language = useSelector(state => state.appState.language);

    let _operationLogTableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            accessor: "actionDetail",
            disableSortBy: true,
        },
        {
            Header: t("VALUE"),
            accessor: "value",
            disableSortBy: true,
            style: {
                wordBreak: 'break-word'
            },
            minWidth: 300
        },
        {
            Header: t("MODIFIED_DATE"),
            accessor: "createdTime",
            disableSortBy: true,
        },
        {
            Header: t("MODIFIED_BY"),
            Cell: ({ row }) => {
                return (
                    <div>
                        <div className="td-singleline">{row.original.createdByName} ({row.original.createdBy})</div>
                    </div>
                );
            },
        },
        {
            Header: t("IP_ADDRESS"),
            accessor: "ipAddress",
            disableSortBy: true,
        },
    ], [_language]);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._ACTION_TYPE);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setActionTypes(responseJson[ApiKey._API_DATA_KEY]);

            if (responseJson[ApiKey._API_DATA_KEY].length != 0) {
                setFilterAction(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
                filterAction = responseJson[ApiKey._API_DATA_KEY][0]["id"];
            }
        }

        var userResponseJson = await ApiEngine.get(ApiUrl._API_GET_USERS);

        if (userResponseJson[ApiKey._API_SUCCESS_KEY]) {
            const userList = [];
            userResponseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                userList.push({ label: user.username, value: user.id });
            });
            setUserOption(userList);
        }

        getOperationLog();
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function getOperationLog() {
        var rawUrl = ApiUrl._API_GET_OPERATION_LOG + "?ActionKey=" + filterAction + "&Value=" + filterValue +
            "&CreatedBy=" + filterUser + "&CreatedTime=" + filterDate + "&v=" + (++count);
        setCount(count);

        setLogUrl(rawUrl);
    }

    return (
        <div>
            <h1 className="page-header">{t('OPERATION_LOG')}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('ACTION')}</b></label>
                                        <select className="form-control m-b-5" onChange={(e) => setFilterAction(e.target.value)}>
                                            {
                                                actionTypes.map((data, value) => {
                                                    return (<option value={data.id}>{data.name}</option>);
                                                })
                                            }
                                            </select>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('VALUE')}</b></label>
                                        <input type="text" className="form-control m-b-5" onInput={(e) => { setFilterValue(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('USER')}</b></label>
                                        <Select name="user" options={userOption}
                                            placeholder={(userOption.filter(option => option.value == filterUser)[0] !== undefined) ? (userOption.filter(option => option.value == filterUser)[0].label) : ""}
                                            value={userOption.filter(option => option.value == filterUser)}
                                            isClearable={ true}
                                            onChange={(e) => {
                                                if (e != null) {
                                                    setFilterUser(e.value);
                                                }
                                                else {
                                                    setFilterUser("");
                                                }
                                            }} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('DATE')}</b></label>
                                        <DateTime
                                            name="date"
                                            timeFormat={false}
                                            dateFormat="YYYY-MM-DD"
                                            value={filterDate}
                                            closeOnSelect={true}
                                            onChange={(e) => {
                                                if (e instanceof moment) {
                                                    setFilterDate(e.format("YYYY-MM-DD"));
                                                }
                                                else {
                                                    setFilterDate('');
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <button className="btn btn-primary" onClick={() => { getOperationLog(); }}>{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable fetchUrl={logUrl} columns={_operationLogTableColumns} globalFilterable={false} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default ManageOperationLog;