import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, DataName, AccessRight, WebUrl } from "../../util/Constant";
import { getValidationMessage, arrayGroupByKey, navigateTo } from "../../util/Util";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const LeadSummaryReport = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});
    let _history = useHistory();

    var _dispatch = useDispatch();

    let _tableColumns = [
        {
            Header: "SERVICER",
            accessor: "servicer",
            disableSortBy: true
        },
        {
            Header: "CUSTOMER",
            accessor: "customerName",
            disableSortBy: true
        },
        {
            Header: "VISIT_COUNT",
            accessor: "visitCount",
            disableSortBy: true
        },
        {
            Header: "EXPECTED_SERVICE_TIME",
            accessor: "serviceTime",
            disableSortBy: true,
        },
        {
            Header: "ACTUAL_SERVICE_TIME",
            Cell: ({ row }) => {
                return <span>{row.original.endServeTime || "-"}</span>
            },
            disableSortBy: true,
        },
        {
            Header: t("STATUS"),
            Cell: ({ row }) => {
                return <span>{row.original.status == 1 ? <span className="badge badge-secondary badge-green">{t("COMPLETED")}</span> : <span className="badge badge-secondary badge-warning">{t("IN_PROGRESS")}</span>}</span>
            },
            minWidth: 100
        },
        {
            Header: t("ACTION"),
            Cell: ({ row }) => {
                if (checkIfPermissionExist(AccessRight._LEAD_REPORT_CONTROL_PERMISSION, false)) {
                    return (<div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    navigateTo(_history, WebUrl._URL_LEAD_DETAIL, row.original)
                                }}><span>{t("VIEW_DETAIL")}</span></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>)
                }
                else {
                    return " - ";
                }
            },
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ];


    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'user' });
        register({ name: 'endDate' }, { required: true });

        setValue('user', []);
        setValue('startDate', moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            const responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?includeSelf=true&role=" + Role._ROLE_WITH_CUSTOMER);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var userListByRole = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "roleName");
                var grouppedUserList = [];

                for (var key in userListByRole) {
                    var userList = [];

                    userListByRole[key].map(user => {
                        userList.push({ label: user.userFullName, value: user.id });
                    });

                    grouppedUserList.push({
                        label: key,
                        value: key,
                        options: userList
                    })
                }

                setUserOption(grouppedUserList);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "User": data.user
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_LEAD_SUMMARY_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} fileName={t("LEAD_SUMMARY_REPORT")} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("LEAD_SUMMARY_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        isValidDate={(e) => {
                                            return !e.isBefore(moment(startDate, "YYYY-MM-DD HH:mm:ss"));
                                        }}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("SERVICER")}</label>
                                    <DualListBox
                                        canFilter
                                        name="user"
                                        options={userOption}
                                        selected={selectedUser}
                                        onChange={(e) => {
                                            setValue('user', e);
                                            setSelectedUser(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default LeadSummaryReport;