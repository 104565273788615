import { createFormBody } from "../util/Util";
import { ApiKey, ApiUrl, SessionKey } from "../util/Constant";
import { showResponseMessage } from "./AppAction";
import i18n from './../localization/i18n';
import ApiEngine from './../util/ApiEngine.js';

export const _LOGIN_SUCCESS = 'LoginSuccess';
export const _LOGIN_FAILED = 'LoginFailed';
export const _LOGOUT = 'Logout';

/// <summary>
/// Author : Yong Sheng Chuan
/// expose login action as function to be called from classes
/// </summary>
export function performLogin(username, password) {
    return async dispatch => {
        //clear the data first
        dispatch({
            type: _LOGOUT
        });

        let params = {
            "username": username,
            "password": password,
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_LOGIN, createFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var userData = responseJson[ApiKey._API_DATA_KEY];

            sessionStorage.setItem(SessionKey._LOGIN_GUID, userData.userId);
            localStorage.setItem(SessionKey._LOGIN_GUID, userData.userId);

            dispatch({
                type: _LOGIN_SUCCESS,
                userData: userData,
            });
        }
        else {
            dispatch({
                type: _LOGIN_FAILED,
                message: responseJson[ApiKey._API_MESSAGE_KEY],
            });
            dispatch(showResponseMessage(responseJson[ApiKey._API_SUCCESS_KEY], i18n.t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
    return {
        type: _LOGIN_SUCCESS,
        userData: userData,
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
    return async dispatch => {
        await ApiEngine.post(ApiUrl._API_LOGOUT);

        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : Yong Sheng Chuan
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });
    }
}