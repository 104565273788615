import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage, isObjectEmpty } from "../../util/Util";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName, Status, WebUrl, Role } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import Select from "react-select";
import Dropzone from 'react-dropzone';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const AccountDetail = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, unregister, triggerValidation } = useForm();

    const [renderPhone, setRenderPhone] = useState(false);
    const _phoneFieldRef = useRef(null);
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");

    const [branchOption, setBranchOption] = useState([]);
    const [customerOption, setCustomerOption] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);

    const [branchId, setBranchId] = useState("");
    const [roleId, setRoleId] = useState("");
    const [roleOptions, setRoleOptions] = useState([]);
    const [isNewImage, setIsNewImage] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState("");
    const [password, setPassword] = useState("");
    const [userFullname, setUserFullname] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [subordinateOption, setSubordinateOption] = useState([]);
    const [selectedSubordinate, setSelectedSubordinate] = useState([]);
    const _userData = useSelector(state => state.authState.userData);

    var _detailUrl = "";
    var _permission = "";
    var _label = "";
    if (props.role == Role._USER_SPECIFIC) {
        _detailUrl = WebUrl._URL_MANAGE_USER;
        _permission = AccessRight._USER_ACCOUNT_CONTROL_PERMISSION;
        _label = "USER";
    }
    else if (props.role == Role._ADMIN_SPECIFIC) {
        _detailUrl = WebUrl._URL_MANAGE_ADMIN;
        _permission = AccessRight._ADMIN_ACCOUNT_CONTROL_PERMISSION;
        _label = "ADMIN";
    }
    else if (props.role == Role._MANAGER) {
        _detailUrl = WebUrl._URL_MANAGE_MANAGER;
        _permission = AccessRight._MANAGER_ACCOUNT_CONTROL_PERMISSION;
        _label = "MANAGER";
    }
    else if (props.role == Role._SALES_REP) {
        _detailUrl = WebUrl._URL_MANAGE_SALES_REP;
        _permission = AccessRight._SALESREP_ACCOUNT_CONTROL_PERMISSION;
        _label = "SALES_REP";
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (props.role != Role._MANAGER && props.role != Role._SALES_REP) {
            register({ name: 'roleId' }, { required: "PLEASE_SELECT_ROLE" });
        }

        register({ name: 'phone' }, { required: true });

        setValue("phone", "");
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            if (_location.state) {
                var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._USER + "&id=" + _location.state["id"];

                var responseJson = await ApiEngine.get(apiUrl);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
                    setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                    setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"] ?? "");
                    setUserFullname(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
                    setPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
                    setConfirmPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
                    setImage(responseJson[ApiKey._API_DATA_KEY]["profileImage"]);
                    setEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
                    setIsActive(responseJson[ApiKey._API_DATA_KEY]["isActive"]);
                    setBranchId(responseJson[ApiKey._API_DATA_KEY]["branchId"])

                    if (props.role != Role._MANAGER && props.role != Role._SALES_REP) {
                        setRoleId(responseJson[ApiKey._API_DATA_KEY]["roleId"]);
                        unregister('roleId');
                    }

                    let selectedSubordinate = [];
                    responseJson[ApiKey._API_DATA_KEY]["subordinate"].forEach(function (salesRep) {
                        selectedSubordinate.push(salesRep);
                    });
                    setSelectedSubordinate(selectedSubordinate);

                    let selectedCustomer = [];
                    responseJson[ApiKey._API_DATA_KEY]["customer"].forEach(function (customer) {
                        selectedCustomer.push(customer);
                    });
                    setSelectedCustomer(selectedCustomer);
                }
            }

            const fullRoleList = [];
            var dataResponseJson = await ApiEngine.get(ApiUrl._API_GET_ROLE +"?includeInternal=true");
            if (dataResponseJson[ApiKey._API_SUCCESS_KEY]) {
                const roleList = [];
                dataResponseJson[ApiKey._API_DATA_KEY].forEach(function (role) {
                    fullRoleList.push({ label: role.roleName, value: role.id });
                });

                dataResponseJson[ApiKey._API_DATA_KEY].filter(i => (i.isAdminRole == props.isAdminRole && i.id != Role._MANAGER && i.id != Role._SALES_REP) || (_location.state && _location.state["id"] == _userData.userId)).forEach(function (role) {
                    roleList.push({ label: role.roleName, value: role.id });
                });

                setRoleOptions(roleList);
            }

            if (props.role == Role._MANAGER) {
                var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?userId=" + _userData.userId + "&role=" + Role._SALES_REP);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let userOptionsByRole = [];
                    fullRoleList.map(role => {
                        let userByRole = responseJson[ApiKey._API_DATA_KEY].filter(user => user.roleId == role.value);

                        if (userByRole.length) {
                            let options = [];

                            userByRole.map(user => {
                                options.push({
                                    label: user.userFullName,
                                    value: user.id,
                                    roleId: user.roleId
                                });
                            });

                            userOptionsByRole.push({
                                label: role.label,
                                value: role.value,
                                options: options
                            })
                        }
                    })

                    setSubordinateOption(userOptionsByRole);
                }
            }

            if (props.role == Role._SALES_REP) {
                var responseJson = await ApiEngine.get(ApiUrl._API_GET_CUSTOMER);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let customerList = [];

                    responseJson[ApiKey._API_DATA_KEY].forEach(function (type) {
                        customerList.push({ label: type.username, value: type.id });
                    });

                    setCustomerOption(customerList);
                }
            }

            var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._BRANCH);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                let branchList = [];

                responseJson[ApiKey._API_DATA_KEY].forEach(function (type) {
                    branchList.push({ label: type.name, value: type.id });
                });

                setBranchOption(branchList);
            }

            setRenderPhone(true);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        if (data.password != data.confirmPassword) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t("PASSWORD_NOT_TALLY_ERROR"),
            }));
            return;
        }

        let params = {
            phoneNumber: data.phone
        }

        if (props.role != Role._MANAGER && props.role != Role._SALES_REP) {
            params["roleId"] = roleId;
        }
        else {
            params["roleId"] = props.role;
        }

        if (!stringIsNullOrEmpty(userId)) {
            params["id"] = userId;
        }
        else {
            params["status"] = true;
        }

        if (props.role == Role._MANAGER) {
            params["empManagerList"] = selectedSubordinate.join(",");
        }

        if ((props.role == Role._SALES_REP || props.role == Role._MANAGER)) {
            params["customerInCharge"] = selectedCustomer.join(",");
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        if (isNewImage && !stringIsNullOrEmpty(imageFile)) {
            params["image"] = imageFile;
        }

        if (stringIsNullOrEmpty(image)) {
            params["image"] = "";
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_USER, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, _detailUrl)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onChangePhoneNumber = (isValid, newNumber, countryData, fullNumber) => {
        setPhone(newNumber.toString().replace(/\s/g, '').replace(/\-/g, ''));
        let formattedNumber = fullNumber.toString().split(/[- ]+/).join('').replace("+", "");
        register({ name: "phone" }, {
            validate: (value) => _phoneFieldRef.current.isValidNumber(newNumber) || stringIsNullOrEmpty(newNumber) || "REQUIRED_VALID_PHONE_NUMBER"
        });

        setValue("phone", stringIsNullOrEmpty(newNumber) ? '' : formattedNumber);
        triggerValidation("phone")
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={checkIfPermissionExist(_permission, true) || _userData.userId == userId ? () => { handleSubmit(submitForm)() } : undefined}>
                            {userId ? t("EDIT_" + _label) : t("ADD_NEW_" + _label)}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <PanelBody>
                                    <div className="row m-b-20">
                                        <div className="col-lg-3">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label><b>{t("USERNAME")}</b></label>
                                                        <input type="text" name="username"
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            defaultValue={username}
                                                            className="form-control m-b-5" />
                                                        {errors.username && <div className="invalid-feedback">{t(getValidationMessage(errors.username))}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label><b>{t("FULLNAME")}</b></label>
                                                        <input type="text" name="userFullName"
                                                            defaultValue={userFullname}
                                                            ref={register({ required: "REQUIRED_TITLE" })}
                                                            className="form-control m-b-5" />
                                                        {errors.userFullName && <div className="invalid-feedback">{t(getValidationMessage(errors.userFullName))}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            &nbsp;
                                                </div>
                                        <div className="col-lg-4">
                                            <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", right: "5%", top: "2%" }}
                                                onClick={() => {
                                                    setImage("");
                                                    setImageFile("");
                                                }}><i className="fa fa-times"></i></a>
                                            <Dropzone accept={'.png,.jpg,.jpeg'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {!stringIsNullOrEmpty(image) && <aside className="thumbsContainer">
                                                                <div className="thumb">
                                                                    <div className="thumbInner">
                                                                        <img
                                                                            src={image}
                                                                            style={{ maxHeight: "200px", width: "100%" }}
                                                                            className="dropzone-img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </aside>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>

                                    <div className="row">
                                        { (_userData.isSuperAdmin || _userData.userId == userId || userId.length == 0) && <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("PASSWORD")}</b></label>
                                                <input type="password" name="password"
                                                    defaultValue={password}
                                                    ref={register({
                                                        required: "PLEASE_ENTER_PASSWORD",
                                                        minLength: { value: 6, message: "PASSWORD_MIN_LENGTH" }
                                                    })}
                                                    className="form-control m-b-5" />
                                                {errors.password && <div className="invalid-feedback">{t(getValidationMessage(errors.password))}</div>}
                                            </div>
                                        </div>}
                                        {(_userData.isSuperAdmin || _userData.userId == userId || userId.length == 0) && <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("CONFIRM_PASSWORD")}</b></label>
                                                <input type="password" name="confirmPassword"
                                                    defaultValue={confirmPassword}
                                                    ref={register({
                                                        required: "ENTER_CONFIRM_NEW_PASSWORD",
                                                        minLength: { value: 6, message: "PASSWORD_MIN_LENGTH" }
                                                    })}
                                                    className="form-control m-b-5" />
                                                {errors.confirmPassword && <div className="invalid-feedback">{t(getValidationMessage(errors.confirmPassword))}</div>}
                                            </div>
                                        </div>}
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("PHONE")}</b></label>
                                                {renderPhone && <IntlTelInput
                                                    fieldName={"phoneNumber"}
                                                    preferredCountries={['my']}
                                                    style={{ display: "block", width: "100%" }}
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control"
                                                    ref={_phoneFieldRef}
                                                    onSelectFlag={(newNumber, countryData, fullNumber, ext) => {
                                                        onChangePhoneNumber(_phoneFieldRef.current.isValidNumber(phone), newNumber, countryData, fullNumber);
                                                    }}
                                                    onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                        onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                    }}
                                                    onPhoneNumberBlur={(isValid, newNumber, countryData, fullNumber, ext) => {
                                                        onChangePhoneNumber(isValid, newNumber, countryData, fullNumber);
                                                    }}
                                                    customPlaceholder={(placeholder, countryData) => {
                                                        return placeholder.split(/[- ]+/).join('');
                                                    }}
                                                    defaultValue={phone}
                                                    value={phone}
                                                    useMobileFullscreenDropdown={false}
                                                    separateDialCode
                                                />}
                                                {(errors.phone) && <div className="invalid-feedback">{t(getValidationMessage(errors.phone))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("EMAIL")}</b></label>
                                                <input type="text" name="email"
                                                    ref={register({
                                                        required: true,
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                            message: "INVALID_EMAIL"
                                                        }
                                                    })}
                                                    defaultValue={email}
                                                    className="form-control m-b-5" />
                                                {errors.email && <div className="invalid-feedback">{t(getValidationMessage(errors.email))}</div>}
                                            </div>
                                        </div>
                                        {((props.role != Role._MANAGER && props.role != Role._SALES_REP) || _userData.userId == userId) &&
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                <label><b>{t("ROLES")}</b></label>
                                                <Select name="roleId" options={roleOptions} isDisabled={_userData.userId == userId}
                                                        placeholder={(roleOptions.filter(option => option.value == roleId)[0] !== undefined) ? (roleOptions.filter(option => option.value == roleId)[0].label) : ""}
                                                        value={roleOptions.filter(option => option.value == roleId)}
                                                        onChange={(e) => {
                                                            unregister('roleId');
                                                            setRoleId(e.value);
                                                        }} />
                                                    {errors.roleId && <div className="invalid-feedback">{t(getValidationMessage(errors.roleId))}</div>}
                                                </div>
                                            </div>
                                        }
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("BRANCH")}</b></label><br />
                                                <select name="branchId" ref={register} class="form-control" onChange={(e) => { setBranchId(e.target.value) }} value={branchId}>
                                                    {
                                                        branchOption.map((data, index) => {
                                                            return (<option value={data.value}>{data.label}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {_userData.userId != userId && <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("STATUS")}</b></label><br />
                                                <div className="switcher">
                                                    <input type="checkbox" name="isActive" id="isActive" className="form-control"
                                                        onChange={(e) => setIsActive(e.target.checked)}
                                                        value={true}
                                                        checked={isActive}
                                                        ref={register} />
                                                    <label htmlFor="isActive"></label>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="row">
                                        {props.role == Role._MANAGER && _userData.userId != userId &&
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label><b>{t("SUBORDINATE")}</b></label>
                                                    <DualListBox
                                                        canFilter
                                                        selected={selectedSubordinate}
                                                        options={subordinateOption}
                                                        onChange={(e) => { setSelectedSubordinate(e) }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {(props.role == Role._SALES_REP) && _userData.userId != userId &&
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label><b>{t("CUSTOMER")}</b></label>
                                                    <DualListBox
                                                        canFilter
                                                        selected={selectedCustomer}
                                                        options={customerOption}
                                                        onChange={(e) => { setSelectedCustomer(e) }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </PanelBody>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AccountDetail);