import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage, isObjectEmpty } from "../../util/Util";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName, WebUrl } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import Select from "react-select";
import Dropzone from 'react-dropzone';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import DateTime from 'react-datetime';
import moment from 'moment';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ProductDetail = props => {
    var _history = useHistory();
    var { t } = useTranslation();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var { register, handleSubmit, errors, unregister, setValue, getValues, reset, triggerValidation } = useForm();

    const _writePermission = checkIfPermissionExist(AccessRight._PRODUCT_CONTROL_PERMISSION, true);
    const _readPermission = checkIfPermissionExist(AccessRight._PRODUCT_CONTROL_PERMISSION, false);
    const [imageToRemoved, setImageToRemoved] = useState([]);
    const [image, setImage] = useState([]);
    const [imageFile, setImageFile] = useState([]);
    const [promotionImageToRemoved, setPromotionImageToRemoved] = useState([]);
    const [promotionImage, setPromotionImage] = useState([]);
    const [promotionImageFile, setPromotionImageFile] = useState([]);
    const [branchOption, setBranchOption] = useState([]);

    const [productId, setProductId] = useState(null);
    const [productCode, setProductCode] = useState("");
    const [description, setDescription] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [categoryId, setCategoryId] = useState("");

    const [priceSetting, setPriceSetting] = useState({});
    const [promotionPriceSetting, setPromotionPriceSetting] = useState([]);
    const [isActive, setIsActive] = useState(true);
    const [isPromotion, setIsPromotion] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [promotionStartDate, setPromotionStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [promotionEndDate, setPromotionEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

    const _DETAIL_TAB = "1";
    const _PRICING_TAB = "2";
    const _PROMOTION_TAB = "3";
    const [visibleTab, setVisibleTab] = useState(_DETAIL_TAB);
    const [priceVisibleTab, setPriceVisibleTab] = useState("1");
    const [promotionVisibleTab, setPromotionVisibleTab] = useState("1");
    const _userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    async function init() {
        register({ name: 'categoryId' }, { required: true });

        var dataResponseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._PRODUCT_CATEGORY);
        if (dataResponseJson[ApiKey._API_SUCCESS_KEY]) {
            const categoryList = [];
            dataResponseJson[ApiKey._API_DATA_KEY].forEach(function (category) {
                categoryList.push({ label: category.name, value: category.id });
            });

            setCategoryOptions(categoryList);
        }

        var branchList = [];
        var dataResponseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._BRANCH);
        if (dataResponseJson[ApiKey._API_SUCCESS_KEY]) {
            dataResponseJson[ApiKey._API_DATA_KEY].forEach(function (branch) {
                if (_userData.isAdmin || _writePermission || branch.id == _userData.branchId) {
                    branchList.push({ name: branch.name, id: branch.id + "" });
                }
            });
        }

        setPriceVisibleTab(branchList[0].id);
        setPromotionVisibleTab(branchList[0].id);

        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._PRODUCT + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setProductId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                setIsPromotion(responseJson[ApiKey._API_DATA_KEY]["isPromotion"]);
                setIsActive(responseJson[ApiKey._API_DATA_KEY]["isActive"]);
                setCategoryId(responseJson[ApiKey._API_DATA_KEY]["categoryId"]);
                setLongDescription(responseJson[ApiKey._API_DATA_KEY]["longDescription"]);
                setProductCode(responseJson[ApiKey._API_DATA_KEY]["productCode"]);

                var normalPriceDetail = {};
                responseJson[ApiKey._API_DATA_KEY]["priceListModel"].map((data, index) => {
                    normalPriceDetail[data.branchId + ""] = data;
                })

                var promotionPriceDetail = {};
                responseJson[ApiKey._API_DATA_KEY]["promotionPriceListModel"].map((data, index) => {
                    promotionPriceDetail[data.branchId + ""] = data;
                })

                branchList.map((data, index) => {
                    if (promotionPriceDetail[data.id + ""] === undefined) {
                        promotionPriceDetail[data.id + ""] = {
                            startDate: moment().startOf('month').format('YYYY-MM-DD'),
                            endDate: moment().endOf('month').format('YYYY-MM-DD')
                        }
                        promotionPriceDetail[data.id + ""].tierPrice = [];
                    }
                    if (normalPriceDetail[data.id + ""] === undefined) {
                        normalPriceDetail[data.id + ""] = {}
                        normalPriceDetail[data.id + ""].tierPrice = [];
                    }
                })

                setPriceSetting(normalPriceDetail);
                setPromotionPriceSetting(promotionPriceDetail);

                unregister('categoryId');

                var newImageList = [];
                responseJson[ApiKey._API_DATA_KEY]["image"].map(function (image) {
                    newImageList.push({ id: image.id, data: image.image });
                });

                setImage([...newImageList]);

                var newPromotionImageList = [];
                responseJson[ApiKey._API_DATA_KEY]["promotionImage"].map(function (image) {
                    newPromotionImageList.push({ id: image.id, data: image.image });
                });

                setPromotionImage([...newPromotionImageList]);
            }
        }
        else {
            var dummyPriceSetting = {}
            var dummyPromotionPriceSetting = {}
            branchList.forEach(function (branch) {
                dummyPriceSetting[branch.id + ""] = {}
                dummyPriceSetting[branch.id + ""].tierPrice = [];
                dummyPriceSetting[branch.id + ""].try = 1;

                dummyPromotionPriceSetting[branch.id + ""] = {
                    startDate: moment().startOf('month').format('YYYY-MM-DD'),
                    endDate: moment().endOf('month').format('YYYY-MM-DD')
                }

                dummyPromotionPriceSetting[branch.id + ""].tierPrice = [];
            });

            setPriceSetting(dummyPriceSetting);
            setPromotionPriceSetting(dummyPromotionPriceSetting);
        }

        setBranchOption(branchList);
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var priceListData = [];
        var promotionPriceListData = [];
        branchOption.map((optionData, index) => {
            if (data["isCloned_" + optionData.id]) {
                var referenceData = JSON.parse(JSON.stringify(priceListData[0]));
                referenceData.branchId = optionData.id;
                referenceData.isCloned = true;
                priceListData.push(referenceData);
            }
            else {
                var normalPriceData = {
                    branchId: optionData.id,
                    price: data["price_" + optionData.id],
                    sellingPrice: data["sellingPrice_" + optionData.id],
                    discountedPrice: data["sellingPrice_" + optionData.id],
                    remark: data["basicRemark_" + optionData.id],
                    isAvailable: data["isEnabled_" + optionData.id],
                    isCloned: data["isCloned_" + optionData.id],
                    tierPrice: []
                }

                if (data["tierPrice_" + optionData.id] !== undefined) {
                    data["tierPrice_" + optionData.id].map((option, key) => {
                        normalPriceData.tierPrice.push({
                            remark: data["remark_" + optionData.id][key],
                            sellingPrice: data["tierSellingPrice_" + optionData.id][key],
                            discountedPrice: data["tierSellingPrice_" + optionData.id][key],
                            quantity: data["quantity_" + optionData.id][key],
                            price: option,
                        });
                    })
                }

                priceListData.push(normalPriceData);
            }

            if (data["isPromotionCloned_" + optionData.id]) {
                var referenceData = JSON.parse(JSON.stringify(promotionPriceListData[0]));
                referenceData.branchId = optionData.id;
                referenceData.isCloned = true;
                promotionPriceListData.push(referenceData);
            }
            else {
                var promotionPriceData = {
                    branchId: optionData.id,
                    description: data["promotionDescription_" + optionData.id],
                    startDate: promotionPriceSetting[optionData.id].startDate,
                    endDate: promotionPriceSetting[optionData.id].endDate,
                    price: data["promotionPrice_" + optionData.id],
                    sellingPrice: data["promotionSellingPrice_" + optionData.id],
                    remark: data["promotionBasicRemark_" + optionData.id],
                    discountedPrice: data["promotionSellingPrice_" + optionData.id],
                    isAvailable: data["isPromotionEnabled_" + optionData.id],
                    isCloned: data["isPromotionCloned_" + optionData.id],
                    tierPrice: []
                }

                if (data["promotionTierPrice_" + optionData.id] !== undefined) {
                    data["promotionTierPrice_" + optionData.id].map((option, key) => {
                        promotionPriceData.tierPrice.push({
                            remark: data["promotionRemark_" + optionData.id][key],
                            sellingPrice: data["promotionTierSellingPrice_" + optionData.id][key],
                            discountedPrice: data["promotionTierSellingPrice_" + optionData.id][key],
                            quantity: data["promotionQuantity_" + optionData.id][key],
                            price: option,
                        });
                    })
                }

                promotionPriceListData.push(promotionPriceData);

                delete data["promotionRemark_" + optionData.id];
                delete data["promotionTierSellingPrice_" + optionData.id];
                delete data["promotionTierPrice_" + optionData.id];
                delete data["promotionQuantity_" + optionData.id];
                delete data["promotionPrice_" + optionData.id];
                delete data["promotionSellingPrice_" + optionData.id];
                delete data["promotionRemark_" + optionData.id];
                delete data["isPromotionEnabled_" + optionData.id];
                delete data["isPromotionCloned_" + optionData.id];

                delete data["remark_" + optionData.id];
                delete data["tierSellingPrice_" + optionData.id];
                delete data["tierPrice_" + optionData.id];
                delete data["quantity_" + optionData.id];
                delete data["price_" + optionData.id];
                delete data["sellingPrice_" + optionData.id];
                delete data["remark_" + optionData.id];
                delete data["isEnabled_" + optionData.id];
                delete data["isCloned_" + optionData.id];
            }
        });

        let params = {
            categoryId: categoryId,
            "imageToRemoved": imageToRemoved,
            "promotionImageToRemoved": promotionImageToRemoved,
            "isActive": isActive,
            "promotionStartDate": promotionStartDate,
            "promotionEndDate": promotionEndDate,
            "name": data.description,
            priceSetting: JSON.stringify(priceListData),
            promotionPriceSetting: JSON.stringify(promotionPriceListData),
        }

        if (!stringIsNullOrEmpty(productId)) {
            params["id"] = productId;
        }
        else {
            params["status"] = true;
        }

        imageFile.map(function (file, i) {
            params["image" + i] = file;
        })

        promotionImageFile.map(function (file, i) {
            params["promotionImage" + i] = file;
        })

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_PRODUCT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setImageFile([]);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_PRODUCT)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const promotionImageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            promotionImageFile.push(file);
            setPromotionImageFile([...promotionImageFile]);

            const reader = new FileReader();
            reader.onloadend = () => {
                promotionImage.push({ id: undefined, data: reader.result });
                setPromotionImage([...promotionImage]);
            }

            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            imageFile.push(file);
            setImageFile([...imageFile]);

            const reader = new FileReader();
            reader.onloadend = () => {
                image.push({ id: undefined, data: reader.result });
                setImage([...image]);
            }

            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function addSetting(branchId, isPromotion) {
        var localSetting = [{
            quantity: 0,
            price: 0,
            sellingPrice: 0,
            remark: ""
        }];

        if (isPromotion) {
            setPromotionPriceSetting((prev) => ({ ...prev, [branchId]: { ...prev[branchId], tierPrice: [...prev[branchId].tierPrice, ...localSetting] } }));
        }
        else {
            setPriceSetting((prev) => ({ ...prev, [branchId]: { ...prev[branchId], tierPrice: [...prev[branchId].tierPrice, ...localSetting] } }));
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function submitProductForm() {
        await triggerValidation();

        if (errors.promotionDescription || errors.promotionPrice || errors.promotionSellingPrice || errors.promotionQuantity || errors.promotionTierPrice || errors.promotionTierSellingPrice) {
            setVisibleTab(_PROMOTION_TAB);
        }
        else if (!isObjectEmpty(errors)) {
            setVisibleTab(_DETAIL_TAB);
        }
        else {
            handleSubmit(submitForm)();
        }
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={_writePermission ? () => { submitProductForm() } : undefined}>
                            {productId ? t("EDIT_PROUCT") : t("ADD_NEW_PRODUCT")}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <PanelBody>
                                    <Nav className="mb-3" pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: visibleTab == _DETAIL_TAB })}
                                                onClick={() => { setVisibleTab(_DETAIL_TAB); }} >
                                                <span className="d-sm-none">{t("DETAIL")}</span>
                                                <span className="d-sm-block d-none">{t("DETAIL")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: visibleTab == _PRICING_TAB })}
                                                onClick={() => { setVisibleTab(_PRICING_TAB); }} >
                                                <span className="d-sm-none">{t("PRICING_DETAIL")}</span>
                                                <span className="d-sm-block d-none">{t("PRICING_DETAIL")}</span>
                                            </NavLink>
                                        </NavItem>
                                        {isPromotion && <NavItem>
                                            <NavLink
                                                className={classnames({ active: visibleTab == _PROMOTION_TAB })}
                                                onClick={() => { setVisibleTab(_PROMOTION_TAB); }} >
                                                <span className="d-sm-none">{t("PROMOTION_DETAIL")}</span>
                                                <span className="d-sm-block d-none">{t("PROMOTION_DETAIL")}</span>
                                            </NavLink>
                                        </NavItem>}
                                    </Nav>
                                    <hr />
                                    <TabContent className="p-15 rounded bg-white mb-4" activeTab={visibleTab}>
                                        <TabPane tabId={_DETAIL_TAB}>
                                            {(_writePermission || image.length != 0) && <div className="row m-b-5">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label><b>{t("PRODUCT_IMAGE")}</b></label><br />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {(_writePermission || image.length != 0) &&
                                                <>
                                                    <div className="row m-b-5">
                                                        {_writePermission &&
                                                            <div className="col-lg-3 m-b-10">
                                                                <Dropzone accept={'.png,.jpg,.jpeg'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section>
                                                                            <div className="dropzone" style={{ textAlign: "center", height: "150px" }} {...getRootProps()}>
                                                                                <input {...getInputProps()} />
                                                                                <b style={{ color: "grey", margin: "10px" }}>{t("UPLOAD_IMAGE")}</b>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        }
                                                        {
                                                            image.map(function (item, i) {
                                                                return (
                                                                    <div className="col-lg-3 m-b-10">

                                                                        <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", left: "0%", top: "0%", margin: "5px 5px 5px 15px" }}
                                                                            onClick={() => {
                                                                                if (item.id !== undefined) {
                                                                                    imageToRemoved.push(item.id);
                                                                                    setImageToRemoved([...imageToRemoved]);
                                                                                }
                                                                                image.splice(i, 1);
                                                                                imageFile.splice(i, 1);

                                                                                setImage([...image]);
                                                                                setImageFile([...imageFile]);
                                                                            }}><i className="fa fa-times"></i></a>
                                                                        <Dropzone accept={'.png,.jpg,.jpeg'}>
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <section>
                                                                                    <div className="dropzone" style={{ height: "150px", textAlign: "center" }} {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        <aside className="thumbsContainer">
                                                                                            <div className="thumb">
                                                                                                <div className="thumbInner">
                                                                                                    <img
                                                                                                        src={item.data}
                                                                                                        style={{ height: "120px",  width: "100%" }}
                                                                                                        className="dropzone-img"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </aside>
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                            <div className="row m-b-20">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("PRODUCT_CODE")}</b></label>
                                                        <input type="text" name="productCode"
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            defaultValue={productCode}
                                                            className="form-control m-b-5" />
                                                        {errors.productCode && <div className="invalid-feedback">{t(getValidationMessage(errors.productCode))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-9">
                                                    <div className="form-group">
                                                        <label><b>{t("DESCRIPTION")}</b></label>
                                                        <input type="text" name="description"
                                                            defaultValue={description}
                                                            ref={register({ required: true })}
                                                            className="form-control m-b-5" />
                                                        {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-b-20">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label><b>{t("DETAIL_DESCRIPTION")}</b></label>
                                                        <textarea type="text" name="longDescription"
                                                            defaultValue={longDescription}
                                                            ref={register({ required: true })}
                                                            className="form-control m-b-5" />
                                                        {errors.longDescription && <div className="invalid-feedback">{t(getValidationMessage(errors.longDescription))}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-b-20">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("CATEGORY")}</b></label>
                                                        <Select name="categoryId" options={categoryOptions}
                                                            placeholder={(categoryOptions.filter(option => option.value == categoryId)[0] !== undefined) ? (categoryOptions.filter(option => option.value == categoryId)[0].label) : ""}
                                                            value={categoryOptions.filter(option => option.value == categoryId)}
                                                            onChange={(e) => {
                                                                unregister('categoryId');
                                                                setCategoryId(e.value);
                                                            }} />
                                                        {errors.categoryId && <div className="invalid-feedback">{t(getValidationMessage(errors.categoryId))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("IS_PROMOTION")}</b></label><br />
                                                        <div className="switcher">
                                                            <input type="checkbox" name="isPromotion" id="isPromotion"
                                                                onChange={(e) => setIsPromotion(e.target.checked)}
                                                                value={true}
                                                                checked={isPromotion}
                                                                ref={register} />
                                                            <label htmlFor="isPromotion"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("IS_ACTIVE")}</b></label><br />
                                                        <div className="switcher">
                                                            <input type="checkbox" name="isActive" id="isActive"
                                                                onChange={(e) => setIsActive(e.target.checked)}
                                                                value={true}
                                                                checked={isActive}
                                                                ref={register} />
                                                            <label htmlFor="isActive"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId={_PRICING_TAB}>
                                            <Panel>
                                                <Nav tabs>
                                                    {
                                                        branchOption.map((data, index) => {
                                                            return (<NavItem>
                                                                <NavLink
                                                                    className={classnames({ active: priceVisibleTab === (data.id) })}
                                                                    onClick={() => { setPriceVisibleTab(data.id); }} >
                                                                    <span className="d-sm-none">{data.name}</span>
                                                                    <span className="d-sm-block d-none">{data.name}</span>
                                                                </NavLink>
                                                            </NavItem>);
                                                        })
                                                    }
                                                </Nav>
                                                <TabContent activeTab={priceVisibleTab} style={{ margin: "1px", border: "0.5px solid rgb(250, 250, 250)", backgroundColor: "rgb(250, 250, 250)" }}>
                                                    {
                                                        branchOption.map((data, index) => {
                                                            return (
                                                                <TabPane tabId={data.id}>
                                                                    <div className="row m-b-5">
                                                                        <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label><b>{t("ENABLED")}</b></label><br />
                                                                                <div className="switcher">
                                                                                    <input type="checkbox" name={"isEnabled_" + data.id} id={"isEnabled_" + data.id}
                                                                                        onChange={(e) => {
                                                                                            setPriceSetting((prev) => ({ ...prev, [data.id]: { ...prev[data.id], isAvailable: !priceSetting[data.id].isAvailable } }));
                                                                                        }}
                                                                                        value={true}
                                                                                        checked={priceSetting[data.id].isAvailable}
                                                                                        ref={register} />
                                                                                    <label htmlFor={"isEnabled_" + data.id}></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {data.id != "1" && priceSetting[data.id].isAvailable && <div className="col-md-3">
                                                                            <div className="form-group">
                                                                                <label><b>{t("CLONE_FROM_MAIN")}</b></label><br />
                                                                                <div className="switcher">
                                                                                    <input type="checkbox" name={"isCloned_" + data.id} id={"isCloned_" + data.id}
                                                                                        onChange={(e) => {
                                                                                            setPriceSetting((prev) => ({ ...prev, [data.id]: { ...prev[data.id], isCloned: !priceSetting[data.id].isCloned } }));
                                                                                        }}
                                                                                        value={true}
                                                                                        checked={priceSetting[data.id].isCloned}
                                                                                        ref={register} />
                                                                                    <label htmlFor={"isCloned_" + data.id}></label>
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                    {priceSetting[data.id].isAvailable &&
                                                                        (data.id == "1" || !priceSetting[data.id].isCloned || (_readPermission && !_writePermission)) &&
                                                                        <div>
                                                                            <div className="row m-b-5">
                                                                                <div className="col-lg-3">
                                                                                    <div className="form-group">
                                                                                        <label><b>{t("PRICE")}</b></label>
                                                                                        <input type="number" name={"price_" + data.id}
                                                                                            ref={register({
                                                                                                required: true,
                                                                                            })}
                                                                                            defaultValue={priceSetting[data.id].price}

                                                                                            className="form-control m-b-5" />
                                                                                        {errors["price_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["price_" + data.id]))}</div>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3">
                                                                                    <div className="form-group">
                                                                                        <label><b>{t("SELLING_PRICE")}</b></label>
                                                                                        <input type="number" name={"sellingPrice_" + data.id}
                                                                                            ref={register({
                                                                                                required: true,
                                                                                            })}
                                                                                            defaultValue={priceSetting[data.id].sellingPrice}
                                                                                            className="form-control m-b-5" />
                                                                                        {errors["sellingPrice_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["sellingPrice_" + data.id]))}</div>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label><b>{t("REMARK")}</b></label>
                                                                                        <input type="text" name={"basicRemark_" + data.id}
                                                                                            ref={register}
                                                                                            defaultValue={priceSetting[data.id].remark}
                                                                                            className="form-control m-b-5" />
                                                                                        {errors["basicRemark_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["basicRemark_" + data.id]))}</div>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            {_writePermission && <div className="row m-b-15">
                                                                                <div className="col-md-12">
                                                                                    <button type="button" className="btn btn-primary" style={{ "marginRight": "10px" }} onClick={() => { addSetting(data.id) }}>{t('ADD_NEW_PRICE')}</button>
                                                                                </div>
                                                                            </div>}
                                                                            {!isObjectEmpty(priceSetting) && priceSetting[data.id].tierPrice.length != 0 &&
                                                                                <div className="row m-b-20">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="form-group">
                                                                                            <table style={{ width: "100%" }} className="table table-bordered table-sm">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("QUANTITY")}</th>
                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("PRICE")}</th>
                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("SELLING_PRICE")}</th>
                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("REMARK")}</th>
                                                                                                        {_writePermission && <th style={{ borderBottom: "1px solid #ddd" }}>{t("ACTION")}</th>}
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        priceSetting[data.id].tierPrice.map((priceData, key) => {
                                                                                                            return (
                                                                                                                <tr key={key}>
                                                                                                                    <td>
                                                                                                                        <input className="form-control" type="number" name={"quantity_" + data.id + "[" + key + "]"}
                                                                                                                            ref={register({
                                                                                                                                required: "PLEASE_ENTER_VALID_NUMBER",
                                                                                                                                min: {
                                                                                                                                    value: 2,
                                                                                                                                    message: t("PLEASE_ENTER_VALUE_GREATER_THAN", { number: 2 })
                                                                                                                                }
                                                                                                                            })}
                                                                                                                            defaultValue={priceData.quantity} />
                                                                                                                        {errors["quantity_" + data.id] && errors["quantity_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["quantity_" + data.id][key]))}</div>}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <input className="form-control" type="number" name={"tierPrice_" + data.id + "[" + key + "]"} step="0.01"
                                                                                                                            ref={register({
                                                                                                                                required: "PLEASE_ENTER_VALID_NUMBER",
                                                                                                                                min: {
                                                                                                                                    value: 1,
                                                                                                                                    message: t("PLEASE_ENTER_VALUE_GREATER_THAN", { number: 1 })
                                                                                                                                }
                                                                                                                            })}
                                                                                                                            defaultValue={priceData.price} />
                                                                                                                        {errors["tierPrice_" + data.id] && errors["tierPrice_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["tierPrice_" + data.id][key]))}</div>}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <input className="form-control" type="number" name={"tierSellingPrice_" + data.id + "[" + key + "]"} step="0.01"
                                                                                                                            ref={register({
                                                                                                                                required: "PLEASE_ENTER_VALID_NUMBER",
                                                                                                                                min: {
                                                                                                                                    value: 1,
                                                                                                                                    message: t("PLEASE_ENTER_VALUE_GREATER_THAN", { number: 1 })
                                                                                                                                }
                                                                                                                            })}
                                                                                                                            defaultValue={priceData.sellingPrice} />
                                                                                                                        {errors["tierSellingPrice_" + data.id] && errors["tierSellingPrice_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["tierSellingPrice_" + data.id][key]))}</div>}
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <input className="form-control" type="text" name={"remark_" + data.id + "[" + key + "]"}
                                                                                                                            ref={register}
                                                                                                                            defaultValue={priceData.remark} />
                                                                                                                        {errors["remark_" + data.id] && errors["remark_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["remark_" + data.id][key]))}</div>}
                                                                                                                    </td>
                                                                                                                    {_writePermission && <td style={{ borderBottom: "1px solid #ddd", padding: "10px", textAlign: "center" }}>
                                                                                                                        <i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => {
                                                                                                                            var newSettingArr = [];

                                                                                                                            priceSetting[data.id].sellingPrice = getValues()["sellingPrice_" + data.id];
                                                                                                                            priceSetting[data.id].price = getValues()["price_" + data.id];

                                                                                                                            priceSetting[data.id].tierPrice.map((ea, index) => {
                                                                                                                                if (key != index) {
                                                                                                                                    newSettingArr.push({
                                                                                                                                        quantity: getValues()["quantity_" + data.id + "[" + index + "]"],
                                                                                                                                        price: getValues()["tierPrice_" + data.id + "[" + index + "]"],
                                                                                                                                        sellingPrice: getValues()["tierSellingPrice_" + data.id + "[" + index + "]"],
                                                                                                                                        remark: getValues()["remark_" + data.id + "[" + index + "]"]
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            })


                                                                                                                            setPriceSetting((prev) => ({ ...prev, [data.id]: { ...prev[data.id], tierPrice: newSettingArr } }));

                                                                                                                            newSettingArr.map((ea, index) => {
                                                                                                                                reset({
                                                                                                                                    ["quantity_" + data.id + "[" + index + "]"]: ea.quantity,
                                                                                                                                    ["tierPrice_" + data.id + "[" + index + "]"]: ea.price,
                                                                                                                                    ["tierSellingPrice_" + data.id + "[" + index + "]"]: ea.sellingPrice,
                                                                                                                                    ["remark_" + data.id + "[" + index + "]"]: ea.remark
                                                                                                                                });
                                                                                                                            });
                                                                                                                        }}></i>
                                                                                                                    </td>}
                                                                                                                </tr>
                                                                                                            );
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </TabPane>
                                                            )
                                                        })
                                                    }
                                                </TabContent>
                                            </Panel>
                                        </TabPane>

                                        {isPromotion && <TabPane tabId={_PROMOTION_TAB}>
                                            {(_writePermission || promotionImage.length != 0) && <div className="row m-b-5">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label><b>{t("PRODUCT_IMAGE")}</b></label><br />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {(_writePermission || promotionImage.length != 0) &&
                                                <>
                                                    <div className="row m-b-5">
                                                        {_writePermission &&
                                                            <div className="col-lg-3 m-b-10">
                                                                <Dropzone accept={'.png,.jpg,.jpeg'} onDrop={acceptedFiles => promotionImageDrop(acceptedFiles)}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <section>
                                                                            <div className="dropzone" style={{ textAlign: "center", height: "150px" }} {...getRootProps()}>
                                                                                <input {...getInputProps()} />
                                                                                <b style={{ color: "grey", margin: "10px" }}>{t("UPLOAD_IMAGE")}</b>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        }
                                                        {
                                                            promotionImage.map(function (item, i) {
                                                                return (
                                                                    <div className="col-lg-3 m-b-10">

                                                                        <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", left: "0%", top: "0%", margin: "5px 5px 5px 15px" }}
                                                                            onClick={() => {
                                                                                if (item.id !== undefined) {
                                                                                    promotionImageToRemoved.push(item.id);
                                                                                    setPromotionImageToRemoved([...imageToRemoved]);
                                                                                }
                                                                                promotionImage.splice(i, 1);
                                                                                promotionImageFile.splice(i, 1);

                                                                                setPromotionImage([...promotionImage]);
                                                                                setPromotionImageFile([...promotionImageFile]);
                                                                            }}><i className="fa fa-times"></i></a>
                                                                        <Dropzone accept={'.png,.jpg,.jpeg'}>
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <section>
                                                                                    <div className="dropzone" style={{ height: "150px", textAlign: "center" }} {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        <aside className="thumbsContainer">
                                                                                            <div className="thumb">
                                                                                                <div className="thumbInner">
                                                                                                    <img
                                                                                                        src={item.data}
                                                                                                        style={{ height: "120px", width: "100%" }}
                                                                                                        className="dropzone-img"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </aside>
                                                                                    </div>
                                                                                </section>
                                                                            )}
                                                                        </Dropzone>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <hr />
                                                </>
                                            }
                                            <div className="row m-b-20">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <Panel>
                                                            <Nav tabs>
                                                                {
                                                                    branchOption.map((data, index) => {
                                                                        return (<NavItem>
                                                                            <NavLink
                                                                                className={classnames({ active: promotionVisibleTab === (data.id) })}
                                                                                onClick={() => { setPromotionVisibleTab(data.id); }} >
                                                                                <span className="d-sm-none">{data.name}</span>
                                                                                <span className="d-sm-block d-none">{data.name}</span>
                                                                            </NavLink>
                                                                        </NavItem>);
                                                                    })
                                                                }
                                                            </Nav>
                                                            <TabContent activeTab={promotionVisibleTab} style={{ margin: "1px", border: "0.5px solid rgb(250, 250, 250)", backgroundColor: "rgb(250, 250, 250)" }}>
                                                                {
                                                                    branchOption.map((data, index) => {
                                                                        if (priceSetting[data.id].isAvailable) {
                                                                            return (
                                                                                <TabPane tabId={data.id}>
                                                                                    <div className="row m-b-5">
                                                                                        <div className="col-md-3">
                                                                                            <div className="form-group">
                                                                                                <label><b>{t("ENABLED")}</b></label><br />
                                                                                                <div className="switcher">
                                                                                                    <input type="checkbox" name={"isPromotionEnabled_" + data.id} id={"isPromotionEnabled_" + data.id}
                                                                                                        onChange={(e) => {
                                                                                                            setPromotionPriceSetting((prev) => ({ ...prev, [data.id]: { ...prev[data.id], isAvailable: !promotionPriceSetting[data.id].isAvailable } }));
                                                                                                        }}
                                                                                                        value={true}
                                                                                                        checked={promotionPriceSetting[data.id].isAvailable}
                                                                                                        ref={register} />
                                                                                                    <label htmlFor={"isPromotionEnabled_" + data.id}></label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {data.id != "1" && promotionPriceSetting[data.id].isAvailable && <div className="col-md-3">
                                                                                            <div className="form-group">
                                                                                                <label><b>{t("CLONE_FROM_MAIN")}</b></label><br />
                                                                                                <div className="switcher">
                                                                                                    <input type="checkbox" name={"isPromotionCloned_" + data.id} id={"isPromotionCloned_" + data.id}
                                                                                                        onChange={(e) => {
                                                                                                            setPromotionPriceSetting((prev) => ({ ...prev, [data.id]: { ...prev[data.id], isCloned: !promotionPriceSetting[data.id].isCloned } }));
                                                                                                        }}
                                                                                                        value={true}
                                                                                                        checked={promotionPriceSetting[data.id].isCloned}
                                                                                                        ref={register} />
                                                                                                    <label htmlFor={"isPromotionCloned_" + data.id}></label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>}
                                                                                    </div>

                                                                                    {promotionPriceSetting[data.id].isAvailable &&
                                                                                        (data.id == "1" || !promotionPriceSetting[data.id].isCloned || (_readPermission && !_writePermission)) &&
                                                                                        <div>
                                                                                            <div className="row m-b-20">
                                                                                                <div className="col-lg-12">
                                                                                                    <div className="form-group">
                                                                                                        <label><b>{t("PROMOTION_DESCRIPTION")}</b></label>
                                                                                                        <input type="text" name={"promotionDescription_" + data.id}
                                                                                                            defaultValue={promotionPriceSetting[data.id].description}
                                                                                                            ref={register({ required: true })}
                                                                                                            className="form-control m-b-5" />
                                                                                                        {errors["promotionDescription_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionDescription_" + data.id]))}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row m-b-20">
                                                                                                <div className="col-lg-3">
                                                                                                    <div className="form-group">
                                                                                                        <label><b>{t("START_DATE")}</b></label>
                                                                                                        <DateTime
                                                                                                            value={promotionPriceSetting[data.id].startDate}
                                                                                                            dateFormat="YYYY-MM-DD"
                                                                                                            closeOnSelect={true}
                                                                                                            timeFormat={false}
                                                                                                            inputProps={{
                                                                                                                readOnly: true,
                                                                                                                className: 'form-control',
                                                                                                                style: { backgroundColor: '#fff', cursor: 'pointer' }
                                                                                                            }}
                                                                                                            isValidDate={(e) => {
                                                                                                                return e.isBefore(moment(promotionPriceSetting[data.id].endDate).add(1, "day").format("YYYY-MM-DD"));
                                                                                                            }}
                                                                                                            onChange={(e) => {
                                                                                                                if (e instanceof moment) {
                                                                                                                    promotionPriceSetting[data.id].startDate = e.format("YYYY-MM-DD");
                                                                                                                    setPriceSetting(JSON.parse(JSON.stringify(priceSetting)));
                                                                                                                }
                                                                                                                else {
                                                                                                                    setPromotionStartDate("")
                                                                                                                }
                                                                                                            }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-3">
                                                                                                    <div className="form-group">
                                                                                                        <label><b>{t("END_DATE")}</b></label>
                                                                                                        <DateTime
                                                                                                            value={promotionPriceSetting[data.id].endDate}
                                                                                                            dateFormat="YYYY-MM-DD"
                                                                                                            closeOnSelect={true}
                                                                                                            timeFormat={false}
                                                                                                            inputProps={{
                                                                                                                readOnly: true,
                                                                                                                className: 'form-control',
                                                                                                                style: { backgroundColor: '#fff', cursor: 'pointer' }
                                                                                                            }}
                                                                                                            onChange={(e) => {
                                                                                                                if (e instanceof moment) {
                                                                                                                    promotionPriceSetting[data.id].endDate = e.format("YYYY-MM-DD");
                                                                                                                    setPriceSetting(JSON.parse(JSON.stringify(priceSetting)));
                                                                                                                }
                                                                                                                else {
                                                                                                                    setPromotionEndDate("")
                                                                                                                }
                                                                                                            }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row m-b-20">
                                                                                                <div className="col-lg-3">
                                                                                                    <div className="form-group">
                                                                                                        <label><b>{t("PRICE")}</b></label>
                                                                                                        <input type="number" name={"promotionPrice_" + data.id}
                                                                                                            ref={register({
                                                                                                                required: true,
                                                                                                            })}
                                                                                                            defaultValue={promotionPriceSetting[data.id].price}
                                                                                                            className="form-control m-b-5" />
                                                                                                        {errors["promotionPrice_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionPrice_" + data.id]))}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-3">
                                                                                                    <div className="form-group">
                                                                                                        <label><b>{t("SELLING_PRICE")}</b></label>
                                                                                                        <input type="number" name={"promotionSellingPrice_" + data.id}
                                                                                                            ref={register({
                                                                                                                required: true,
                                                                                                            })}
                                                                                                            defaultValue={promotionPriceSetting[data.id].sellingPrice}
                                                                                                            className="form-control m-b-5" />
                                                                                                        {errors["promotionSellingPrice_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionSellingPrice_" + data.id]))}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-lg-6">
                                                                                                    <div className="form-group">
                                                                                                        <label><b>{t("REMARK")}</b></label>
                                                                                                        <input type="text" name={"promotionBasicRemark_" + data.id}
                                                                                                            ref={register}
                                                                                                            defaultValue={promotionPriceSetting[data.id].remark}
                                                                                                            className="form-control m-b-5" />
                                                                                                        {errors["promotionBasicRemark_" + data.id] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionBasicRemark_" + data.id]))}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <hr />
                                                                                            {_writePermission && <div className="row m-b-15">
                                                                                                <div className="col-md-4">
                                                                                                    <button type="button" className="btn btn-primary" onClick={() => { addSetting(data.id, true) }}>{t('ADD_NEW_PRICE')}</button>
                                                                                                </div>
                                                                                            </div>}
                                                                                            {!isObjectEmpty(promotionPriceSetting) && promotionPriceSetting[data.id].tierPrice.length != 0 &&
                                                                                                <div className="row m-b-20">
                                                                                                    <div className="col-lg-12">
                                                                                                        <div className="form-group">
                                                                                                            <table style={{ width: "100%" }} className="table table-bordered table-sm">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("QUANTITY")}</th>
                                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("PRICE")}</th>
                                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("SELLING_PRICE")}</th>
                                                                                                                        <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("REMARK")}</th>
                                                                                                                        {_writePermission && <th style={{ borderBottom: "1px solid #ddd" }}>{t("ACTION")}</th>}
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        promotionPriceSetting[data.id].tierPrice.map((priceData, key) => {
                                                                                                                            return (
                                                                                                                                <tr key={key}>
                                                                                                                                    <td>
                                                                                                                                        <input className="form-control" type="number" name={"promotionQuantity_" + data.id + "[" + key + "]"}
                                                                                                                                            ref={register({
                                                                                                                                                required: "PLEASE_ENTER_VALID_NUMBER",
                                                                                                                                                min: {
                                                                                                                                                    value: 2,
                                                                                                                                                    message: t("PLEASE_ENTER_VALUE_GREATER_THAN", { number: 2 })
                                                                                                                                                }
                                                                                                                                            })}
                                                                                                                                            defaultValue={priceData.quantity} />
                                                                                                                                        {errors["promotionQuantity_" + data.id] && errors["promotionQuantity_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionQuantity_" + data.id][key]))}</div>}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        <input className="form-control" type="number" name={"promotionTierPrice_" + data.id + "[" + key + "]"} step="0.01"
                                                                                                                                            ref={register({
                                                                                                                                                required: "PLEASE_ENTER_VALID_NUMBER",
                                                                                                                                                min: {
                                                                                                                                                    value: 1,
                                                                                                                                                    message: t("PLEASE_ENTER_VALUE_GREATER_THAN", { number: 1 })
                                                                                                                                                }
                                                                                                                                            })}
                                                                                                                                            defaultValue={priceData.price} />
                                                                                                                                        {errors["promotionTierPrice_" + data.id] && errors["promotionTierPrice_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionTierPrice_" + data.id][key]))}</div>}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        <input className="form-control" type="number" name={"promotionTierSellingPrice_" + data.id + "[" + key + "]"} step="0.01"
                                                                                                                                            ref={register({
                                                                                                                                                required: "PLEASE_ENTER_VALID_NUMBER",
                                                                                                                                                min: {
                                                                                                                                                    value: 1,
                                                                                                                                                    message: t("PLEASE_ENTER_VALUE_GREATER_THAN", { number: 1 })
                                                                                                                                                }
                                                                                                                                            })}
                                                                                                                                            defaultValue={priceData.sellingPrice} />
                                                                                                                                        {errors["promotionTierSellingPrice_" + data.id] && errors["promotionTierSellingPrice_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionTierSellingPrice_" + data.id][key]))}</div>}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        <input className="form-control" type="text" name={"promotionRemark_" + data.id + "[" + key + "]"}
                                                                                                                                            ref={register}
                                                                                                                                            defaultValue={priceData.remark} />
                                                                                                                                        {errors["promotionRemark_" + data.id] && errors["promotionRemark_" + data.id][key] && <div className="invalid-feedback">{t(getValidationMessage(errors["promotionRemark_" + data.id][key]))}</div>}
                                                                                                                                    </td>
                                                                                                                                    {_writePermission && <td style={{ borderBottom: "1px solid #ddd", padding: "10px", textAlign: "center" }}>
                                                                                                                                        <i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => {
                                                                                                                                            var newSettingArr = [];
                                                                                                                                            promotionPriceSetting[data.id].sellingPrice = getValues()["promotionSellingPrice_" + data.id];
                                                                                                                                            promotionPriceSetting[data.id].price = getValues()["promotionPrice_" + data.id];
                                                                                                                                            promotionPriceSetting[data.id].description = getValues()["promotionDescription_" + data.id];
                                                                                                                                            promotionPriceSetting[data.id].tierPrice.map((ea, index) => {
                                                                                                                                                if (key != index) {
                                                                                                                                                    newSettingArr.push({
                                                                                                                                                        quantity: getValues()["promotionQuantity_" + data.id + "[" + index + "]"],
                                                                                                                                                        price: getValues()["promotionTierPrice_" + data.id + "[" + index + "]"],
                                                                                                                                                        sellingPrice: getValues()["promotionTierSellingPrice_" + data.id + "[" + index + "]"],
                                                                                                                                                        remark: getValues()["promotionRemark_" + data.id + "[" + index + "]"]
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                            setPromotionPriceSetting((prev) => ({ ...prev, [data.id]: { ...prev[data.id], tierPrice: newSettingArr } }));

                                                                                                                                            newSettingArr.map((ea, index) => {
                                                                                                                                                reset({
                                                                                                                                                    ["promotionQuantity_" + data.id + "[" + index + "]"]: ea.quantity,
                                                                                                                                                    ["promotionTierPrice_" + data.id + "[" + index + "]"]: ea.price,
                                                                                                                                                    ["promotionTierSellingPrice_" + data.id + "[" + index + "]"]: ea.sellingPrice,
                                                                                                                                                    ["promotionRemark_" + data.id + "[" + index + "]"]: ea.remark
                                                                                                                                                });
                                                                                                                                            });
                                                                                                                                        }}></i>
                                                                                                                                    </td>}
                                                                                                                                </tr>
                                                                                                                            );
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </TabPane>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <TabPane tabId={data.id}>
                                                                                    <div class="note note-warning note-with-end-icon">
                                                                                        <div class="note-icon" style={{ padding:"15px" }}><i class="fa fa-lightbulb"></i></div>
                                                                                        <div class="note-content text-end">
                                                                                            { t("UNAVAILABLE_DUE_TO_NO_PRICE_ERROR")}
                                                                                        </div>
                                                                                    </div>
                                                                                </TabPane>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </TabContent>
                                                        </Panel>

                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>}
                                    </TabContent>
                                </PanelBody>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;