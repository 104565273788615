import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, AlertTypes } from "../../util/Constant";
import { stringIsNullOrEmpty, getValidationMessage, arrayGroupByKey } from "../../util/Util";
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const TaskHistoryReport = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedTask, setSelectedTask] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [taskOption, setTaskOption] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const [selectedData, setSelectedData] = useState();
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});
    var _dispatch = useDispatch();
    const [isWorkLogModalVisible, setIsWorkLogModalVisible] = useState(false);

    let _tableColumns = [
        {
            Header: "TASK",
            accessor: "taskName",
            disableSortBy: true
        },
        {
            Header: "EMPLOYEE",
            accessor: "worker",
            disableSortBy: true
        },
        {
            Header: "START_TIME",
            accessor: "startTime",
            disableSortBy: true
        },
        {
            Header: "END_TIME",
            accessor: "endTime",
            disableSortBy: true
        },
        {
            Header: "TOTAL_WORKING_TIME",
            accessor: "totalWorkingTime",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            Cell: ({ row }) => {
                return <span>{row.original.isOverDue == false ? <span className="badge badge-secondary badge-green">{t("IN_TIME")}</span> : <span className="badge badge-secondary badge-danger">{t("OVERDUE")}</span>}</span>
            },
            minWidth: 100
        },
        {
            Header: t("ACTION"),
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                setSelectedData(row.original);
                                setIsWorkLogModalVisible(!isWorkLogModalVisible);
                            }}><span>{t("VIEW_DETAIL")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'user' });
        register({ name: 'task' });
        register({ name: 'endDate' }, { required: true });

        setValue('user', []);
        setValue('task', []);
        setValue('startDate', moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ASSIGNED_TASK);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var taskList = [];
                responseJson[ApiKey._API_DATA_KEY].map(task => {
                    taskList.push({ label: task.name, value: task.id });
                });
                setTaskOption(taskList);
            }

            responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?includeSelf=true&role=" + Role._ROLE_WITH_TASK);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var userListByRole = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "roleName");
                var grouppedUserList = [];

                for (var key in userListByRole) {
                    var userList = [];

                    userListByRole[key].map(user => {
                        userList.push({ label: user.userFullName, value: user.id });
                    });

                    grouppedUserList.push({
                        label: key,
                        value: key,
                        options: userList
                    })
                }

                setUserOption(grouppedUserList);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function preview(data) {
        window.open(data);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        console.log(data);
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "User": data.user,
            "TaskId": stringIsNullOrEmpty(data.task) ? -1: parseInt(data.task),
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_TASK_WORK_HISTORY_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} fileName={t("TASK_HISTORY_REPORT")} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("TASK_HISTORY_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TASK")}</label>
                                    <Select name="task" options={taskOption} isClearable={true}
                                        placeholder={(taskOption.filter(option => option.value == selectedTask)[0] !== undefined) ? (taskOption.filter(option => option.value == selectedTask)[0].label) : ""}
                                        value={taskOption.filter(option => option.value == selectedTask)}
                                        onChange={(e) => {
                                            if (e) {
                                                setValue('task', e.value);
                                                setSelectedTask(e.value);
                                            }
                                            else {
                                                setValue('task', '');
                                                setSelectedTask('');
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("USER")}</label>
                                    <DualListBox
                                        canFilter
                                        name="user"
                                        options={userOption}
                                        selected={selectedUser}
                                        onChange={(e) => {
                                            setValue('user', e);
                                            setSelectedUser(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Modal isOpen={isWorkLogModalVisible} fade={true}>
                <ModalHeader toggle={() => { setIsWorkLogModalVisible(!isWorkLogModalVisible) }}>
                    {t("DETAIL")}
                </ModalHeader>
                <ModalBody>
                    <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label"><b>{t("START_REMARK")}</b></label>
                        <div className="col-md-8">
                            <textarea type="text"
                                ref={register}
                                readOnly={ true}
                                name="remark"
                                defaultValue={selectedData?.remark}
                                autoComplete="off"
                                className="form-control" />
                        </div>
                    </div>
                    {!stringIsNullOrEmpty(selectedData?.attachmentFileName) && <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label"><b>{t("START_ATTACHMENT")}</b></label>
                        <div className="col-md-8">
                            <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", left: "0%", top: "0%", margin: "5px 5px 5px 15px" }}
                                onClick={() => {
                                    preview(selectedData.attachmentFile);
                                }}><i className="fa fa-eye"></i></a>
                            <Dropzone accept={'.png,.jpg,.jpeg,.pdf'} disabled={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div className="dropzone" style={{ textAlign: "center" }} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <b style={{ color: "grey", margin: "10px" }}>{stringIsNullOrEmpty(selectedData.attachmentFileName) ? t("DROPZONE_MESSAGE") : selectedData.attachmentFileName}</b>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </div>}

                    {!stringIsNullOrEmpty(selectedData?.endRemark) && <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label"><b>{t("END_REMARK")}</b></label>
                        <div className="col-md-8">
                            <textarea type="text"
                                ref={register}
                                readOnly={true}
                                name="remark"
                                defaultValue={selectedData.endRemark}
                                autoComplete="off"
                                className="form-control" />
                        </div>
                    </div>}
                    {!stringIsNullOrEmpty(selectedData?.endAttachmentFileName) && <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label"><b>{t("END_ATTACHMENT")}</b></label>
                        <div className="col-md-8">
                            <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", left: "0%", top: "0%", margin: "5px 5px 5px 15px" }}
                                onClick={() => {
                                    preview(selectedData.endAttachmentFile);
                                }}><i className="fa fa-eye"></i></a>
                            <Dropzone accept={'.png,.jpg,.jpeg,.pdf'} disabled={true}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div className="dropzone" style={{ textAlign: "center" }} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <b style={{ color: "grey", margin: "10px" }}>{stringIsNullOrEmpty(selectedData.endAttachmentFileName) ? t("DROPZONE_MESSAGE") : selectedData.endAttachmentFileName}</b>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </div>}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={() => { setIsWorkLogModalVisible(!isWorkLogModalVisible); }}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default TaskHistoryReport;