import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, getValidationMessage } from "../../util/Util";
import { ApiKey, ConfigurationType, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageConfiguration = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const _accessRight = props.configurationType == DataName._PRODUCT_CATEGORY ? AccessRight._PRODUCT_CONTROL_PERMISSION : AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION;
    const { register, handleSubmit, errors } = useForm();
    const [configurationReference, setConfigurationReference] = useState(null);
    var _dispatch = useDispatch();
    const [isConfigurationModalVisible, setIsConfigurationModalVisible] = useState(false);
    const [configuration, setConfiguration] = useState({});
    const [configurationData, setConfigurationData] = useState([]);
    const _language = useSelector(state => state.appState.language);

    let _tableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                return (<div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { loadConfigurationData(row.original) }}><span>{t(checkIfPermissionExist(_accessRight, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
                            {checkIfPermissionExist(_accessRight, true) && <DropdownItem onClick={() => deleteConfiguration(row.original.id)}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>);
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 100
        },
        {
            Header: t("DESCRIPTION"),
            accessor: "description",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 200
        }
    ], [_language, configurationReference]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        var referenceData = {};
        var configuration = props.configurationType;
        if (configuration == DataName._PRODUCT_CATEGORY) {
            referenceData = {
                "table": DataName._PRODUCT_CATEGORY,
                "key": "PRODUCT_CATEGORY"
            }
        }
        else if (configuration == DataName._SERVICE_CHECK_LIST) {
            referenceData = {
                "table": DataName._SERVICE_CHECK_LIST,
                "key": "SERVICE_CHECK_LIST"
            }
        }
        else if (configuration == DataName._ATTACHMENT_TYPE) {
            referenceData = {
                "table": DataName._ATTACHMENT_TYPE,
                "key": "ATTACHMENT_TYPE"
            }
        }

        setConfigurationReference(referenceData);
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (configurationReference != null) {
            getConfiguration();
        }
    }, [configurationReference]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getConfiguration() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + configurationReference["table"]);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setConfigurationData(responseJson[ApiKey._API_DATA_KEY].filter(i => i.isInternal == undefined || !i.isInternal));
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function loadConfigurationData(data) {
        setConfiguration(data);
        setIsConfigurationModalVisible(!isConfigurationModalVisible);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function deleteConfiguration(configurationId) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            content: t("DELETE_SETTING_CONFIRMATION"),
            onConfirm: () => performDeleteConfiguration(configurationId),
            showCancel: true
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function performDeleteConfiguration(configurationId) {
        let params = {
            "status": false,
            "id": configurationId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + configurationReference["table"], createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getConfiguration();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let formCollection = {
            description: data.description,
            name: data.name,
            status: true,
        };

        if (configuration.id) {
            formCollection["id"] = configuration.id;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + configurationReference["table"], createMultiPartFormBody(formCollection));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getConfiguration();
            setIsConfigurationModalVisible(!isConfigurationModalVisible)
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    if (configurationReference != null) {
        return (
            <div>
                <div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                    <h1 className="page-header">{t(configurationReference["key"])}</h1>
                    {
                        checkIfPermissionExist(_accessRight, true) &&
                        <button type="button" onClick={() => { loadConfigurationData({}); }} className="btn btn-primary">{t('ADD_' + configurationReference["key"])}</button>
                    }
                </div>
                <Panel>
                    <PanelBody>
                        <ReactTable data={configurationData} columns={_tableColumns} filterable={true} />
                    </PanelBody>
                </Panel>
                <div className="row">
                    <Modal isOpen={isConfigurationModalVisible}>
                        <form onSubmit={handleSubmit(submitForm)}>
                            <ModalHeader toggle={() => { setIsConfigurationModalVisible(!isConfigurationModalVisible) }}>
                                {t(configurationReference["key"] + "_INFORMATION")}
                            </ModalHeader>
                            <ModalBody>
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t(configurationReference["key"] + "_NAME")}</b></label>
                                    <div className="col-md-8">
                                        <input type="text"
                                            ref={register({ required: true })}
                                            defaultValue={configuration.name}
                                            name="name"
                                            autoComplete="off"
                                            className="form-control" />
                                        {errors.name && <div className="invalid-feedback">{t(getValidationMessage(errors.name))}</div>}
                                    </div>
                                </div>
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("DESCRIPTION")}</b></label>
                                    <div className="col-md-8">
                                        <textarea
                                            className="form-control"
                                            autoComplete="off"
                                            rows="2"
                                            name="description"
                                            ref={register({ required: true })}
                                            defaultValue={configuration.description} />
                                        {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {
                                    checkIfPermissionExist(_accessRight, true) &&
                                    <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                                }

                                <button type="button" className="btn btn-danger" onClick={() => { setIsConfigurationModalVisible(!isConfigurationModalVisible); }}>{t('BTN_CANCEL')}</button>
                            </ModalFooter>
                        </form>
                    </Modal>
                </div>
            </div>
        );
    }
    else {
        return (null);
    }
}

export default ManageConfiguration;