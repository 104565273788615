import React from 'react';
import { Route } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';
import SidebarNavList from './sidebar-nav-list.jsx';
import Menus from './menu.jsx';
import { connect } from "react-redux";
import { AccessRight, Status } from "../../util/Constant";

class SidebarNav extends React.Component {
	static contextType = PageSettings;
	
	constructor(props) {
		super(props);
		this.state = {
			active: -1,
			clicked: -1
		};
	}

	handleExpand(e, i, match) {
		e.preventDefault();

		if (this.state.clicked === -1 && match) {
			this.setState(state => ({
				active: -1,
				clicked: 1
			}));
		} else {
			this.setState(state => ({
				active: (this.state.active === i ? -1 : i),
				clicked: 1
			}));
		}
	}

    filterMenuByPermission = () => {
        var menuToUse = JSON.parse(JSON.stringify(Menus));

        if (this.props.authState.userData.accessString !== undefined) {
            var userPermissionData = this.props.authState.userData.accessString.split(";");
            var permissionArrKey = [];
            menuToUse = JSON.parse(JSON.stringify(Menus));

            for (var i = 0; i < userPermissionData.length; i++) {
                var keyValuePermission = userPermissionData[i].split(":");
                if (keyValuePermission.length == 2) {
                    if (keyValuePermission[1][AccessRight._READ_BIT_POSITION] == Status._ENABLED.toString()) {
                        permissionArrKey.push(keyValuePermission[0] + AccessRight._READ_PERMISSION_STRING);
                    }
                }
            }
            //first filter away non use children
            for (var i = 0; i < menuToUse.length; i++) {
                if (menuToUse[i].children) {
                    menuToUse[i].children = menuToUse[i].children.filter(item => {
                        let permission = item['permission'];
                        if (permission !== undefined) {
                            return permissionArrKey.filter(item => item == permission).length != 0;
                        }
                        else {
                            return true;
                        }
                    });
                }
            }

            //secondly filter away parent
            menuToUse = menuToUse.filter(item => {
                if (!item.children || item.children.length == 0) {
                    if (item.isDummy) {
                        return false;
                    }
                    else {
                        let permission = item.permission;
                        if (permission !== undefined) {
                            return permissionArrKey.filter(item => item == permission).length != 0;
                        }
                        else {
                            return true;
                        }
                    }
                }
                else {
                    return true;
                }
            });
        }

        return menuToUse;
    }

    render() {
        var menuList = this.filterMenuByPermission();
        return (
            <ul className="nav">
                {menuList.map((menu, i) => (
                    <Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
                        <SidebarNavList
                            data={menu}
                            key={i}
                            expand={(e) => this.handleExpand(e, i, match)}
                            active={i === this.state.active}
                            clicked={this.state.clicked}
                        />
                    )} />
                ))}
            </ul>
        );
	}
}


const mapStateToProps = state => ({
	...state
});

export default connect(
	mapStateToProps,
	null
)(SidebarNav);