import React, { useState, useEffect, useRef } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { navigateTo, createMultiPartFormBody, getValidationMessage, isObjectEmpty, stringIsNullOrEmpty } from "../../util/Util";
import { ApiKey, WebUrl, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';
import NavigationButton from '../../components/custom/NavigationButton';
import { useLocation } from "react-router-dom";
import DateTime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageTask = props => {
    const { t } = useTranslation();
    const _location = useLocation();
    let _history = useHistory();
    const { register, handleSubmit, errors, watch } = useForm();
    var _dispatch = useDispatch();
    const [configuration, setConfiguration] = useState({});
    const [selectedPriority, setSelectedPriority] = useState({});
    const [taskPriorityOption, setTaskPriorityOption] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const _userData = useSelector(state => state.authState.userData);
    const [deadline, setDeadline] = useState("");
    const [supportingDocument, setSupportingDocument] = useState();
    const [supportingDocumentFile, setSupportingDocumentFile] = useState("");
    const [supportingDocumentFileName, setSupportingDocumentFileName] = useState("");
    const [isNewDocument, setIsNewDocument] = useState(false);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        initData();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function initData() {
        await initTask();
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._TASK_PRIORITY);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTaskPriorityOption(responseJson[ApiKey._API_DATA_KEY]);

            if (!_location.state) {
                setSelectedPriority(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
            }
        }

        responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS +"?includeSelf=true");
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            let userList = [];

            responseJson[ApiKey._API_DATA_KEY].forEach(function (user) {
                userList.push({ label: user.userFullName, value: user.id });
            });

            setUserOption(userList);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function initTask() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._TASK + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setConfiguration(responseJson[ApiKey._API_DATA_KEY]);
                setSelectedPriority(responseJson[ApiKey._API_DATA_KEY]["priorityId"]);
                setDeadline(responseJson[ApiKey._API_DATA_KEY]["deadline"]);
                setSelectedUser(responseJson[ApiKey._API_DATA_KEY]["assignedUser"]);
                setSupportingDocumentFileName(responseJson[ApiKey._API_DATA_KEY].attachmentFile);
                setSupportingDocumentFile(responseJson[ApiKey._API_DATA_KEY].attachment);
            }
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let formCollection = {
            priorityId: selectedPriority,
            description: data.description,
            remark: data.remark,
            name: data.name,
            status: true,
            deadline: stringIsNullOrEmpty(deadline) ? "" : deadline + ":00",
            isNewDocument: isNewDocument
        };

        if (configuration.id) {
            formCollection["id"] = configuration.id;

            if (configuration.taskStatus) {
                formCollection["completionRemark"] = data.completionRemark;
            }
        }

        if (isNewDocument && !stringIsNullOrEmpty(supportingDocumentFile)) {
            formCollection["document"] = supportingDocumentFile;
        }

        if (stringIsNullOrEmpty(supportingDocumentFile)) {
            formCollection["documentRemoved"] = true;
        }

        formCollection["assignedUser"] = selectedUser.join(",");

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_TASK, createMultiPartFormBody(formCollection));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_TASK)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function completeTask() {
        if (configuration.totalWorkRecord === 0) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t("NO_WORK_HISTORY_FOUND_ERROR")
            }));

            return;
        }

        let formCollection = {
            completeRemark: watch("completeRemark"),
            taskStatus: true,
            id: _location.state["id"]
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_TASK, createMultiPartFormBody(formCollection));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            initTask();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_TASK)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setSupportingDocumentFile(file);
            setSupportingDocumentFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewDocument(true);
                setSupportingDocument(reader.result);
                setIsNewDocument(true);
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={checkIfPermissionExist(AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION, true) && !configuration.taskStatus &&
                            (isObjectEmpty(configuration) || (_userData.userId == configuration.createdById || _userData.isAdmin)) ? () => { handleSubmit(submitForm)() } : undefined}>
                            <span>{!isObjectEmpty(configuration) ? t("EDIT_TASK") : t("ADD_TASK")}</span>
                            {!isObjectEmpty(configuration) && !configuration.taskStatus && (_userData.userId == configuration.createdById || _userData.isAdmin) && <div style={{ float: "right", marginRight: "15px" }}>
                                <button class="btn btn-success" onClick={() => { completeTask(); }}>{t("COMPLETE")}</button>
                            </div>}
                            {configuration.taskStatus && <div style={{ float: "right", marginRight: "15px" }}>
                                <span className="badge badge-secondary badge-green">{t("COMPLETE")}</span>
                            </div>}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label><b>{t("TASK_NAME")}</b></label>
                                            <input type="text"
                                                ref={register({ required: true })}
                                                defaultValue={configuration.name}
                                                name="name"
                                                autoComplete="off"
                                                className="form-control" />
                                            {errors.name && <div className="invalid-feedback">{t(getValidationMessage(errors.name))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("URGENCY")}</b></label>
                                            <select name="taskPriority" ref={register} className="form-control" value={selectedPriority} onChange={(e) => setSelectedPriority(e.target.value)}>
                                                {
                                                    taskPriorityOption.map((data) => {
                                                        return <option value={data.id}>{data.name}</option>
                                                    })
                                                }
                                            </select>
                                            {errors.taskPriority && <div className="invalid-feedback">{t(getValidationMessage(errors.taskPriority))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="form-group">
                                            <label><b>{t("DEADLINE")}</b></label>
                                            <div className="input-group">
                                                <DateTime
                                                    value={deadline}
                                                    dateFormat="YYYY-MM-DD"
                                                    closeOnSelect={true}
                                                    timeFormat={"HH:mm"}
                                                    isValidDate={(e) => {
                                                        return e.isAfter(moment());
                                                    }}
                                                    inputProps={{
                                                        readOnly: true,
                                                        className: 'form-control',
                                                        style: { backgroundColor: 'transparent', cursor: 'pointer' }
                                                    }}
                                                    onChange={(e) => {
                                                        if (e instanceof moment) {
                                                            setDeadline(e.format("YYYY-MM-DD HH:mm"));
                                                        }
                                                        else {
                                                            setDeadline("");
                                                        }
                                                    }} />
                                                <div className="input-group-append"><span className="input-group-text" onClick={() => setDeadline("")}><i class="fa fa-times"></i></span></div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group">
                                            <label><b>{t("ATTACHMENT")}</b></label>
                                            {
                                                !stringIsNullOrEmpty(supportingDocumentFile) &&
                                                <a href="javascript:;" className="btn btn-info btn-icon btn-circle btn-sm" style={{ position: "absolute", left: "4%", top: "35%", margin: "5px 5px 5px 15px" }}
                                                    onClick={() => {
                                                        if (supportingDocumentFile.path) {
                                                            var w = window.open("");

                                                            w.document.write(
                                                                "<iframe width='100%' height='100%' src='" +
                                                                encodeURI(supportingDocument) + "'></iframe>"
                                                            )
                                                        }
                                                        else {
                                                            window.open(supportingDocumentFile);
                                                        }
                                                    }}><i className="fa fa-eye"></i></a>
                                            }
                                            <a href="javascript:;" className="btn btn-danger btn-icon btn-circle btn-sm" style={{ position: "absolute", left: "0%", top: "35%", margin: "5px 5px 5px 15px" }}
                                                onClick={() => {
                                                    setSupportingDocumentFile("");
                                                    setSupportingDocumentFileName("");
                                                }}><i className="fa fa-times"></i></a>
                                            <Dropzone accept={'.png,.jpg,.jpeg,.pdf,.pptx,.doc,.docx,.xls,.xlsx'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div className="dropzone" style={{ textAlign: "center" }} {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <b style={{ color: "grey", margin: "10px" }}>{stringIsNullOrEmpty(supportingDocumentFileName) ? t("DROPZONE_MESSAGE") : supportingDocumentFileName}</b>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label><b>{t("TASK_REMARK")}</b></label>
                                            <textarea
                                                className="form-control"
                                                autoComplete="off"
                                                rows="2"
                                                name="remark"
                                                ref={register}
                                                defaultValue={configuration.remark} />
                                        </div>
                                    </div>
                                </div>
                                {_location.state && <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label><b>{t("COMPLETION_REMARK")}</b></label>
                                            <textarea
                                                className="form-control"
                                                autoComplete="off"
                                                rows="2"
                                                name="completeRemark"
                                                ref={register}
                                                defaultValue={configuration.completeRemark} />
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label><b>{t("DESCRIPTION")}</b></label>
                                            <textarea
                                                className="form-control"
                                                autoComplete="off"
                                                rows="2"
                                                name="description"
                                                ref={register({ required: true })}
                                                defaultValue={configuration.description} />
                                            {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label><b>{t("ASSIGNMENT")}</b></label>
                                            <DualListBox
                                                canFilter
                                                selected={selectedUser}
                                                options={userOption}
                                                onChange={(e) => { setSelectedUser(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
}

export default ManageTask;