import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { navigateTo, dataTableFilterCaseInsensitive } from "../../util/Util";
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, Status, AccessRight, LanguageKey, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from "react-redux";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageRole = props => {
	const { t } = useTranslation();
    let _history = useHistory();
	const [roleData, setRoleData] = useState([]);
	const _language = useSelector(state => state.appState.language);

	let _tableColumns = useMemo(() => [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
						<DropdownMenu>
							<DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_ROLE_DETAIL, row.original) }}><span className="text-warning">{t(checkIfPermissionExist(AccessRight._ACCESS_LEVEL_CONTROL_PERMISSION, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" }
		},
		{
			Header: t("NAME"),
			accessor: "roleName"
		},
		{
			Header: t("DESCRIPTION"),
			accessor: "description"
		},
		{
			Header: t("STATUS"),
			accessor: "status",
			Cell: ({ row }) => {
				return <span>{row.original.status == 1 ? <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span className="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>}</span>
			},
			minWidth: 100
		},
	], [_language]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLE);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRoleData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

	return (
        <div>
			<div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("ROLES")}</h1>
                {
                    checkIfPermissionExist(AccessRight._ACCESS_LEVEL_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_ROLE_DETAIL} buttonText={t("ADD_NEW_ROLE")} />
                }
            </div>
			<Panel>
				<PanelBody>
					<ReactTable data={roleData} columns={_tableColumns} filterable={true} />
				</PanelBody>
			</Panel>
		</div>
	)
}

export default ManageRole;