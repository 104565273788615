import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../util/Util";
import { ApiKey, ApiUrl, AlertTypes, Role, WebUrl, DataName, AccessRight } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from '../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import DateTime from 'react-datetime';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const SystemMessageDetail = props => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, unregister } = useForm();
    const [announcementId, setAnnouncementId] = useState('');

    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState("");
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [actionUrl, setActionUrl] = useState("");
    const [isNewImage, setIsNewImage] = useState(false);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    var _permission = checkIfPermissionExist(AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION, true);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'description' }, { required: "INPUT_FIELD_REQUIRED" });
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._SYSTEM_MESSAGE + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setStartDate(responseJson[ApiKey._API_DATA_KEY]["startDate"]);
                setEndDate(responseJson[ApiKey._API_DATA_KEY]["endDate"]);
                setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
                setImage(responseJson[ApiKey._API_DATA_KEY]["image"]);
                setActionUrl(responseJson[ApiKey._API_DATA_KEY]["actionUrl"]);
                setAnnouncementId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            }
        }
        else {
            setValue('description', '');
        }
    }


    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            "isNewImage": isNewImage,
            "startDate": startDate,
            "endDate": endDate,
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        if (!stringIsNullOrEmpty(announcementId)) {
            params["id"] = announcementId;
        }
        else {
            params["status"] = true;
        }
        
        if (isNewImage && !stringIsNullOrEmpty(imageFile)) {
            params["image"] = imageFile;
        }

        if (stringIsNullOrEmpty(image)) {
            params["image"] = "";
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_ANNOUNCEMENT, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_SYSTEM_MESSAGE)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    if (_permission) {
        return (
            <div>
                <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
                <div className="row">
                    <div className="col-xl-12">
                        <Panel>
                            <PanelHeader onClick={_permission ? () => { handleSubmit(submitForm)() } : undefined}>
                                {title ? t("EDIT_SYSTEM_MESSAGE") : t("ADD_SYSTEM_MESSAGE")}
                            </PanelHeader>
                            <PanelBody>
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-4 col-form-label"><b>{t("TITLE")}</b></label>
                                                <div className="col-md-8">
                                                    <input type="text" className="form-control" name="title"
                                                        ref={register({ required: "REQUIRED_TITLE" })}
                                                        defaultValue={title} />
                                                    {errors.title && <div className="invalid-feedback">{t(getValidationMessage(errors.title))}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-4 col-form-label"><b>{t("ANNOUNCEMENT_DATE")}</b></label>
                                                <div className="col-md-4">
                                                    <DateTime
                                                        value={startDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        inputProps={{
                                                            readOnly: true,
                                                            className: 'form-control',
                                                            style: { backgroundColor: 'transparent', cursor: 'pointer' }
                                                        }}
                                                        isValidDate={(e) => {
                                                            return e.isBefore(moment(endDate).add(1, "day").format("YYYY-MM-DD"));
                                                        }}
                                                        onChange={(e) => {
                                                            if (e instanceof moment) {
                                                                setStartDate(e.format("YYYY-MM-DD"));
                                                            }
                                                            else {
                                                                setStartDate("")
                                                            }
                                                        }} />
                                                </div>
                                                <div className="col-md-4">
                                                    <DateTime
                                                        value={endDate}
                                                        dateFormat="YYYY-MM-DD"
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        inputProps={{
                                                            readOnly: true,
                                                            className: 'form-control',
                                                            style: { backgroundColor: 'transparent', cursor: 'pointer' }
                                                        }}
                                                        onChange={(e) => {
                                                            if (e instanceof moment) {
                                                                if (moment(e).diff(moment(startDate), 'seconds') < 0) {
                                                                    setStartDate(moment(e).format("YYYY-MM-DD"));
                                                                }
                                                                setEndDate(e.format("YYYY-MM-DD"));
                                                            }
                                                            else {
                                                                setEndDate("")
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-4 col-form-label"><b>{t("CONTENT")}</b></label>
                                                <div className="col-md-8">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={description}
                                                        name="description"
                                                        onInit={editor => {
                                                            editor.setData(description);
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            setDescription(data);
                                                            setValue('description', data);
                                                        }}
                                                    />
                                                    {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-4 col-form-label"><b>{t("ANNOUNCEMENT_IMAGE")}</b></label>
                                                <div className="col-md-8">
                                                    <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", right: "5%", top: "2%" }}
                                                        onClick={() => {
                                                            setImage("");
                                                            setImageFile("");
                                                        }}><i className="fa fa-times"></i></a>
                                                    <Dropzone accept={'.png,.jpg,.jpeg'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    {!stringIsNullOrEmpty(image) && <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                            <div className="thumbInner">
                                                                                <img
                                                                                    src={image}
                                                                                    style={{ maxHeight: "200px", maxWidth: "200px" }}
                                                                                    className="dropzone-img"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </aside>}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div className="form-group row m-b-15">
                                                <label className="col-md-4 col-form-label"><b>{t("ACTION_URL")}</b></label>
                                                <div className="col-md-8">
                                                    <input type="text" className="form-control" name="actionUrl"
                                                        ref={register}
                                                        defaultValue={actionUrl} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
                <div className="row">
                    <div className="col-xl-12">
                        <Panel>
                            <PanelHeader>
                                {title}
                            </PanelHeader>
                            <PanelBody>
                                <div className="row" style={{ marginBottom:"20px" }}>
                                    <div className="col-lg-12">
                                        {
                                            !stringIsNullOrEmpty(image) &&
                                            <div style={{ width: "100%", height: "300px" }} onClick={() => window.open(actionUrl)}>
                                                <img src={image} style={{ objectFit: "contain", width: "100%", height: "100%" }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div dangerouslySetInnerHTML={{ __html: description }} />
                                    </div>
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SystemMessageDetail);