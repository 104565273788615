import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import ReactTable from '../../components/custom/ReactTable';
import NavigationButton from '../../components/custom/NavigationButton';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage, isObjectEmpty } from "../../util/Util";
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName, Status, WebUrl, Day } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import 'react-intl-tel-input/dist/main.css';
import InputMask from 'react-input-mask';
import Dropzone from 'react-dropzone';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import Autocomplete from 'react-google-autocomplete';
import classnames from 'classnames';
import ReactTags from 'react-tag-autocomplete';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const CustomerDetail = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, unregister, reset, getValues } = useForm();
    const { register: branchFormRegister, handleSubmit: branchFormSubmit, errors: branchFormError, setValue: branchFormSetValue, unregister: branchFormUnregister, getValues: branchGetValues, reset: branchReset } = useForm();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [ownerName, setOwnerName] = useState("");
    const [officePhone, setOfficePhone] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [branchName, setBranchName] = useState('');
    const [address, setAddress] = useState("");
    const [altAddress, setAltAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postcode, setPostcode] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');
    const [remark, setRemark] = useState('');
    const [faxNumber, setFaxNumber] = useState('');
    const [code, setCode] = useState('');
    const [operatingHourDetail, setOperatingHourDetail] = useState([]);
    const [ownerDetail, setOwnerDetail] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [branchId, setBranchId] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [branchModalVisible, setBranchModalVisible] = useState(false);
    const [branchOperatingVisible, setBranchOperatingVisible] = useState(false);
    const [branchOwnerVisible, setBranchOwnerVisible] = useState(false);
    const [isNewImage, setIsNewImage] = useState(false);
    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState("");
    const _writePermission = checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, true);

    const [attachmentToRemoved, setAttachmentToRemoved] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [attachmentFile, setAttachmentFile] = useState([]);

    var _permission = checkIfPermissionExist(AccessRight._CUSTOMER_CONTROL_PERMISSION, true);
    const [selectedShopeeShop, setSelectedShopeeShop] = useState([]);
    const [selectedLazadaShop, setSelectedLazadaShop] = useState([]);

    const _SUBMIT_TYPE_DETAIL = 0;
    const _SUBMIT_TYPE_BRANCH = 1;
    const _SUBMIT_TYPE_OPERATING = 2;
    const _SUBMIT_TYPE_OWNER = 3;

    const _DETAIL_TAB = "1";
    const _BRANCH_OFFICE_TAB = "2";
    const _ATTACHMENT_TAB = "3";
    const [visibleTab, setVisibleTab] = useState(_DETAIL_TAB);

    const _DAYS = {
        SUNDAY: 0,
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6
    }

    let _DAYS_OF_WEEK = [
        {
            label: t("SUNDAY_FULL_NAME"),
            value: _DAYS.SUNDAY
        },
        {
            label: t("MONDAY_FULL_NAME"),
            value: _DAYS.MONDAY
        },
        {
            label: t("TUESDAY_FULL_NAME"),
            value: _DAYS.TUESDAY
        },
        {
            label: t("WEDNESDAY_FULL_NAME"),
            value: _DAYS.WEDNESDAY
        },
        {
            label: t("THURSDAY_FULL_NAME"),
            value: _DAYS.THURSDAY
        },
        {
            label: t("FRIDAY_FULL_NAME"),
            value: _DAYS.FRIDAY
        },
        {
            label: t("SATURDAY_FULL_NAME"),
            value: _DAYS.SATURDAY
        }
    ];

    let _branchColumn = useMemo(() => [
        {
            Header: t("ADDRESS"),
            accessor: "address"
        },
        {
            Header: t("STATE"),
            accessor: "state"
        },
        {
            Header: t("BRANCH"),
            accessor: "branchName"
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => {
                return (
                    <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    setBranchId(row.original.id);
                                    setCity(row.original.city);
                                    setPostcode(row.original.postcode);
                                    setState(row.original.state);
                                    setLatitude(row.original.latitude);
                                    setLongitude(row.original.longitude);
                                    setBranchName(row.original.branchName);
                                    setAddress(row.original.address);
                                    setAltAddress(row.original.addressAlternative);
                                    setBranchModalVisible(true);
                                }}><span className="text-warning">{t("EDIT")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    setBranchId(row.original.id);
                                    setOperatingHourDetail(row.original.operatingHourList);
                                    setBranchOperatingVisible(true);
                                }}><span className="text-warning">{t("SETUP_OPERATING_HOUR")}</span></DropdownItem>
                                <DropdownItem onClick={() => {
                                    setBranchId(row.original.id);
                                    setOwnerDetail(row.original.owner);
                                    setBranchOwnerVisible(true);
                                }}><span className="text-warning">{t("SETUP_OWNER")}</span></DropdownItem>
                                <DropdownItem onClick={() => {deleteBranch(row.original.id) }}><span className="text-warning">{t("DELETE")}</span></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                )
            },
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'birthDate' }, {
            pattern: {
                value: /^\d{4}-\d{2}-\d{2}$/,
                message: "INVALID_FORMAT"
            } });
        register({ name: 'officePhoneNumber' }, { minLength: 10, maxLength: 11 });
        register({ name: 'phoneNumber' }, { minLength: 11, maxLength: 12 });
        setValue("officePhoneNumber", "");
        setValue("phoneNumber", "");
        setValue("birthDate", "");

        register({ name: 'shopeeShop' });
        register({ name: 'lazadaShop' });
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (branchModalVisible) {
            branchFormRegister({ name: 'branchAddress' });
            branchFormSetValue("branchAddress", address);
        }
        else {
            branchFormUnregister("branchAddress");
        }
    }, [branchModalVisible]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function deleteBranch (branchId){
        var apiUrl = ApiUrl._API_DELETE_CUSTOMER_BRANCH + "?targetBranch=" + branchId;
        var responseJson = await ApiEngine.post(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            initBranch();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());

            if (_location.state) {
                var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._CUSTOMER + "&id=" + _location.state["id"];

                var responseJson = await ApiEngine.get(apiUrl);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
                    setCustomerId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                    setOfficePhone(responseJson[ApiKey._API_DATA_KEY]["officePhoneNumber"] ?? "");
                    setMobilePhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"] ?? "");
                    setEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
                    setOwnerName(responseJson[ApiKey._API_DATA_KEY]["customerOwnerName"]);
                    setAddress(responseJson[ApiKey._API_DATA_KEY]["address"]);
                    setAltAddress(responseJson[ApiKey._API_DATA_KEY]["addressAlternative"]);
                    setFaxNumber(responseJson[ApiKey._API_DATA_KEY]["faxNumber"]);
                    setCode(responseJson[ApiKey._API_DATA_KEY]["code"])

                    setImage(responseJson[ApiKey._API_DATA_KEY]["profileImage"]);
                    setBranchData(responseJson[ApiKey._API_DATA_KEY]["branch"]);
                    setOwnerDetail(responseJson[ApiKey._API_DATA_KEY]["owner"]);
                    setRemark(responseJson[ApiKey._API_DATA_KEY]["remark"]);
                    setIsActive(responseJson[ApiKey._API_DATA_KEY]["isActive"])

                    setValue("phoneNumber", responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                    setValue("officePhoneNumber", responseJson[ApiKey._API_DATA_KEY]["officePhoneNumber"]);

                    if (stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["birthDate"])) {
                        setBirthDate("");
                        setValue("birthDate", "");
                    }
                    else {
                        setBirthDate(responseJson[ApiKey._API_DATA_KEY]["birthDate"]);
                        setValue("birthDate", responseJson[ApiKey._API_DATA_KEY]["birthDate"]);
                    }

                    if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["shopeeShopName"])) {
                        var formattedShopee = [];
                        responseJson[ApiKey._API_DATA_KEY]["shopeeShopName"].split(',').forEach(function (item) {
                            formattedShopee.push({ id: item, name: item });
                        });
                        setSelectedShopeeShop(formattedShopee);
                        setValue("shopeeShop", [...formattedShopee]);
                    }

                    if (!stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["lazadaShopName"])) {
                        var formattedLazada = [];
                        responseJson[ApiKey._API_DATA_KEY]["lazadaShopName"].split(',').forEach(function (item) {
                            formattedLazada.push({ id: item, name: item });
                        });
                        setSelectedLazadaShop(formattedLazada);
                        setValue("lazadaShop", [...formattedLazada]);
                    }

                    var newAttachmentList = [];
                    responseJson[ApiKey._API_DATA_KEY]["attachments"].map(function (file) {
                        newAttachmentList.push({ id: file.id, data: file.image, name: file.fileName });
                    });

                    setAttachment([...newAttachmentList]);
                    setAttachmentFile([...newAttachmentList]);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function initBranch() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._CUSTOMER + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setBranchData(responseJson[ApiKey._API_DATA_KEY]["branch"]);
            }
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {}

        if (data.submitType == _SUBMIT_TYPE_DETAIL) {
            params = {
                phoneNumber: data.phone,
                identityNumber: data.icNumber,
                birthDate: birthDate,
                "attachmentToRemoved": attachmentToRemoved,
            };

            if (!stringIsNullOrEmpty(customerId)) {
                params["id"] = customerId;
            }
            else {
                params["status"] = true;
            }

            Object.keys(data).map((key, value) => {
                params[key] = data[key];
            });

            if (isNewImage && !stringIsNullOrEmpty(imageFile)) {
                params["image"] = imageFile;
            }

            if (stringIsNullOrEmpty(image)) {
                params["image"] = "";
            }

            if (data.lazadaShop) {
                params["lazadaShopName"] = data.lazadaShop.map((shop) => shop.name);
            }

            if (data.shopeeShop) {
                params["shopeeShopName"] = data.shopeeShop.map((shop) => shop.name);
            }

            attachmentFile.map(function (file, i) {
                params["attachment" + i] = file;
            })
        }
        else if (data.submitType == _SUBMIT_TYPE_BRANCH) {
            var branchModel = [];
            var newBranch = {};

            if (!stringIsNullOrEmpty(branchId)) {
                newBranch["id"] = branchId;
            }

            newBranch["branchName"] = data.branchName;
            newBranch["address"] = data.branchAddress;
            newBranch["postcode"] = data.postcode;
            newBranch["state"] = data.state;
            newBranch["city"] = data.city;
            newBranch["latitude"] = data.latitude;
            newBranch["longitude"] = data.longitude;
            newBranch["addressAlternative"] = data.branchAlternateAddress;

            branchModel.push(newBranch);
            params = {
                id: customerId,
                isCreateOrUpdateBranch: true,
                skipOperatingHour: true,
            }
            params["branchModel"] = JSON.stringify(branchModel);
        }
        else if (data.submitType == _SUBMIT_TYPE_OPERATING) {
            var operatingDetail = [];
            if (data.day !== undefined) {
                data.day.map((option, key) => {
                    operatingDetail.push({
                        day: option,
                        startTime: data.startTime[key],
                        endTime: data.endTime[key],
                        branchOfficeId: branchId,
                        id: key + 1
                    });
                })
            }

            if (operatingDetail.length != 0) {
                params["operatingDetail"] = JSON.stringify(operatingDetail);
            }
            params["branchId"] = branchId;
            params["updateOperatingHour"] = true;
            params["id"] = customerId;
        }
        else if (data.submitType == _SUBMIT_TYPE_OWNER) {
            var ownerDetailArr = [];
            if (data.ownerName !== undefined) {
                data.ownerName.map((option, key) => {
                    ownerDetailArr.push({
                        username: option,
                        role: data.ownerRole[key],
                        phoneNumber: data.ownerPhone[key]
                    });
                })
            }

            if (ownerDetailArr.length != 0) {
                params["owner"] = JSON.stringify(ownerDetailArr);
            }
            params["branchId"] = branchId;
            params["id"] = customerId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_CUSTOMER, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_CUSTOMER)
        }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBranchModalVisible(false);
            setBranchOperatingVisible(false);
            setBranchOwnerVisible(false);
            initBranch();
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const selectCustomerAddress = (place) => {
        if (place.geometry !== undefined) {
            let fullAddress = document.getElementById("branchAddress").value;

            setLongitude(place.geometry.location.lng());
            setLatitude(place.geometry.location.lat());

            if (!stringIsNullOrEmpty(fullAddress)) {
                branchFormSetValue('branchAddress', fullAddress);
            }
            else {
                branchFormSetValue('branchAddress', '');
            }

            setCity("");
            setPostcode("");
            setState("");

            place.address_components.map((item, value) => {
                if (item.types.filter(i => i == "locality").length != 0) {
                    setCity(item.long_name);
                    branchFormSetValue("city", item.long_name)
                }
                else if (item.types.filter(i => i == "postal_code").length != 0) {
                    setPostcode(item.long_name);
                    branchFormSetValue("postcode", item.long_name)
                }
                else if (item.types.filter(i => i == "administrative_area_level_1").length != 0) {
                    setState(item.long_name);
                    branchFormSetValue("state", item.long_name)
                }
            });

            setAddress('');
            setAddress(fullAddress);

            branchFormSetValue('latitude', place.geometry.location.lat());
            branchFormSetValue('longitude', place.geometry.location.lng());
        }
        else {
            branchFormSetValue('latitude', "");
            branchFormSetValue('longitude', "");
            setLongitude("");
            setLatitude("");
            setCity("");
            setPostcode("");
            setState("");
        }
    };

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const onInputCustomerAddress = (address) => {
        if (stringIsNullOrEmpty(address)) {
            setLatitude('');
            setLongitude('');
            branchFormSetValue('branchAddress', '');
            setAddress('');
        }
        else {
            branchFormSetValue('branchAddress', address);
            setAddress(address);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const AddOperatingHour = () => {
        var uniqueId = 1;
        if (operatingHourDetail.length != 0) {
            uniqueId = Math.max(...operatingHourDetail.map(o => o.id)) + 1
        }

        var newWorkingHour = [{
            day: 0,
            startTime: "00:00 AM",
            endTime: "11:59 PM",
            id: uniqueId
        }];

        setOperatingHourDetail([...operatingHourDetail, ...newWorkingHour]);
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const AddOwner = () => {
        var newOwner = [{
            username: "",
            role: "",
            phoneNumber: ""
        }];

        setOwnerDetail([...ownerDetail, ...newOwner]);
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    function loadStandardOperatingHour() {
        setOperatingHourDetail(JSON.parse('[{"id":1,"startTime":"09:00 AM","endTime":"05:00 PM","day":1},{"id":2,"startTime":"09:00 AM","endTime":"05:00 PM","day":2},{"id":3,"startTime":"09:00 AM","endTime":"05:00 PM","day":3},{"id":4,"startTime":"09:00 AM","endTime":"05:00 PM","day":4},{"id":5,"startTime":"09:00 AM","endTime":"05:00 PM","day":5}]'));
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    function duplicateOperatingHour() {
        var newOperatingList = [];

        for (var i = 0; i < 7; i++) {
            newOperatingList.push({
                day: i,
                startTime: branchGetValues()["startTime[0]"],
                endTime: branchGetValues()["endTime[0]"],
                id: i
            });
        }

        setOperatingHourDetail([...newOperatingList]);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onDeleteLazadaShop = function (i) {
        selectedLazadaShop.splice(i, 1);
        setSelectedLazadaShop([...selectedLazadaShop]);
        setValue("lazadaShop", selectedLazadaShop.length != 0 ? [...selectedLazadaShop] : '');
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onAddLazadaShop = function (i) {
        selectedLazadaShop.push(i);

        setSelectedLazadaShop([...selectedLazadaShop]);
        setValue("lazadaShop", [...selectedLazadaShop]);
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onDeleteShopeeShop = function (i) {
        selectedShopeeShop.splice(i, 1);
        setSelectedShopeeShop([...selectedShopeeShop]);
        setValue("shopeeShop", selectedShopeeShop.length != 0 ? [...selectedShopeeShop] : '');
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onAddShopeeShop = function (i) {
        selectedShopeeShop.push(i);

        setSelectedShopeeShop([...selectedShopeeShop]);
        setValue("shopeeShop", [...selectedShopeeShop]);
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const fileDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            attachmentFile.push(file);
            setAttachmentFile([...attachmentFile]);

            const reader = new FileReader();
            reader.onloadend = () => {
                attachment.push({ id: undefined, data: reader.result });
                setAttachment([...attachment]);
            }

            reader.readAsDataURL(file);
        }
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={_permission ? () => { handleSubmit(submitForm)() } : undefined}>
                            {customerId ? t("EDIT_CUSTOMER") : t("ADD_NEW_CUSTOMER")}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <PanelBody>
                                    {customerId && <Nav className="mb-3" pills>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: visibleTab == _DETAIL_TAB })}
                                                onClick={() => { setVisibleTab(_DETAIL_TAB); }} >
                                                <span className="d-sm-none">{t("DETAIL")}</span>
                                                <span className="d-sm-block d-none">{t("DETAIL")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: visibleTab == _BRANCH_OFFICE_TAB })}
                                                onClick={() => { setVisibleTab(_BRANCH_OFFICE_TAB); }} >
                                                <span className="d-sm-none">{t("BRANCH")}</span>
                                                <span className="d-sm-block d-none">{t("BRANCH")}</span>
                                            </NavLink>
                                        </NavItem>
                                        {(_writePermission || attachment.length != 0) &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: visibleTab == _ATTACHMENT_TAB })}
                                                onClick={() => { setVisibleTab(_ATTACHMENT_TAB); }} >
                                                    <span className="d-sm-none">{t("ATTACHMENT")}</span>
                                                    <span className="d-sm-block d-none">{t("ATTACHMENT")}</span>
                                                </NavLink>
                                            </NavItem>
                                        }
                                    </Nav>}

                                    <TabContent className="p-15 rounded bg-white mb-4" activeTab={visibleTab}>
                                        <TabPane tabId={_DETAIL_TAB}>
                                            <input type="hidden" name="submitType" value={_SUBMIT_TYPE_DETAIL} ref={register} />
                                            <div className="row m-b-20">
                                                <div className="col-lg-3">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label><b>{t("CUSTOMER_NAME")}</b></label>
                                                                <input type="text" name="username"
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    defaultValue={username}
                                                                    className="form-control m-b-5" />
                                                                {errors.username && <div className="invalid-feedback">{t(getValidationMessage(errors.username))}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label><b>{t("BIRTHDATE")}</b></label>
                                                                <DateTime
                                                                    value={birthDate}
                                                                    dateFormat="YYYY-MM-DD"
                                                                    closeOnSelect={true}
                                                                    timeFormat={false}
                                                                    isValidDate={(e) => {
                                                                        return e.isBefore(moment().format("YYYY-MM-DD HH:mm"));
                                                                    }}
                                                                    inputProps={{
                                                                        className: 'form-control',
                                                                        style: { backgroundColor: 'transparent', cursor: 'pointer' }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        if (e instanceof moment) {
                                                                            setBirthDate(e.format("YYYY-MM-DD"));
                                                                            setValue("birthDate", e.format("YYYY-MM-DD"));
                                                                        }
                                                                        else {
                                                                            setBirthDate(e);
                                                                            setValue("birthDate", e);
                                                                        }
                                                                    }} />
                                                                {errors.birthDate && <div className="invalid-feedback">{t(getValidationMessage(errors.birthDate))}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    &nbsp;
                                                </div>
                                                <div className="col-lg-4">
                                                    <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", right: "5%", top: "2%" }}
                                                        onClick={() => {
                                                            setImage("");
                                                            setImageFile("");
                                                        }}><i className="fa fa-times"></i></a>
                                                    <Dropzone accept={'.png,.jpg,.jpeg'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    {!stringIsNullOrEmpty(image) && <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                            <div className="thumbInner">
                                                                                <img
                                                                                    src={image}
                                                                                    style={{ maxHeight: "200px", width: "100%" }}
                                                                                    className="dropzone-img"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </aside>}
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("CUSTOMER_OWNER_NAME")}</b></label>
                                                        <input type="text" name="customerOwnerName"
                                                            ref={register}
                                                            defaultValue={ownerName}
                                                            className="form-control m-b-5" />
                                                        {errors.customerOwnerName && <div className="invalid-feedback">{t(getValidationMessage(errors.customerOwnerName))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("EMAIL")}</b></label>
                                                        <input type="text" name="email"
                                                            ref={register({
                                                                pattern: {
                                                                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                    message: "INVALID_EMAIL"
                                                                }
                                                            })}
                                                            defaultValue={email}
                                                            className="form-control m-b-5" />
                                                        {errors.email && <div className="invalid-feedback">{t(getValidationMessage(errors.email))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("HOME_PHONE")}</b></label>
                                                        <InputMask mask="99-99999999" className="form-control" maskChar={null} value={officePhone} onChange={(e) => { setOfficePhone(e.target.value); setValue("officePhoneNumber", e.target.value) }} />
                                                        {(errors.officePhoneNumber) && <div className="invalid-feedback">{t(getValidationMessage(errors.officePhoneNumber))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("MOBILE_PHONE")}</b></label>
                                                        <InputMask mask="999-99999999" className="form-control" maskChar={null} value={mobilePhone} onChange={(e) => { setMobilePhone(e.target.value); setValue("phoneNumber", e.target.value) }} />
                                                        {(errors.phoneNumber) && <div className="invalid-feedback">{t(getValidationMessage(errors.phoneNumber))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("FAX_NUMBER")}</b></label>
                                                        <input type="text" name="faxNumber"
                                                            ref={register}
                                                            defaultValue={faxNumber}
                                                            className="form-control m-b-5" />
                                                        {errors.faxNumber && <div className="invalid-feedback">{t(getValidationMessage(errors.faxNumber))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("CUSTOMER_CODE")}</b></label>
                                                        <input type="text" name="code"
                                                            ref={register}
                                                            defaultValue={code}
                                                            className="form-control m-b-5" />
                                                        {errors.code && <div className="invalid-feedback">{t(getValidationMessage(errors.code))}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <label><b>{t("STATUS")}</b></label><br />
                                                        <div className="switcher">
                                                            <input type="checkbox" name="isActive" id="isActive" className="form-control"
                                                                onChange={(e) => setIsActive(e.target.checked)}
                                                                value={true}
                                                                checked={isActive}
                                                                ref={register} />
                                                            <label htmlFor="isActive"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label><b>{t("SHOPEE_SHOP_NAME")}</b></label>
                                                        <ReactTags tags={selectedShopeeShop} placeholder="" allowNew
                                                            handleDelete={onDeleteShopeeShop} handleAddition={onAddShopeeShop} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label><b>{t("LAZADA_SHOP_NAME")}</b></label>
                                                        <ReactTags tags={selectedLazadaShop} placeholder="" allowNew
                                                            handleDelete={onDeleteLazadaShop} handleAddition={onAddLazadaShop} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label><b>{t("REMARK")}</b></label>
                                                        <textarea type="text" name="remark"
                                                            ref={register}
                                                            defaultValue={remark}
                                                            className="form-control m-b-5" />
                                                        {errors.remark && <div className="invalid-feedback">{t(getValidationMessage(errors.remark))}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={_BRANCH_OFFICE_TAB}>
                                            <div className="row">
                                                <div className="col-lg-12 form-group">
                                                    <button type="button" onClick={() => {
                                                        setBranchId("");
                                                        setCity("");
                                                        setPostcode("");
                                                        setState("");
                                                        setLatitude("");
                                                        setLongitude("");
                                                        setBranchName("");
                                                        setAddress("");
                                                        setBranchModalVisible(true);
                                                        setAltAddress("");
                                                    }} className="btn btn-primary">{t('ADD_BRANCH')}</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <ReactTable data={branchData} columns={_permission ? _branchColumn : _branchColumn.filter(i => i.id != "action")} filterable={false} globalFilterable={false} />
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={_ATTACHMENT_TAB}>
                                            <div className="row">
                                                <div className="col-lg-12 form-group">
                                            <div className="row m-b-5">
                                                <div className="col-lg-3" style={{ display: "flex", alignItems: "center" }}>
                                                    <label><b>{t("ATTACHMENT")}</b></label><br />
                                                </div>

                                                <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                                    <div className="form-group" style={{ width: "100%" }}>

                                                        <Dropzone accept={'.png,.jpg,.jpeg,.pdf'} onDrop={acceptedFiles => fileDrop(acceptedFiles)}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div className="dropzone" style={{ textAlign: "center", height: "40px" }} {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <b style={{ color: "grey", margin: "10px" }}>{t("UPLOAD_BROCHURE")}</b>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                            </div>
                                                    {attachmentFile.length != 0 &&
                                                        <>
                                                            <div className="row m-b-5">
                                                                <div className="col-lg-9">
                                                                    <table style={{ width: "100%", tableLayout: "fixed" }} className="table table-bordered">
                                                                        <thead style={{ textAlign: "center" }}>
                                                                            <tr>
                                                                                <th style={{ borderBottom: "1px solid #ddd", textAlign: "center", width: "15%" }}>{t("ACTION")}</th>
                                                                                <th style={{ borderBottom: "1px solid #ddd", textAlign: "left", width: "70%" }}>{t("FILE_NAME")}</th>
                                                                                <th style={{ borderBottom: "1px solid #ddd", textAlign: "center", width: "15%" }}>{t("VIEW")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                attachmentFile.map((item, i) => {
                                                                                    return (<tr>
                                                                                        <td style={{ textAlign: "center" }}><i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => {
                                                                                            if (item.id !== undefined) {
                                                                                                attachmentToRemoved.push(item.id);
                                                                                                setAttachmentToRemoved([...attachmentToRemoved]);
                                                                                            }
                                                                                            attachment.splice(i, 1);
                                                                                            attachmentFile.splice(i, 1);

                                                                                            setAttachment([...attachment]);
                                                                                            setAttachmentFile([...attachmentFile]);
                                                                                        }}></i></td>
                                                                                        <td style={{ textAlign: "left" }}>{item.name}</td>
                                                                                        <td style={{ textAlign: "center" }}><i className="fas fa-eye" style={{ fontSize: "x-large", cursor: "pointer", color: "orange" }} onClick={() => {
                                                                                            let pdfWindow = window.open("")
                                                                                            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + attachment[i].data + "'></iframe>");
                                                                                        }}></i></td>
                                                                                    </tr>);
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </PanelBody>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <Modal isOpen={branchModalVisible} toggle={() => { setBranchModalVisible(!branchModalVisible) }} size="lg">
                <form onSubmit={branchFormSubmit(submitForm)}>
                    <ModalHeader toggle={() => { setBranchModalVisible(!branchModalVisible) }}>
                        <span>{t("BRANCH")}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <input type="hidden" name="submitType" value={_SUBMIT_TYPE_BRANCH} ref={branchFormRegister} />
                            <div className="col-md-12">
                                <label><b>{t("BRANCH_NAME")}</b></label>
                                <input type="text"
                                    name="branchName"
                                    defaultValue={branchName}
                                    ref={branchFormRegister({ required: true })}
                                    className="form-control m-b-5"
                                    autoComplete="off" />
                                {branchFormError.branchName && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.branchName))}</div>}
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label><b>{t("ADDRESS")}</b></label>
                                    <Autocomplete
                                        className="form-control m-b-5"
                                        id="branchAddress"
                                        onInput={(e) => { onInputCustomerAddress(e.target.value); }}
                                        onPlaceSelected={(place) => { selectCustomerAddress(place); }}
                                        types={['address']}
                                        defaultValue={address}
                                        options={{
                                            types: ["address"],
                                            componentRestrictions: { country: "my" },
                                        }}
                                        name="branchAddress" />
                                    {branchFormError.branchAddress && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.branchAddress))}</div>}
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label><b>{t("ALTERNATE_ADDRESS")}</b></label>
                                    <input type="text"
                                        name="branchAlternateAddress"
                                        defaultValue={altAddress}
                                        ref={branchFormRegister}
                                        className="form-control m-b-5"
                                        autoComplete="off" />
                                    {branchFormError.branchAlternateAddress && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.branchAlternateAddress))}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label><b>{t("LATITUDE")}</b></label>
                                <input type="text"
                                    defaultValue={latitude}
                                    ref={branchFormRegister({ required: true })}
                                    className="form-control m-b-5"
                                    name="latitude"
                                    autoComplete="off" />
                                {branchFormError.latitude && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.latitude))}</div>}
                            </div>
                            <div className="col-md-6">
                                <label><b>{t("LONGITUDE")}</b></label>
                                <input type="text"
                                    name="longitude"
                                    defaultValue={longitude}
                                    ref={branchFormRegister({ required: true })}
                                    className="form-control m-b-5"
                                    autoComplete="off" />
                                {branchFormError.longitude && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.longitude))}</div>}
                            </div>
                            <div className="col-md-6">
                                <label><b>{t("CITY")}</b></label>
                                <input type="text"
                                    name="city"
                                    defaultValue={city}
                                    ref={branchFormRegister({ required: true })}
                                    className="form-control m-b-5"
                                    autoComplete="off" />
                                {branchFormError.city && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.city))}</div>}
                            </div>
                            <div className="col-md-6">
                                <label><b>{t("STATE")}</b></label>
                                <input type="text"
                                    name="state"
                                    defaultValue={state}
                                    ref={branchFormRegister({ required: true })}
                                    className="form-control m-b-5"
                                    autoComplete="off" />
                                {branchFormError.state && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.state))}</div>}
                            </div>
                            <div className="col-md-6">
                                <label><b>{t("POSTAL_CODE")}</b></label>
                                <input type="text"
                                    name="postcode"
                                    defaultValue={postcode}
                                    ref={branchFormRegister({ required: true })}
                                    className="form-control m-b-5"
                                    autoComplete="off" />
                                {branchFormError.postcode && <div className="invalid-feedback">{t(getValidationMessage(branchFormError.postcode))}</div>}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-success" style={{ marginRight: "10px" }}>{t("BTN_SAVE")}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setBranchModalVisible(false); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
            <Modal isOpen={branchOperatingVisible} toggle={() => { setBranchOperatingVisible(!branchOperatingVisible) }} size="lg">
                <form onSubmit={branchFormSubmit(submitForm)}>
                    <ModalHeader toggle={() => { setBranchOperatingVisible(!branchOperatingVisible) }}>
                        <span>{t("OPERATING_HOUR")}</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <input type="hidden" name="submitType" value={_SUBMIT_TYPE_OPERATING} ref={branchFormRegister} />
                            <div className="row" style={{ marginBottom: "30px" }}>
                                <div className="col-lg-12">
                                    <button type="button" className="btn btn-primary" style={{ marginRight: "10px", marginBottom: "5px" }} onClick={() => { AddOperatingHour(); }}>
                                        <i className="fa fa-plus-circle m-r-10"></i> {t("ADD_OPERATING_HOUR")}
                                    </button>
                                    <button type="button" className="btn btn-primary" style={{ marginRight: "10px", marginBottom: "5px" }} onClick={() => { loadStandardOperatingHour(); }}>
                                        <i className="fa fa-clock m-r-10"></i> {t("LOAD_STANDARD_TEMPLATE")}
                                    </button>
                                    {operatingHourDetail.length == 1 && <button type="button" className="btn btn-primary" style={{ marginBottom: "5px" }} onClick={() => { duplicateOperatingHour(); }}>
                                        <i className="fa fa-clock m-r-10"></i> {t("DUPLICATE_FIRST_SELECTION")}
                                    </button>}
                                </div>
                            </div>
                            {operatingHourDetail.length != 0 && <div className="row">
                                <div className="col-lg-12">
                                    <table style={{ width: "100%" }}>
                                        <thead style={{ textAlign: "center" }}>
                                            <tr>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("DAY")}</th>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("FROM")}</th>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("TO")}</th>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "200px" }}>{t("ACTION")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                operatingHourDetail.map((data, key) => {
                                                    return (<tr key={key} id={data.id}>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                                                            <select className="form-control" name={"day[" + key + "]"} ref={branchFormRegister}>
                                                                {_DAYS_OF_WEEK.map((innerData, key) => {
                                                                    var selectedString = innerData.value == parseInt(data.day) ? 'selected' : '';
                                                                    return (<option value={innerData.value} selected={selectedString}>{innerData.label}</option>);
                                                                })}
                                                            </select>
                                                        </td>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                                                            <DateTime className="timePicker"
                                                                dateFormat={false}
                                                                initialValue={data.startTime}
                                                                timeFormat="hh:mm A"
                                                                inputProps={{ readOnly: true, className: "form-control centerText", name: "startTime[" + key + "]", ref: branchFormRegister }}
                                                                closeOnSelect={true} />
                                                        </td>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                                                            <DateTime className="timePicker"
                                                                dateFormat={false}
                                                                timeFormat="hh:mm A"
                                                                initialValue={data.endTime}
                                                                inputProps={{ readOnly: true, className: "form-control centerText", name: "endTime[" + key + "]", ref: branchFormRegister }}
                                                                closeOnSelect={true} />
                                                        </td>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px", textAlign: "center" }}>
                                                            <i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => { setOperatingHourDetail([...operatingHourDetail.filter((i, index) => index != key)]); }}></i>
                                                        </td>
                                                    </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-success" style={{ marginRight: "10px" }}>{t("BTN_SAVE")}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setBranchOperatingVisible(false); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>

            <Modal isOpen={branchOwnerVisible} toggle={() => { setBranchOwnerVisible(!branchOwnerVisible) }} size="lg">
                <form onSubmit={branchFormSubmit(submitForm)}>
                    <ModalHeader toggle={() => { setBranchOwnerVisible(!branchOwnerVisible) }}>
                        <span>{t("SHOP_OWNER")}</span>
                    </ModalHeader>
                    <ModalBody>
                        <input type="hidden" name="submitType" value={_SUBMIT_TYPE_OWNER} ref={branchFormRegister} />
                        <div className="row">
                            {ownerDetail.length == 0 &&
                                <div className="col-md-12">
                                    <label><b>{t("NO_RECORD")}</b></label>
                                </div>
                            }
                            {ownerDetail.length != 0 && <div className="row">
                                <div className="col-lg-12">
                                    <table style={{ width: "100%" }}>
                                        <thead style={{ textAlign: "center" }}>
                                            <tr>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("NAME")}</th>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("ROLE")}</th>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "300px" }}>{t("PHONE")}</th>
                                                <th style={{ borderBottom: "1px solid #ddd", width: "200px" }}>{t("ACTION")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ownerDetail.map((data, key) => {
                                                    return (<tr key={key} id={data.id}>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                                                            <input type="text" name={"ownerName[" + key + "]"}
                                                                ref={branchFormRegister({
                                                                    required: true
                                                                })}
                                                                defaultValue={data.username}
                                                                className="form-control m-b-5" />
                                                            {branchFormError["ownerName"] && branchFormError["ownerName"][key] && <div className="invalid-feedback">{t(getValidationMessage(branchFormError["ownerName"][key]))}</div>}
                                                        </td>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                                                            <input type="text" name={"ownerRole[" + key + "]"}
                                                                ref={branchFormRegister({
                                                                    required: true
                                                                })}
                                                                defaultValue={data.role}
                                                                className="form-control m-b-5" />
                                                            {branchFormError["ownerRole"] && branchFormError["ownerRole"][key] && <div className="invalid-feedback">{t(getValidationMessage(branchFormError["ownerRole"][key]))}</div>}
                                                        </td>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px" }}>
                                                            <input type="text" name={"ownerPhone[" + key + "]"}
                                                                ref={branchFormRegister({
                                                                    required: true,
                                                                    pattern: {
                                                                        value: /^(\+?6?01?)[0-9]-*[0-9]{7,8}$/,
                                                                        message: "INVALID_PHONE"
                                                                    }
                                                                })}
                                                                defaultValue={data.phoneNumber}
                                                                className="form-control m-b-5" />
                                                            {branchFormError["ownerPhone"] && branchFormError["ownerPhone"][key] && <div className="invalid-feedback">{t(getValidationMessage(branchFormError["ownerPhone"][key]))}</div>}
                                                        </td>
                                                        <td style={{ borderBottom: "1px solid #ddd", padding: "10px", textAlign: "center" }}>
                                                            <i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => {
                                                                var newSettingArr = [];

                                                                ownerDetail.map((ea, index) => {
                                                                    if (key != index) {
                                                                        newSettingArr.push({
                                                                            phone: branchGetValues()["ownerPhone[" + index + "]"],
                                                                            role: branchGetValues()["ownerRole[" + index + "]"],
                                                                            owner: branchGetValues()["ownerName[" + index + "]"]
                                                                        })
                                                                    }
                                                                })

                                                                setOwnerDetail(newSettingArr);

                                                                newSettingArr.map((ea, index) => {
                                                                    branchReset({
                                                                        ["ownerPhone[" + index + "]"]: ea.phone,
                                                                        ["ownerRole[" + index + "]"]: ea.role,
                                                                        ["ownerName[" + index + "]"]: ea.owner
                                                                    });
                                                                });
                                                            }}></i>
                                                        </td>
                                                    </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => { AddOwner(); }}>
                            <i className="fa fa-plus-circle m-r-10"></i> {t("ADD_OWNER")}
                        </button>
                        <button type="submit" className="btn btn-success" style={{ marginRight: "10px" }}>{t("BTN_SAVE")}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setBranchOwnerVisible(false); }}>{t('BTN_CANCEL')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )
}

export default withRouter(CustomerDetail);