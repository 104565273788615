/// <summary>
/// Author : Yong Sheng Chuan
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";
    static _API_CODE_KEY = "code";

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_POST = "POST";
    static _API_GET = "GET";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// Url to communicate to controller
/// </summary> 
export class ApiUrl {
    static _API_LOGIN = "/Account/Login";
    static _API_LOGOUT = "/Account/Logout";
    static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
    static _API_GET_ROLE = "/Account/GetAllRoles";
    static _API_CREATE_OR_UPDATE_USER = "/Account/CreateOrSaveUser";
    static _API_USER_CHANGE_PASSWORD = "/Account/ChangePassword";
    static _API_GET_DATA_LIST = "/Configuration/GetData";
    static _API_GET_DATA_BY_ID = "/Configuration/GetDataById";
    static _API_GET_PRODUCT_BY_ID = "/Configuration/GetProductById";
    static _API_GET_ROLE_ACCESS_DATA = "/Account/GetRoleAccessData";
    static _API_GET_USERS = "/Account/GetUsers";
    static _API_GET_CUSTOMER = "/Customer/GetCustomer";
    static _API_GET_DASHBOARD_DATA = "/Home/GetDashboardData"; 
    static _API_USER_REQUEST_RESET_PASSWORD = "/Account/RequestResetPassword";
    static _API_USER_RESET_PASSWORD = "/Account/ResetPassword"; 
    
    static _API_CREATE_OR_UPDATE = "/Configuration/CreateOrUpdate";
    static _API_CREATE_OR_UPDATE_ANNOUNCEMENT = "/Configuration/CreateOrSaveAnouncement";
    static _API_CREATE_OR_UPDATE_QUICK_REMARK = "/Configuration/CreateOrSaveQuickRemark";
    static _API_CREATE_OR_UPDATE_PRODUCT = "/Configuration/CreateOrSaveProduct";
    static _API_CREATE_OR_UPDATE_PRODUCT_BROCHURE = "/Configuration/CreateOrSaveProductBrochure";
    static _API_GET_PRODUCT = "/Configuration/GetProduct";
    static _API_GET_PRODUCT_BROCHURE = "/Configuration/GetProductBrochure";
    static _API_CREATE_OR_UPDATE_CUSTOMER = "/Customer/CreateOrUpdateCustomer";
    static _API_DELETE_CUSTOMER_BRANCH = "/Customer/DeleteCustomerBranch";
    static _API_UPDATE_CUSTOMER_PIC = "/Customer/UpdateCustomerPic";
    static _API_GET_QUOTATION = "/Customer/GetQuotation";
    static _API_GENERATE_EMAIL_FOR_SO = "/Customer/GenerateEmailForSalesOrder";

    static _API_GET_APPOINTMENT_RELATED_DATA = "/Appointment/GetAppointmentRelatedData";
    static _API_GET_APPOINTMENT_DATA = "/Appointment/GetUserAppointment";
    static _API_GENERATE_APPOINTMENT_PDF = "/Appointment/GenerateAppointmentPdf";
    static _API_CREATE_OR_UPDATE_APPOINTMENT = "/Appointment/CreateOrUpdateAppointment";
    static _API_CREATE_OR_UPDATE_LEAD = "/Appointment/CreateOrUpdateLead";
    static _API_REOPEN_LEAD = "/Appointment/ReopenLead";
    static _API_CREATE_OR_UPDATE_CUSTOMER_FROM_LEAD = "/Customer/CreateOrUpdateCustomerFromLead";
    static _API_GET_LEAD = "/Appointment/getLead"; 
    static _API_CREATE_OR_UPDATE_TASK = "/Task/CreateOrUpdateTask";
    static _API_GET_ASSIGNED_TASK = "/Task/GetAssignedTask";
    static _API_GET_TASK_WORK_HISTORY = "/Task/GetTaskWorkHistory";
    static _API_UPDATE_WORK_PROGRESS = "/Task/UpdateWorkProgress";
    static _API_GET_OPERATION_LOG = "/Configuration/GetOperationLog";
    static _API_GET_TASK_WORK_HISTORY_REPORT = "/Report/GetTaskHistoryReport";
    static _API_GET_VISIT_HISTORY_REPORT = "/Report/GetVisitHistoryReport";
    static _API_GET_SALES_KPI_REPORT = "/Report/GetSalesKpiReport";
    static _API_GET_MARKET_SURVEY_REPORT = "/Report/GetMarketSurveyReport";
    static _API_GET_VISIT_SUMMARY_REPORT = "/Report/GetVisitSummaryReport";
    static _API_GET_CUSTOMER_VISIT_SUMMARY_REPORT = "/Report/GetCustomerVisitSummaryReport";
    static _API_GET_LEAD_SUMMARY_REPORT = "/Report/GetLeadSummaryReport";
    static _API_GET_SALES_ORDER_REPORT = "/Report/GetSalesOrderReport";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    static _URL_MAIN = "/";
    static _URL_LOGIN = "/login/:email/:token";
    static _URL_DASHBOARD = "/dashboard";
    static _URL_MANAGE_ROLE = "/ManageRole";
    static _URL_ROLE_DETAIL = "/RoleDetail";
    static _URL_SYSTEM_SETTINGS = "/SystemSetting";
    static _URL_MANAGE_ADMIN = "/ManageAdmin";
    static _URL_ADMIN_DETAIL = "/AdminDetail";
    static _URL_MANAGE_USER = "/ManageUser";
    static _URL_USER_DETAIL = "/UserDetail";
    static _URL_MANAGER_DETAIL = "/ManagerDetail";
    static _URL_SALES_REP_DETAIL = "/SalesRepDetail";
    static _URL_OPERATION_LOG = "/OperationLog";
    static _URL_USER_CHANGE_PASSWORD = "/ChangePassword";
    static _URL_MANAGE_CUSTOMER = "/ManageCustomer";
    static _URL_CUSTOMER_DETAIL = "/CustomerDetail";
    static _URL_MANAGE_MANAGER = "/ManageManager;";
    static _URL_MANAGE_SALES_REP = "/ManageSalesRep";
    static _URL_SYSTEM_MESSAGE = "/ManageSystemMessage";
    static _URL_SYSTEM_MESSAGE_DETAIL = "/ManageSystemMessageDetail";
    
    static _URL_CHECKLIST = "/ManageCheckList";
    static _URL_MANAGE_APPOINTMENT = "/ManageAppointment";
    static _URL_MANAGE_LEAD = "/ManageLead";
    static _URL_LEAD_DETAIL = "/LeadDetail";
    static _URL_QUICK_REMARK = "/QuickRemark";
    static _URL_QUICK_REMARK_DETAIL = "/QuickRemarkDetail";
    static _URL_SERVICE_TYPE = "/ServiceType";
    static _URL_ATTACHMENT_TYPE = "/ManageAttachmentType";
    static _URL_MANAGE_PRODUCT_CATEGORY = "/ManageProductCategory";
    static _URL_MANAGE_PRODUCT = "/ManageProduct";
    static _URL_MANAGE_PRODUCT_DETAIL = "/ManageProductDetail";
    static _URL_MANAGE_PRODUCT_BROCHURE = "/ManageProductBrochure";
    static _URL_MANAGE_PRODUCT_BROCHURE_DETAIL = "/ManageProductBrochureDetail";

    static _URL_MANAGE_TASK = "/ManageTask";
    static _URL_TASK_DETAIL = "/TaskDetail";
    static _URL_MANAGE_KPI_REPORT = "/KpiReport";
    static _URL_MANAGE_TASK_HISTORY_REPORT = "/TaskHistoryReport";
    static _URL_MANAGE_VISIT_HISTORY_REPORT = "/VisitHistoryReport";
    static _URL_MARKET_SURVEY_REPORT = "/MarketSurveyReport";
    static _URL_MANAGE_CUSTOMER_VISIT_SUMMARY_REPORT = "/CustomerVisitSummaryReport";
    static _URL_MANAGE_LEAD_SUMMARY_REPORT = "/LeadSummaryReport";
    static _URL_MANAGE_VISIT_SUMMARY_REPORT = "/VisitSummaryReport";
    static _URL_MANAGE_SALES_ORDER_REPORT = "/SalesOrderReport";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class Status {
    static _ENABLED = 1;
    static _DISABLED = 0;
    static _PENDING_APPROVAL = 0;
    static _APPROVED = 1;
    static _REJECTED = 2;
    static _CANCELED = 3;
}

/// <summary>
/// Author : Yong Sheng Chuan
/// changing this need to match value from backend
/// </summary>
export class AccessRight {
    static _SYSTEM_SETTING_CONTROL_PERMISSION = "System Setting Control";
    static _ADMIN_ACCOUNT_CONTROL_PERMISSION = "Admin Account Control";
    static _USER_ACCOUNT_CONTROL_PERMISSION = "User Account Control";
    static _MANAGER_ACCOUNT_CONTROL_PERMISSION = "Manager Account Control";
    static _SALESREP_ACCOUNT_CONTROL_PERMISSION = "Sales Rep Account Control";
    static _ACCESS_LEVEL_CONTROL_PERMISSION = "Access Level Control";
    static _ANNOUNCEMENT_CONTROL_PERMISSION = "Announcement Control";
    static _OPERATION_LOG_PERMISSION = "Operation Log Control";
    static _APPOINTMENT_CONTROL_PERMISSION = "Appointment Control";
    static _CUSTOMER_CONTROL_PERMISSION = "Customer Control";
    static _LEAD_CONTROL_PERMISSION = "Lead Control";
    static _PRODUCT_CONTROL_PERMISSION = "Product Control";
    static _SALES_KPI_REPORT_CONTROL_PERMISSION = "Sales Kpi Report Control";
    static _EMP_WORK_REPORT_CONTROL_PERMISSION = "Emp Work Report Control";
    static _TASK_MANAGEMENT_CONTROL_PERMISSION = "Task Management Control";
    static _VISIT_HISTORY_REPORT_CONTROL_PERMISSION = "Visit History Report Control";
    static _SALES_ORDER_REPORT_CONTROL_PERMISSION = "Sales Order Report Control";
    static _MARKET_SURVEY_REPORT_CONTROL_PERMISSION = "Market Survey Report Control";
    static _CUSTOMER_VISIT_SUMMARY_REPORT_CONTROL_PERMISSION = "Customer Visit Summary Report Control";
    static _LEAD_REPORT_CONTROL_PERMISSION = "Lead Report Control";

    static _READ_PERMISSION_STRING = "(READ)";
    static _WRITE_PERMISSION_STRING = "(WRITE)";
    static _READ_BIT_POSITION = 1;
    static _WRITE_BIT_POSITION = 0;
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export class Language {
    static _CHINESE = "cn";
    static _ENGLISH = "en";
    static _BAHASA_MELAYU = "bm";
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
    static _LOGIN_GUID = "loginGuid";
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export class InputValidationKey {
    static _REQUIRED_KEY = "required";
    static _MAX_LENGTH_KEY = "maxLength";
    static _MIN_LENGTH_KEY = "minLength";
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export class AlertTypes {
    static _DEFAULT = 'default';
    static _INFO = 'info';
    static _SUCCESS = 'success';
    static _WARNING = 'warning';
    static _DANGER = 'danger';
    static _ERROR = 'error';
    static _INPUT = 'input';
    static _CUSTOM = 'custom';
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class DataName {
    static _USER = "User";
    static _ROLE = "Role";
    static _SYSTEM_SETTING = "SystemSetting";
    static _COMPANY = "Company";
    static _OPERATION_LOG = "OperationLog";
    static _SYSTEM_MESSAGE = "Announcement";
    static _ACTION_TYPE = "ActionType";
    static _CUSTOMER = "Customer";
    static _APPOINTMENT = "Appointment";
    static _ATTACHMENT_TYPE = "AttachmentType";
    static _CUSTOMER_OPERATING_HOUR = "CustomerShopOperatingHour";
    static _LEAD_ASSIGNMENT = "LeadAssignment";
    static _PRODUCT = "Product";
    static _PRODUCT_CATEGORY = "ProductCategory";
    static _SALES_REP_MANAGER = "SalesRepManager";
    static _SERVICE_CHECK_LIST = "ServiceCheckList";
    static _SERVICE_QUICK_REMARK = "ServiceQuickRemark";
    static _SERVICE_TYPE = "ServiceType";
    static _VISIT_ATTACHMENT = "VisitAttachment";
    static _VISIT_HISTORY = "VisitHistory";
    static _TASK = "Task";
    static _QUOTATION = "Quotation";
    static _CITY = "City";
    static _BRANCH = "Branch";
    static _TASK_PRIORITY = "TaskPriority";
    static _CUSTOMER_OFFICE_BRANCH = "CustomerOfficeBranch";
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class Day {
    static _SUNDAY = 0;
    static _MONDAY = 1;
    static _TUESDAY = 2;
    static _WEDNESDAY = 3;
    static _THURSDAY = 4;
    static _FRIDAY = 5;
    static _SATURDAY = 6;
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class Role {
    static _ADMIN = 1;
    static _MANAGER = 2;
    static _SALES_REP = 3;
    static _ADMIN_SPECIFIC = 100;
    static _USER_SPECIFIC = 200;
    static _ROLE_WITH_CUSTOMER = 300;
    static _ROLE_WITH_TASK = 400;
}

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
export class ViewMode {
    static _VIEW_MODE_CREATED = 0;
    static _VIEW_MODE_ASSIGNED = 1;
}

/// <summary>
/// Author: Yong Sheng Chuan
/// </summary>
export class ProductType {
    static _PRODUCT = 1;
    static _PROMOTION = 2;
    static _BROCHURE = 3;
}