import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName, Status, WebUrl } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../util/Util";
import ApiEngine from '../../util/ApiEngine.js';
import { useHistory } from "react-router-dom";


/// <summary>
/// Author: Nelson
/// </summary>
const ChangePassword = props => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();
    var _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, getValues, unregister, triggerValidation,watch } = useForm();
    const { loginType } = useSelector(state => ({
        loginType: state.authState.userData.loginType
    }));

    /// <summary>
    /// Author: Nelson
    /// </summary>
    useEffect(() => {
        register({ name: "oldPassword" }, { required: "ENTER_OLD_PASSWORD" });
        register({ name: "password" }, { required: "ENTER_NEW_PASSWORD", minLength: { value: 6, message: "PASSWORD_MIN_LENGTH" } });
        register({ name: "confirmNewPassword" },
            {
                required: "ENTER_CONFIRM_NEW_PASSWORD",
                minLength: { value: 6, message: "PASSWORD_MIN_LENGTH" },
                validate: (value) => value === watch('password') || "NEW_PASSWORD_NOT_MATCH"
            });
    }, []);

    /// <summary>
    /// Author: Nelson
    /// Yong Sheng Chuan - change the checking of old pw from frontend to backend
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        var responseJson = await ApiEngine.post(ApiUrl._API_USER_CHANGE_PASSWORD, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && navigateTo(_history, WebUrl._URL_DASHBOARD)
        }));
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={handleSubmit(submitForm)}>
                            {t("CHANGE_PASSWORD")}
                        </PanelHeader>
                        <PanelBody>
                            <form className="container p-0">
                                <div className="form-group">
                                    <label className="login-form-label">{t("OLD_PASSWORD")}</label>
                                    <input
                                        autoComplete="off"
                                        type="password"
                                        id="oldPassword"
                                        name="oldPassword"
                                        className="form-control"
                                        onInput={(e) => { setValue('oldPassword', e.target.value); }}
                                    />
                                    {errors.oldPassword && <div className="invalid-feedback">{t(errors.oldPassword.message)}</div>}
                                </div>
                                <div className="form-group">
                                    <label className="login-form-label">{t("NEW_PASSWORD")}</label>
                                    <input
                                        autoComplete="off"
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        onInput={(e) => { setValue('password', e.target.value); }}
                                    />
                                    {errors.password && <div className="invalid-feedback">{t(errors.password.message)}</div>}
                                </div>
                                <div className="form-group">
                                    <label className="login-form-label">{t("CONFIRM_NEW_PASSWORD")}</label>
                                    <input
                                        autoComplete="off"
                                        type="password"
                                        id="confirmNewPassword"
                                        name="confirmNewPassword"
                                        className="form-control"
                                        onInput={(e) => { setValue('confirmNewPassword', e.target.value); }}
                                    />
                                    {errors.confirmNewPassword && <div className="invalid-feedback">{t(errors.confirmNewPassword.message)}</div>}
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;