import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { navigateTo, createMultiPartFormBody, getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import NavigationButton from '../../components/custom/NavigationButton';
import { ApiKey, WebUrl, ApiUrl, Role, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageAccount = props => {
	const { t } = useTranslation();
	let _history = useHistory();
	const [accountData, setAccountData] = useState([]);
	const [quotationData, setQuotationData] = useState([]);
	const [isConfigurationModalVisible, setIsConfigurationModalVisible] = useState(false); 
	const _language = useSelector(state => state.appState.language);
	var _dispatch = useDispatch();
	const _userData = useSelector(state => state.authState.userData);

	var _detailUrl = "";
	var _permission = "";
	var _label = "";
	if (props.role == Role._USER_SPECIFIC) {
		_detailUrl = WebUrl._URL_USER_DETAIL;
		_permission = AccessRight._USER_ACCOUNT_CONTROL_PERMISSION;
		_label = "USER";
	}
	else if (props.role == Role._ADMIN_SPECIFIC) {
		_detailUrl = WebUrl._URL_ADMIN_DETAIL;
		_permission = AccessRight._ADMIN_ACCOUNT_CONTROL_PERMISSION;
		_label = "ADMIN";
	}
	else if (props.role == Role._MANAGER) {
		_detailUrl = WebUrl._URL_MANAGER_DETAIL;
		_permission = AccessRight._MANAGER_ACCOUNT_CONTROL_PERMISSION;
		_label = "MANAGER";
	}
	else if (props.role == Role._SALES_REP) {
		_detailUrl = WebUrl._URL_SALES_REP_DETAIL;
		_permission = AccessRight._SALESREP_ACCOUNT_CONTROL_PERMISSION;
		_label = "SALES_REP";
	}

	let _tableColumns = useMemo(() => [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => {
				return (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => { navigateTo(_history, _detailUrl, row.original) }}><span className="text-warning">{t(checkIfPermissionExist(_permission, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
								{(props.role == Role._SALES_REP || props.role == Role._MANAGER) && <DropdownItem onClick={() => { viewQuotation(row.original) }}><span className="text-warning">{t("VIEW_QUOTATION")}</span></DropdownItem>}
								{checkIfPermissionExist(_permission, true) && <DropdownItem onClick={() => { deleteUser(row.original) }}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				)
			},
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" }
		},
		{
			Header: t("NAME"),
			accessor: "username"
		},
		{
			Header: t("PHONE"),
			accessor: "phoneNumber"
		},
		{
			Header: t("ROLE"),
			accessor: "roleName"
		},
		{
			Header: t("STATUS"),
			accessor: "isActive",
			Cell: ({ row }) => {
				return <span>{row.original.isActive == 1 ? <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span className="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>}</span>
			},
			minWidth: 100
		},
	], [_language]);

	let _quotationTableColumns = useMemo(() => [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => {
				return (<button className="btn btn-default btn-sm" style={{ height: "auto" }} onClick={() => { window.open(row.original.quotationDocument); }}>{t("VIEW")}</button>);
			},
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" }
		},
		{
			Header: t("CUSTOMER_NAME"),
			accessor: "customerName"
		},
		{
			Header: t("DOCUMENT"),
			accessor: "quotationDocumentName"
		},
	], [_language]);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function viewQuotation(data) {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_QUOTATION + "?userId=" + data.id);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setQuotationData(responseJson[ApiKey._API_DATA_KEY]);
			setIsConfigurationModalVisible(true);
		}
    }

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function init() {
		var responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?userId=" + _userData.userId+"&role=" + props.role);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setAccountData(responseJson[ApiKey._API_DATA_KEY]);
		}
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function deleteUser(admin) {
		_dispatch(showMessage({
			type: AlertTypes._WARNING,
			content: t("DELETE_SETTING_CONFIRMATION"),
			onConfirm: () => performDeleteUser(admin),
			showCancel: true
		}));
	}

	/// <summary>
	/// Author : Yong Sheng Chuan
	/// </summary>
	async function performDeleteUser(admin) {
		let params = {
			"status": false,
			"id": admin.id
		}

		var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._USER, createMultiPartFormBody(params));

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			init();
		}

		_dispatch(showMessage({
			type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
			content: t(responseJson[ApiKey._API_MESSAGE_KEY])
		}));
	}

	return (
		<div>
			<div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
				<h1 className="page-header">{t(_label)}</h1>
				<div>
					{
						checkIfPermissionExist(_permission, true) &&
						<NavigationButton history={_history} url={_detailUrl} buttonText={t("ADD_NEW_" + _label)} />
					}
				</div>
			</div>
			<Panel>
				<PanelBody>
					<ReactTable data={accountData} columns={_tableColumns} filterable={true} />
				</PanelBody>
			</Panel>
			<Modal isOpen={isConfigurationModalVisible} size="lg">
				<ModalHeader toggle={() => { setIsConfigurationModalVisible(!isConfigurationModalVisible) }}>
					{t("QUOTATION_LIST")}
				</ModalHeader>
				<ModalBody>
					<div className="form-group row m-b-15">
						<div className="col-md-12">
							<ReactTable data={quotationData} columns={_quotationTableColumns} filterable={true} />
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<button type="button" className="btn btn-danger" onClick={() => { setIsConfigurationModalVisible(!isConfigurationModalVisible); }}>{t('BTN_CANCEL')}</button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default ManageAccount;