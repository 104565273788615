import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../util/Util";
import { ApiKey, ApiUrl, AlertTypes, AccessRight, Status, DataName } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from '../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import moment from 'moment';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const LeadAppointment = (props) => {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors, setValue, unregister, watch } = useForm();
    var _dispatch = useDispatch();

    const [appointmentType, setAppointmentType] = useState([]);
    const [appointmentTypeId, setAppointmentTypeId] = useState('');
    const [serviceDate, setServiceDate] = useState('');
    const [leadAppointment, setLeadAppointment] = useState({});
    const _userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        if (props.leadId && props.isModalVisible) {
            register({ name: 'serviceDate' }, { required: true });
            init();
        }
        else {
            unregister("serviceDate");
        }
    }, [props.leadId, props.isModalVisible]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._LEAD_ASSIGNMENT + "&id=" + props.leadId;

        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {

            var parsedAppointment = {
                userId: responseJson[ApiKey._API_DATA_KEY]["userId"],
                cityId: responseJson[ApiKey._API_DATA_KEY]["cityId"],
                leadId: responseJson[ApiKey._API_DATA_KEY]["id"],
                appointmentTime: responseJson[ApiKey._API_DATA_KEY]["serviceTime"],
                phoneNumber: responseJson[ApiKey._API_DATA_KEY]["phoneNumber"],
                leadRemark: responseJson[ApiKey._API_DATA_KEY]["leadRemark"],
                address: responseJson[ApiKey._API_DATA_KEY]["address"],
                cityName: responseJson[ApiKey._API_DATA_KEY]["cityName"],
                user: responseJson[ApiKey._API_DATA_KEY]["user"],
                customerName: responseJson[ApiKey._API_DATA_KEY]["customerName"],
                isComplete: responseJson[ApiKey._API_DATA_KEY]["isComplete"],
                hasAppointment: responseJson[ApiKey._API_DATA_KEY]["hasAppointment"]
            };
            setValue('serviceDate', responseJson[ApiKey._API_DATA_KEY]["serviceTime"]);
            setServiceDate(responseJson[ApiKey._API_DATA_KEY]["serviceTime"]);

            if (responseJson[ApiKey._API_DATA_KEY]["hasAppointment"]) {
                parsedAppointment["appointmentTime"] = responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["appointmentTime"];
                parsedAppointment["serviceTypeId"] = responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["serviceTypeId"];
                parsedAppointment["serviceType"] = responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["serviceType"];

                parsedAppointment["allowDirectComplete"] = responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["allowDirectComplete"];
                setValue('serviceDate', responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["appointmentTime"]);

                var appointmentTime = responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["appointmentTime"];
                
                setServiceDate(moment(appointmentTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm"));
                parsedAppointment["appointmentId"] = responseJson[ApiKey._API_DATA_KEY]["appointmentModel"]["id"];
            }

            setLeadAppointment({});
            setLeadAppointment(parsedAppointment);
        }

        var apiUrl = ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._SERVICE_TYPE;

        responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const appointmentTypeData = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (type) {
                appointmentTypeData.push({ label: type.name, value: type.id, visitRequired: type.isVisitRequired });
            });

            setAppointmentType(appointmentTypeData);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            status: true,
            appointmentTime: serviceDate,
            serviceTypeId: data.appointmentType,
            leadId: data.leadId,
            userId: leadAppointment.userId,
            cityId: leadAppointment.cityId,
            remark: leadAppointment.leadRemark
        }

        if (leadAppointment.appointmentId) {
            params["id"] = leadAppointment.appointmentId;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_APPOINTMENT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && onFinish(true)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function onFinish(status) {
        if (props.onFinish) {
            props.onFinish(status)
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function updateAppointmentComplete(appointmentId) {
        let params = {
            "isComplete": true,
            "id": appointmentId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_APPOINTMENT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            onFinish(true);
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        }));
    }

    return (
        <Modal isOpen={props.isModalVisible} toggle={() => { onFinish() }}>
            <form onSubmit={handleSubmit(submitForm)}>
                <ModalHeader toggle={() => { onFinish() }}>{t("APPOINTMENT")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <input type="hidden" className="form-control" name="leadId" readOnly={true} defaultValue={leadAppointment.leadId} ref={register} />
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("SERVICER")}</b></label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="leadServicer" readOnly={true} defaultValue={leadAppointment.user} ref={register} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("CUSTOMER_NAME")}</b></label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="address" readOnly={true} defaultValue={leadAppointment.customerName} ref={register} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("CITY")}</b></label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="city" readOnly={true} defaultValue={leadAppointment.cityName} ref={register} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("ADDRESS")}</b></label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="address" readOnly={true} defaultValue={leadAppointment.address} ref={register} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("MOBILE_PHONE")}</b></label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="phone" readOnly={true} defaultValue={leadAppointment.phoneNumber} ref={register} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("APPOINTMENT_TIME")}</b></label>
                                <div className="col-md-8">
                                    <DateTime
                                        value={serviceDate}
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        timeFormat={"HH:mm"}
                                        inputProps={{
                                            readOnly: true,
                                            className: 'form-control',
                                            style: { cursor: 'pointer', background:"transparent" }
                                        }}
                                        isValidDate={(e) => {
                                            return e.isSameOrAfter(moment().format("YYYY-MM-DD HH:mm"))
                                        }}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setServiceDate(e.format("YYYY-MM-DD HH:mm"));
                                                setValue("serviceDate", e.format("YYYY-MM-DD HH:mm"));
                                            }
                                            else {
                                                setServiceDate("");
                                                setValue("serviceDate", "");
                                            }
                                        }} />
                                    {errors.serviceDate && <div className="invalid-feedback">{t(getValidationMessage(errors.serviceDate))}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("APPOINTMENT_TYPE")}</b></label>
                                <div className="col-md-8">
                                    <select className="form-control" ref={register({ required: true })} name="appointmentType" value={appointmentTypeId} onChange={(e) => { setAppointmentTypeId(e.target.value) }}>
                                        {
                                            appointmentType.map((data, key) => {
                                                return (<option value={data.value}>{data.label}</option>);
                                            })
                                        }
                                    </select>
                                    {errors.appointmentType && <div className="invalid-feedback">{t(getValidationMessage(errors.appointmentType))}</div>}
                                </div>
                            </div>
                        </div>
                        {
                            <div className="col-lg-12">
                                <div className="form-group row m-b-15">
                                    <label className="col-md-4 col-form-label"><b>{t("REMARK")}</b></label>
                                    <div className="col-md-8">
                                        <textarea type="text" className="form-control" name="remark" readOnly={true} defaultValue={leadAppointment.leadRemark} ref={register} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {leadAppointment["userId"] == _userData.userId && !leadAppointment.isComplete && <button type="submit" className="btn btn-primary" style={{ marginRight: "10px" }}>{t("SAVE")}</button>}
                    {leadAppointment["userId"] == _userData.userId && leadAppointment.hasAppointment && !leadAppointment.isComplete && leadAppointment.allowDirectComplete && <button type="button" className="btn btn-success" onClick={() => updateAppointmentComplete(leadAppointment.appointmentId)}>{t("COMPLETE")}</button>}
                    <button type="button" className="btn btn-danger" onClick={() => { onFinish(); }}>{t('BTN_CANCEL')}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default LeadAppointment