import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { ApiKey, WebUrl, ApiUrl, ProductType, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import NavigationButton from '../../components/custom/NavigationButton';
import { navigateTo, createMultiPartFormBody, getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import { useDispatch, useSelector } from "react-redux";
import ApiEngine from '../../util/ApiEngine.js';
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useForm } from "react-hook-form";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageProduct = props => {
    var _history = useHistory();
    var { t } = useTranslation();
    var _dispatch = useDispatch();
    const [postData, setPostData] = useState({});
    const [apiUrl, setApiUrl] = useState('');
    const [isPromotion, setIsPromotion] = useState(false);
    const { register, handleSubmit, errors, setValue, unregister, setError, triggerValidation } = useForm();

    const _language = useSelector(state => state.appState.language);

    let _tableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu alignRight>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_MANAGE_PRODUCT_DETAIL, row.original) }}><span>{t(checkIfPermissionExist(AccessRight._PRODUCT_CONTROL_PERMISSION, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
                            {checkIfPermissionExist(AccessRight._PRODUCT_CONTROL_PERMISSION, true) && <DropdownItem onClick={() => { deleteProduct(row.original.id); }}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: '1%'
        },
        {
            Header: t("PRODUCT_NAME"),
            accessor: "name",
            disableSortBy: true
        },
        {
            Header: t("PRODUCT_CODE"),
            accessor: "productCode",
            disableSortBy: true
        },
        {
            Header: t("DESCRIPTION"),
            accessor: "description",
            disableSortBy: true
        },
        {
            Header: t("STATUS"),
            accessor: "isActive",
            Cell: ({ row }) => {
                return <span>{row.original.isActive == 1 ? <span className="badge badge-secondary badge-green">{t("ACTIVE")}</span> : <span className="badge badge-secondary badge-danger">{t("SUSPENDED")}</span>}</span>
            },
            minWidth: 100
        },
    ], [_language]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        handleSubmit(submitForm)();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function deleteProduct(product) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            content: t("DELETE_SETTING_CONFIRMATION"),
            onConfirm: () => performDeleteProduct(product),
            showCancel: true
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function performDeleteProduct(product) {
        let params = {
            "status": false,
            "id": product
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._PRODUCT, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleSubmit(submitForm)();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "productName": data.name,
            "productCode": data.code,
            "productType": isPromotion ? ProductType._PROMOTION : ProductType._PRODUCT,
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_PRODUCT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const PaginationTable = useMemo(() =>
        <ReactTable
            className="col-nowrap"
            initialPageSize={10}
            columns={_tableColumns}
            fetchUrl={apiUrl}
            postData={postData} />
        , [apiUrl, postData])

    return (
        <div>
            <div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("PRODUCT")}</h1>
                {
                    checkIfPermissionExist(AccessRight._PRODUCT_CONTROL_PERMISSION, true) &&
                    <NavigationButton history={_history} url={WebUrl._URL_MANAGE_PRODUCT_DETAIL} buttonText={t("ADD_PRODUCT")} />
                }
            </div>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("PRODUCT_NAME")}</label>
                                        <input className="form-control" id="name" name="name" type="text" ref={register} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("PRODUCT_CODE")}</label>
                                        <input className="form-control" id="code" name="code" type="text" ref={register} />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label><b>{t('IS_PROMOTION')}</b></label><br />
                                        <div className="switcher">
                                            <input type="checkbox" name="isPromotion" id="isPromotion"
                                                onChange={(e) => setIsPromotion(e.target.checked)}
                                                value={true}
                                                checked={isPromotion}
                                                ref={register} />
                                            <label htmlFor="isPromotion"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <button className="btn btn-primary" onClick={() => { handleSubmit(submitForm)() }}>{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageProduct;