import React, { useMemo, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, AlertTypes, DataName, AccessRight } from "../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import ApiEngine from '../../util/ApiEngine.js';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const EditRole = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, getValues } = useForm();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(true);
    const [isAdminRole, setIsAdminRole] = useState(false);
    const [accessRightOption, setAccessRightOption] = useState({});
    const [roleId, setRoleId] = useState("");

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            var accessOption = {};
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLE_ACCESS_DATA);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                accessOption = responseJson[ApiKey._API_DATA_KEY];
            }

            if (_location.state) {
                var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._ROLE + "&id=" + _location.state["id"];

                var responseJson = await ApiEngine.get(apiUrl);
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Object.keys(accessOption).map((key, value) => {
                        Object.keys(accessOption[key]).map((innerKey, innerValue) => {
                            accessOption[key][innerKey] = responseJson[ApiKey._API_DATA_KEY][innerKey[0].toLowerCase() + innerKey.substr(1).replace(/\s+/g, '')];
                        });
                    });

                    setName(responseJson[ApiKey._API_DATA_KEY]["roleName"]);
                    setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
                    setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
                    setIsAdminRole(responseJson[ApiKey._API_DATA_KEY]["isAdminRole"]);
                    setRoleId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                }
            }

            setAccessRightOption(accessOption);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            "roleName": data.roleName,
            "description": data.description,
            "status": data.status,
            "isAdminRole": data.isAdminRole,
        }

        if (!stringIsNullOrEmpty(roleId)) {
            params["id"] = roleId;
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._ROLE, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_ROLE)
        }));
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={checkIfPermissionExist(AccessRight._ACCESS_LEVEL_CONTROL_PERMISSION, true) ? () => { handleSubmit(submitForm)() } : undefined}>
                            {roleId ? t("EDIT_ROLE") : t("ADD_NEW_ROLE")}
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("ROLE_NAME")}</b></label>
                                            <input type="text" name="roleName" className="form-control m-b-5"
                                                ref={register({
                                                    required: "INPUT_FIELD_REQUIRED"
                                                })} defaultValue={name} />
                                            {errors.roleName && <div className="invalid-feedback">{t(getValidationMessage(errors.roleName))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("DESCRIPTION")}</b></label>
                                            <input type="text" name="description" className="form-control m-b-5"
                                                ref={register({
                                                    required: "INPUT_FIELD_REQUIRED"
                                                })} defaultValue={description} />
                                            {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("IS_ADMIN")}</b></label><br />
                                            <div className="switcher">
                                                <input type="checkbox" name="isAdminRole" id="isAdminRole"
                                                    onChange={(e) => setIsAdminRole(e.target.checked)}
                                                    value={true}
                                                    checked={isAdminRole}
                                                    ref={register} />
                                                <label htmlFor="isAdminRole"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <label><b>{t("STATUS")}</b></label><br />
                                            <div className="switcher">
                                                <input type="checkbox" name="status" id="status"
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                    value={true}
                                                    checked={status}
                                                    ref={register} />
                                                <label htmlFor="status"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h3>{t("FUNCTION_LIST")}</h3>
                                <div className="row">
                                    {Object.keys(accessRightOption).map((key, value) => {
                                        return (<div className="col-lg-4" key={value}>
                                            <div className="card text-truncate mb-3">
                                                <div className="card-header">
                                                    <b>{t(key.toUpperCase())}</b>
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-nowrap">{t("FUNCTION_NAME")}</th>
                                                                    <th className="text-nowrap">{t("ACCESS_LEVEL")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(accessRightOption[key]).map((innerKey, innerValue) => {
                                                                    var accessString = ("00" + accessRightOption[key][innerKey].toString(2));
                                                                    accessString = accessString.substr(accessString.length - 2);
                                                                    var canWrite = checkIfPermissionExist(innerKey, true);
                                                                    var canRead = checkIfPermissionExist(innerKey, false);

                                                                    return (<tr key={innerValue}>
                                                                        <td> {t(innerKey.toUpperCase().replace(/ /g, "_"))}</td>
                                                                        <td>
                                                                            <select defaultValue={accessRightOption[key][innerKey]} className="form-control" name={innerKey[0].toLowerCase() + innerKey.substr(1).replace(/\s+/g, '')} ref={register}>
                                                                                {(canWrite && canRead) && <option value="3">{t("ALL")}</option>}
                                                                                {canRead && <option value="1">{t("VIEW")}</option>}
                                                                                <option value="0">{t("NONE")}</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>)
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
                                    })}
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    )
}

export default withRouter(EditRole);