import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, getValidationMessage } from "../../util/Util";
import { ApiKey, ConfigurationType, ApiUrl, AccessRight, AlertTypes, DataName } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageServiceType = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    const _accessRight = props.configurationType == DataName._PRODUCT_CATEGORY ? AccessRight._PRODUCT_CONTROL_PERMISSION : AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION;
    const { register, handleSubmit, errors } = useForm();
    var _dispatch = useDispatch();
    const [isConfigurationModalVisible, setIsConfigurationModalVisible] = useState(false);
    const [isVisitRequired, setIsVisitRequired] = useState(false);
    const [configuration, setConfiguration] = useState({});
    const [configurationData, setConfigurationData] = useState([]);
    const _language = useSelector(state => state.appState.language);

    let _tableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                return (<div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { loadConfigurationData(row.original) }}><span>{t(checkIfPermissionExist(_accessRight, true) ? "EDIT" : "VIEW")}</span></DropdownItem>
                            {checkIfPermissionExist(_accessRight, true) && <DropdownItem onClick={() => deleteConfiguration(row.original.id)}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>);
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 100
        },
        {
            Header: t("DESCRIPTION"),
            accessor: "description",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 200
        },
    ], [_language]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        getConfiguration();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function getConfiguration() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_DATA_LIST + "?type=" + DataName._SERVICE_TYPE);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setConfigurationData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function loadConfigurationData(data) {
        setIsVisitRequired(data.isVisitRequired ?? true);
        setConfiguration(data);
        setIsConfigurationModalVisible(!isConfigurationModalVisible);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function deleteConfiguration(configurationId) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            content: t("DELETE_SETTING_CONFIRMATION"),
            onConfirm: () => performDeleteConfiguration(configurationId),
            showCancel: true
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function performDeleteConfiguration(serviceId) {
        let params = {
            "status": false,
            "id": serviceId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._SERVICE_TYPE, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getConfiguration();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        let formCollection = {
            description: data.description,
            name: data.name,
            status: true,
            isVisitRequired: isVisitRequired
        };

        if (configuration.id) {
            formCollection["id"] = configuration.id;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._SERVICE_TYPE, createMultiPartFormBody(formCollection));
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getConfiguration();
            setIsConfigurationModalVisible(!isConfigurationModalVisible)
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    return (
        <div>
            <div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("SERVICE_TYPE")}</h1>
                {
                    checkIfPermissionExist(_accessRight, true) &&
                    <button type="button" onClick={() => { loadConfigurationData({}); }} className="btn btn-primary">{t('ADD_SERVICE_TYPE')}</button>
                }
            </div>
            <Panel>
                <PanelBody>
                    <ReactTable data={configurationData} columns={_tableColumns} filterable={true} />
                </PanelBody>
            </Panel>
            <div className="row">
                <Modal isOpen={isConfigurationModalVisible}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <ModalHeader toggle={() => { setIsConfigurationModalVisible(!isConfigurationModalVisible) }}>
                            {t( "SERVICE_TYPE_INFORMATION")}
                        </ModalHeader>
                        <ModalBody>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("SERVICE_TYPE_NAME")}</b></label>
                                <div className="col-md-8">
                                    <input type="text"
                                        ref={register({ required: t("PLEASE_ENTER_NAME") })}
                                        defaultValue={configuration.name}
                                        name="name"
                                        autoComplete="off"
                                        className="form-control" />
                                    {errors.name && <div className="invalid-feedback">{t(getValidationMessage(errors.name))}</div>}
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("DESCRIPTION")}</b></label>
                                <div className="col-md-8">
                                    <textarea
                                        className="form-control"
                                        autoComplete="off"
                                        rows="2"
                                        name="description"
                                        ref={register({ required: t("PLEASE_ENTER_DESCRIPTION") })}
                                        defaultValue={configuration.description} />
                                    {errors.description && <div className="invalid-feedback">{t(getValidationMessage(errors.description))}</div>}
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("VISIT_REQUIRED")}</b></label>
                                <div className="col-md-8">
                                    <div className="switcher">
                                        <input type="checkbox" name="isVisitRequired" id="isVisitRequired"
                                            onChange={(e) => {
                                                setIsVisitRequired(e.target.checked);
                                            }}
                                            value={true}
                                            checked={isVisitRequired}
                                            ref={register} />
                                        <label htmlFor="isVisitRequired"></label>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {
                                checkIfPermissionExist(_accessRight, true) &&
                                <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                            }

                            <button type="button" className="btn btn-danger" onClick={() => { setIsConfigurationModalVisible(!isConfigurationModalVisible); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </div>
    );
}

export default ManageServiceType;