import { WebUrl, AccessRight } from "./../../util/Constant";

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const Menu = [
    {
        path: WebUrl._URL_DASHBOARD, icon: 'fa fa-th', title: 'DASHBOARD', isDummy: false,
    },
    {
        path: WebUrl._URL_SYSTEM_SETTINGS, icon: 'fa fa-wrench', title: 'SYSTEM_SETTINGS', isDummy: false, permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING
    },
    {
        path: '/admin/account', icon: 'fa fa-user', title: 'ACCOUNT', isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_ROLE, title: 'ROLES', permission: AccessRight._ACCESS_LEVEL_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_ADMIN, title: 'ADMIN', permission: AccessRight._ADMIN_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_USER, title: 'USER', permission: AccessRight._USER_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_MANAGER, title: 'MANAGER', permission: AccessRight._MANAGER_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_SALES_REP, title: 'SALES_REP', permission: AccessRight._SALESREP_ACCOUNT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/admin/setting', icon: 'fa fa-cog', title: 'COMPANY_SETTING', isDummy: true,
        children: [
            { path: WebUrl._URL_SYSTEM_MESSAGE, title: 'ANNOUNCEMENT', permission: AccessRight._ANNOUNCEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_CHECKLIST, title: 'CHECKLIST', permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_SERVICE_TYPE, title: 'SERVICE_TYPE', permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_ATTACHMENT_TYPE, title: 'ATTACHMENT_TYPE', permission: AccessRight._SYSTEM_SETTING_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING }
        ]
    },
    {
        path: '/admin/product', icon: 'fa fa-users', title: 'CUSTOMER', isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_CUSTOMER, title: 'CUSTOMER', permission: AccessRight._CUSTOMER_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_APPOINTMENT, title: 'APPOINTMENT', permission: AccessRight._APPOINTMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_LEAD, title: 'LEAD', permission: AccessRight._LEAD_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: '/admin/customer', icon: 'fa fa-shopping-basket', title: 'PRODUCT', isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_PRODUCT_CATEGORY, title: 'PRODUCT_CATEGORY', permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_PRODUCT_BROCHURE, title: 'PRODUCT_BROCHURE', permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_PRODUCT, title: 'PRODUCT', permission: AccessRight._PRODUCT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
        ]
    },
    {
        path: WebUrl._URL_MANAGE_TASK, icon: 'fa fa-list-alt', title: 'TASK', isDummy: false, permission: AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING
    },
    {
        path: '/admin/report', icon: 'fa fa-clipboard', title: 'REPORT', isDummy: true,
        children: [
            { path: WebUrl._URL_MANAGE_KPI_REPORT, title: 'KPI_REPORT', permission: AccessRight._SALES_KPI_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_TASK_HISTORY_REPORT, title: 'TASK_HISTORY', permission: AccessRight._EMP_WORK_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_VISIT_HISTORY_REPORT, title: 'VISIT_HISTORY', permission: AccessRight._VISIT_HISTORY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_VISIT_SUMMARY_REPORT, title: 'VISIT_SUMMARY', permission: AccessRight._VISIT_HISTORY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_SALES_ORDER_REPORT, title: 'SALES_ORDER', permission: AccessRight._SALES_ORDER_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MARKET_SURVEY_REPORT, title: 'MARKET_SURVEY', permission: AccessRight._MARKET_SURVEY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_CUSTOMER_VISIT_SUMMARY_REPORT, title: 'CUSTOMER_VISIT_SUMMARY', permission: AccessRight._CUSTOMER_VISIT_SUMMARY_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING },
            { path: WebUrl._URL_MANAGE_LEAD_SUMMARY_REPORT, title: 'LEAD_SUMMARY', permission: AccessRight._LEAD_REPORT_CONTROL_PERMISSION + AccessRight._READ_PERMISSION_STRING }
        ]
    },
    {
        path: WebUrl._URL_OPERATION_LOG, icon: 'fa fa-wrench', title: 'OPERATION_LOG', isDummy: false, permission: AccessRight._OPERATION_LOG_PERMISSION + AccessRight._READ_PERMISSION_STRING
    },
]

export default Menu;