import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, DataName } from "../../util/Constant";
import { stringIsNullOrEmpty, getValidationMessage, arrayGroupByKey } from "../../util/Util";
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import classnames from 'classnames';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const MarketSurveyReport = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [customerOption, setCustomerOption] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [postData, setPostData] = useState({});

    const [attachmentData, setAttachmentData] = useState([]);
    const [checklistData, setChecklistData] = useState([]);
    const [shareHistory, setShareHistory] = useState([]);
    const [marketSurveyData, setMarketSurveyData] = useState([]);
    const [communicationNote, setCommunicationNote] = useState();
    var _dispatch = useDispatch();
    const [visitModalVisible, setVisitModalVisible] = useState(false);

    const _ATTACHMENT_TAB = "1";
    const _SHARE_TAB = "2";
    const _CHECKLIST_TAB = "3";
    const _SURVEY_TAB = "4";
    const _COMMUNICATION_NOTE_TAB = "5";
    const [visibleTab, setVisibleTab] = useState(_SHARE_TAB);

    let _tableColumns = [
        {
            Header: t("ACTION"),
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => {
                                loadVisit(row.original.id);
                            }}><span>{t("VIEW_DETAIL")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "SERVICER",
            accessor: "servicer",
            disableSortBy: true
        },
        {
            Header: "CUSTOMER",
            accessor: "customer",
            disableSortBy: true
        },
        {
            Header: "CHECK_IN_TIME",
            accessor: "checkInTime",
            disableSortBy: true
        },
        {
            Header: "CHECK_OUT_TIME",
            accessor: "checkOutTime",
            disableSortBy: true
        },
        {
            Header: "TOTAL_VISIT_TIME",
            accessor: "totalVisitTime",
            disableSortBy: true
        },
        {
            Header: t("IS_VALID"),
            Cell: ({ row }) => {
                return <span>{row.original.isValidHistory == 1 ? <span className="badge badge-secondary badge-green">{t("YES")}</span> : <span className="badge badge-secondary badge-danger">{t("NO")}</span>}</span>
            },
            minWidth: 100
        },
    ]

    let _checkListColumns = useMemo(() => [
        {
            Header: t("CHECKLIST"),
            accessor: "item"
        },
        {
            Header: t("STATUS"),
            Cell: ({ row }) => {
                return <span>{row.original.isChecked == 1 ? <i class="fas fa-check-circle fa-fw"></i> : <i class="fas fa-times-circle fa-fw"></i>}</span>
            },
            minWidth: 100
        },
    ], []);

    let _attachmentColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            Cell: ({ row }) => {
                if (stringIsNullOrEmpty(row.original.document)) {
                    return "<span> - </span>";
                }
                else {
                    return (<div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => {
                                    window.open(row.original.document);
                                }}><span>{t("VIEW_ATTACHMENT")}</span></DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>)
                }
            },
        },
        {
            Header: t("ATTACHMENT_TYPE"),
            accessor: "attachmentType"
        },
        {
            Header: t("DOCUMENT"),
            accessor: "documentName"
        },
    ], []);

    let _sharingColumns = useMemo(() => [
        {
            Header: t("CUSTOMER"),
            accessor: "customerName"
        },
        {
            Header: t("PRODUCT_NAME"),
            accessor: "productName"
        }
    ], []);

    let _marketSurveyColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            disableSortBy: true,
            Cell: ({ row }) => {
                if (stringIsNullOrEmpty(row.original.document)) {
                    return <span> - </span>;
                }
                else {
                    return (<button className="btn btn-default btn-sm" style={{ height: "auto" }} onClick={() => window.open(row.original.image)}>{t("VIEW")}</button>);
                }
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: t("PRODUCT_NAME"),
            accessor: "productName"
        },
        {
            Header: t("DISTRIBUTED_BY"),
            accessor: "distributedBy"
        },
        {
            Header: t("RETAIL_PRICE"),
            accessor: "retailPrice"
        },
        {
            Header: t("TRADE_PRICE"),
            accessor: "tradePrice"
        },
        {
            Header: t("TRADE_OFFER"),
            accessor: "tradeOffer"
        },
    ], []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function loadVisit(visitId) {
        var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._VISIT_HISTORY + "&id=" + visitId;

        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCommunicationNote(responseJson[ApiKey._API_DATA_KEY]["remark"])
            setAttachmentData(responseJson[ApiKey._API_DATA_KEY]["attachmentList"]);
            setChecklistData(responseJson[ApiKey._API_DATA_KEY]["checklist"]);
            setShareHistory(responseJson[ApiKey._API_DATA_KEY]["sharingList"]);
            setMarketSurveyData(responseJson[ApiKey._API_DATA_KEY]["marketSurveyList"])
        }

        setVisitModalVisible(responseJson[ApiKey._API_SUCCESS_KEY])
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'user' });
        register({ name: 'customer' });
        register({ name: 'endDate' }, { required: true });

        setValue('user', []);
        setValue('customer', '');
        setValue('startDate', moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_CUSTOMER);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var customerList = [];
                responseJson[ApiKey._API_DATA_KEY].map(customer => {
                    customerList.push({ label: customer.username, value: customer.id });
                });
                setCustomerOption(customerList);
            }

            responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?includeSelf=true&role=" + Role._ROLE_WITH_CUSTOMER);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var userListByRole = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "roleName");
                var grouppedUserList = [];

                for (var key in userListByRole) {
                    var userList = [];

                    userListByRole[key].map(user => {
                        userList.push({ label: user.userFullName, value: user.id });
                    });

                    grouppedUserList.push({
                        label: key,
                        value: key,
                        options: userList
                    })
                }

                setUserOption(grouppedUserList);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "User": data.user,
            "Customer": data.customer,
            "IsValidVisit": parseInt(data.isValidVisit),
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_VISIT_HISTORY_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} fileName={t("VISIT_HISTORY_REPORT")} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("VISIT_HISTORY_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CUSTOMER")}</label>
                                    <Select name="customer" options={customerOption} isClearable={true}
                                        placeholder={(customerOption.filter(option => option.value == selectedCustomer)[0] !== undefined) ? (customerOption.filter(option => option.value == selectedCustomer)[0].label) : ""}
                                        value={customerOption.filter(option => option.value == selectedCustomer)}
                                        onChange={(e) => {
                                            if (e) {
                                                setValue('customer', e.value);
                                                setSelectedCustomer(e.value);
                                            }
                                            else {
                                                setValue('customer', '');
                                                setSelectedCustomer('');
                                            }
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="startDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={startDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("startDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="endDate"
                                        timeFormat="HH:mm:ss"
                                        dateFormat="YYYY-MM-DD"
                                        closeOnSelect={true}
                                        value={endDate}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.format("YYYY-MM-DD HH:mm:ss"));
                                                setValue("endDate", e.format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("VISIT_VALIDITY")}</label>
                                    <select name="isValidVisit" ref={register} className="form-control">
                                        <option value={-1}>{t("ALL")}</option>
                                        <option value={1}>{t("VALID")}</option>
                                        <option value={0}>{t("INVALID")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("SERVICER")}</label>
                                    <DualListBox
                                        canFilter
                                        name="user"
                                        options={userOption}
                                        selected={selectedUser}
                                        onChange={(e) => {
                                            setValue('user', e);
                                            setSelectedUser(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
            <Modal isOpen={visitModalVisible} fade={true} size="lg">
                <ModalHeader toggle={() => { setVisitModalVisible(!visitModalVisible) }}>
                    {t("DETAIL")}
                </ModalHeader>
                <ModalBody>
                    <Nav className="mb-3" pills>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _SHARE_TAB })}
                                onClick={() => { setVisibleTab(_SHARE_TAB); }} >
                                <span className="d-sm-none">{t("SHARING_HISTORY")}</span>
                                <span className="d-sm-block d-none">{t("SHARING_HISTORY")}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _ATTACHMENT_TAB })}
                                onClick={() => { setVisibleTab(_ATTACHMENT_TAB); }} >
                                <span className="d-sm-none">{t("ATTACHMENT")}</span>
                                <span className="d-sm-block d-none">{t("ATTACHMENT")}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _CHECKLIST_TAB })}
                                onClick={() => { setVisibleTab(_CHECKLIST_TAB); }} >
                                <span className="d-sm-none">{t("CHECKLIST")}</span>
                                <span className="d-sm-block d-none">{t("CHECKLIST")}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _SURVEY_TAB })}
                                onClick={() => { setVisibleTab(_SURVEY_TAB); }} >
                                <span className="d-sm-none">{t("MARKET_SURVEY")}</span>
                                <span className="d-sm-block d-none">{t("MARKET_SURVEY")}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _COMMUNICATION_NOTE_TAB })}
                                onClick={() => { setVisibleTab(_COMMUNICATION_NOTE_TAB); }} >
                                <span className="d-sm-none">{t("COMMUNICATION_NOTE")}</span>
                                <span className="d-sm-block d-none">{t("COMMUNICATION_NOTE")}</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent className="p-15 rounded bg-white mb-4" activeTab={visibleTab}>
                        <TabPane tabId={_SHARE_TAB}>
                            <ReactTable data={shareHistory} columns={_sharingColumns} filterable={false} globalFilterable={false} />
                        </TabPane>
                        <TabPane tabId={_ATTACHMENT_TAB}>
                            <ReactTable data={attachmentData} columns={_attachmentColumns} filterable={false} globalFilterable={false} />
                        </TabPane>
                        <TabPane tabId={_CHECKLIST_TAB}>
                            <ReactTable data={checklistData} columns={_checkListColumns} filterable={false} globalFilterable={false} />
                        </TabPane>
                        <TabPane tabId={_SURVEY_TAB}>
                            <ReactTable data={marketSurveyData} columns={_marketSurveyColumns} filterable={false} globalFilterable={false} />
                        </TabPane>
                        <TabPane tabId={_COMMUNICATION_NOTE_TAB}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <textarea type="text" name="remark" rows="12"
                                        defaultValue={communicationNote || "-"}
                                        readOnly
                                        className="form-control m-b-5" />
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={() => { setVisitModalVisible(!visitModalVisible); }}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default MarketSurveyReport;