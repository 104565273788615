import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import NavigationButton from '../../components/custom/NavigationButton';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage, isObjectEmpty } from "../../util/Util";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ApiKey, ApiUrl, AccessRight, AlertTypes, DataName, Status, WebUrl, Role } from "../../util/Constant";
import LeadAppointment from '../../components/custom/LeadAppointment';
import CompleteLead from '../../components/custom/CompleteLead';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import DateTime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import Autocomplete from 'react-google-autocomplete';
import Dropzone from 'react-dropzone';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const LeadDetail = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _location = useLocation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, unregister, triggerValidation } = useForm();
    const [address, setAddress] = useState("");
    const [addressAlternative, setAddressAlternative] = useState("");
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');

    const [salesRepOption, setSalesRepOption] = useState([]);
    const [selectedSalesRep, setSelectedSalesRep] = useState([]);

    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [appointmentModalVisible, setAppointmentModalVisible] = useState(false);
    const [isAppointmentMade, setIsAppointmentMade] = useState(false);
    const [isRequestComplete, setIsRequestComplete] = useState(false);
    const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [leadId, setLeadId] = useState("")
    const [appointedTime, setAppointedTime] = useState("")
    const [contactName, setContactName] = useState("")
    const [customerName, setCustomerName] = useState("");
    const [leadRemark, setLeadRemark] = useState("");
    const [cities, setCities] = useState([]);
    const [rawCityOption, setRawCityOption] = useState([]);
    const [cityId, setCityId] = useState('');
    const _userData = useSelector(state => state.authState.userData);
    const _writePermission = checkIfPermissionExist(AccessRight._LEAD_CONTROL_PERMISSION, true);
    const [attachmentToRemoved, setAttachmentToRemoved] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const [attachmentFile, setAttachmentFile] = useState([]);
    const [isNewImage, setIsNewImage] = useState(false);
    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState("");

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        try {
            _dispatch(setBusy());

            register({ name: 'address' });
            register({ name: 'userId' }, { required: true });
            register({ name: 'cityId' }, { required: true });
            register({ name: 'appointedTime' }, { required: true });
            init();
            initSelectionData();
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function initSelectionData() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_APPOINTMENT_RELATED_DATA);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var userList = responseJson[ApiKey._API_DATA_KEY]["userList"];
            var cityList = responseJson[ApiKey._API_DATA_KEY]["cityList"];

            const userData = [];
            userList.forEach(function (user) {
                userData.push({ label: user.username, value: user.id });
            });

            setSalesRepOption(userData);

            const cityData = [];
            const fullCityData = [];

            for (const [key, value] of Object.entries(cityList)) {
                var subList = [];

                value.forEach(function (city) {
                    subList.push({ label: city.name, value: city.id });
                    fullCityData.push({ label: city.name, value: city.id });
                });
                cityData.push({ label: key, options: subList });
            }

            setCities(cityData);
            setRawCityOption(fullCityData);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_DATA_BY_ID + "?type=" + DataName._LEAD_ASSIGNMENT + "&id=" + _location.state["id"];

            var responseJson = await ApiEngine.get(apiUrl);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setLeadId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setContactName(responseJson[ApiKey._API_DATA_KEY]["contactName"]);
                setSelectedCustomer(responseJson[ApiKey._API_DATA_KEY]["customerId"])
                setCustomerName(responseJson[ApiKey._API_DATA_KEY]["customerName"]);
                setAddress(responseJson[ApiKey._API_DATA_KEY]["address"]);
                setAddressAlternative(responseJson[ApiKey._API_DATA_KEY]["addressAlternative"]);
                setValue("address", responseJson[ApiKey._API_DATA_KEY]["address"]);
                setLatitude(responseJson[ApiKey._API_DATA_KEY]["latitude"]);
                setLongitude(responseJson[ApiKey._API_DATA_KEY]["longitude"]);
                setPhoneNumber(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
                setLeadRemark(responseJson[ApiKey._API_DATA_KEY]["leadRemark"]);
                setAppointedTime(responseJson[ApiKey._API_DATA_KEY]["serviceTime"]);
                setIsAppointmentMade(responseJson[ApiKey._API_DATA_KEY]["hasAppointment"]);
                setIsRequestComplete(responseJson[ApiKey._API_DATA_KEY]["isComplete"]);
                setSelectedSalesRep(responseJson[ApiKey._API_DATA_KEY]["userId"]);
                setCityId(responseJson[ApiKey._API_DATA_KEY]["cityId"]);
                setValue("userId", responseJson[ApiKey._API_DATA_KEY]["userId"]);
                setValue("cityId", responseJson[ApiKey._API_DATA_KEY]["cityId"]);
                setValue("appointedTime", responseJson[ApiKey._API_DATA_KEY]["serviceTime"]);
                setImage(responseJson[ApiKey._API_DATA_KEY]["leadImage"]);

                var newAttachmentList = [];
                responseJson[ApiKey._API_DATA_KEY]["attachments"].map(function (file) {
                    newAttachmentList.push({ id: file.id, data: file.image, name: file.fileName });
                });

                setAttachment([...newAttachmentList]);
                setAttachmentFile([...newAttachmentList]);
            }
        }
        else {
            setValue("address", "");
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "attachmentToRemoved": attachmentToRemoved
        };

        if (!stringIsNullOrEmpty(appointedTime)) {
            params["serviceTime"] = appointedTime + ":00";
        }

        if (!stringIsNullOrEmpty(leadId)) {
            params["id"] = leadId;
        }
        else {
            params["status"] = true;
        }

        attachmentFile.map(function (file, i) {
            params["attachment" + i] = file;
        })

        if (selectedCustomer) {
            params["customerId"] = selectedCustomer;
        }

        if (isNewImage && !stringIsNullOrEmpty(imageFile)) {
            params["image"] = imageFile;
        }

        if (stringIsNullOrEmpty(image)) {
            params["image"] = "";
        }

        Object.keys(data).map((key, value) => {
            params[key] = data[key];
        });

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_LEAD, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => responseJson[ApiKey._API_SUCCESS_KEY] && _location.state == undefined && navigateTo(_history, WebUrl._URL_MANAGE_LEAD)
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const saveAsCustomer = async () => {
        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_CUSTOMER_FROM_LEAD + "?leadId=" + leadId);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const onInputCustomerAddress = (address) => {
        if (stringIsNullOrEmpty(address)) {
            setLatitude('');
            setLongitude('');
            setValue('address', '');
            setAddress('');
        }
        else {
            setValue('address', address);
            setAddress(address);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setIsNewImage(true);
                setImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author: Yong Sheng Chuan
    /// </summary>
    const selectCustomerAddress = (place) => {
        if (place.geometry !== undefined) {
            let fullAddress = document.getElementById("address").value;

            setLongitude(place.geometry.location.lng());
            setLatitude(place.geometry.location.lat());

            if (!stringIsNullOrEmpty(fullAddress)) {
                setValue('address', fullAddress);
            }
            else {
                setValue('address', '');
            }

            setAddress(fullAddress);

            setValue('latitude', place.geometry.location.lat());
            setValue('longitude', place.geometry.location.lng());
        }
        else {
            setValue('latitude', "");
            setValue('longitude', "");
            setLongitude("");
            setLatitude("");
        }
    };

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const fileDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            attachmentFile.push(file);
            setAttachmentFile([...attachmentFile]);

            const reader = new FileReader();
            reader.onloadend = () => {
                attachment.push({ id: undefined, data: reader.result });
                setAttachment([...attachment]);
            }

            reader.readAsDataURL(file);
        }
    }

    const reopenLead = async (leadId) => {
        var responseJson = await ApiEngine.post(ApiUrl._API_REOPEN_LEAD + "?leadId=" + leadId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    return (
        <div>
            <h1 className="page-header">&nbsp;<NavigationButton history={_history} /></h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader onClick={((_writePermission || selectedSalesRep == _userData.userId) && !isRequestComplete) ? () => { handleSubmit(submitForm)() } : undefined}>
                            <span>{leadId ? t("EDIT_LEAD") : t("ADD_NEW_LEAD")}</span>
                            <div style={{ float: "right", marginRight: "15px" }}>
                                {leadId && isRequestComplete && <span className="badge badge-secondary badge-green">{t("COMPLETE")}</span>}
                                {leadId && isAppointmentMade && !isRequestComplete && <span className="badge badge-secondary badge-info">{t("APPOINTED")}</span>}
                                {leadId && !isAppointmentMade && !isRequestComplete && <span className="badge badge-secondary badge-warning">{t("UNAPPOINTED")}</span>}
                            </div>
                        </PanelHeader>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <PanelBody>
                                    <div className="row m-b-15">
                                        <div className="col-lg-12">
                                            {!stringIsNullOrEmpty(leadId) && _userData.userId == selectedSalesRep && stringIsNullOrEmpty(selectedCustomer) && <button type="button" className="btn btn-info" onClick={() => {
                                                saveAsCustomer();
                                            }} style={{ marginRight: "15px" }}>{t('SAVE_TO_CUSTOMER')}</button>}
                                            {_userData.userId == selectedSalesRep && (!isRequestComplete || isAppointmentMade) && <button type="button" className="btn btn-info" style={{ marginRight: "15px" }} onClick={() => {
                                                setAppointmentModalVisible(true);
                                            }}>{t(isAppointmentMade ? 'VIEW_APPOINTMENT' : 'CREATE_APPOINTMENT')}</button>}
                                            {_userData.userId == selectedSalesRep && !isRequestComplete && <button type="button" className="btn btn-success" onClick={() => {
                                                setIsCompleteModalVisible(true);
                                            }}>{t('COMPLETE_LEAD')}</button>}
                                            {_userData.isAdmin && isRequestComplete && <button type="button" className="btn btn-success" onClick={() => {
                                                reopenLead(leadId);
                                            }}>{t('REOPEN')}</button>}
                                        </div>
                                    </div>
                                    <div className="row m-b-15">
                                        <div className="col-lg-3" style={{ display: "flex", alignItems: "center" }}>
                                            <label><b>{t("LEAD_IMAGE")}</b></label><br />
                                        </div>
                                        <div className="col-lg-4">
                                            <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", right: "5%", top: "2%" }}
                                                onClick={() => {
                                                    setImage("");
                                                    setImageFile("");
                                                }}><i className="fa fa-times"></i></a>
                                            <Dropzone accept={'.png,.jpg,.jpeg'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <section>
                                                        <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            {!stringIsNullOrEmpty(image) && <aside className="thumbsContainer">
                                                                <div className="thumb">
                                                                    <div className="thumbInner">
                                                                        <img
                                                                            src={image}
                                                                            style={{ maxHeight: "200px", width: "100%" }}
                                                                            className="dropzone-img"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </aside>}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    {(_writePermission || attachment.length != 0) && <div className="row m-b-5">
                                        <div className="col-lg-3" style={{ display: "flex", alignItems: "center" }}>
                                            <label><b>{t("ATTACHMENT")}</b></label><br />
                                        </div>

                                        {!isRequestComplete && <div className="col-lg-6" style={{ display: "flex", alignItems: "center" }}>
                                            <div className="form-group" style={{ width: "100%" }}>

                                                <Dropzone accept={'.png,.jpg,.jpeg,.pdf'} onDrop={acceptedFiles => fileDrop(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div className="dropzone" style={{ textAlign: "center", height: "40px" }} {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <b style={{ color: "grey", margin: "10px" }}>{t("UPLOAD_BROCHURE")}</b>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>}
                                    </div>
                                    }
                                    {attachmentFile.length != 0 &&
                                        <>
                                            <div className="row m-b-5">
                                                <div className="col-lg-9">
                                                    <table style={{ width: "100%", tableLayout: "fixed" }} className="table table-bordered">
                                                        <thead style={{ textAlign: "center" }}>
                                                            <tr>
                                                                <th style={{ borderBottom: "1px solid #ddd", textAlign: "center", width: "15%" }}>{t("ACTION")}</th>
                                                                <th style={{ borderBottom: "1px solid #ddd", textAlign: "left", width: "70%" }}>{t("FILE_NAME")}</th>
                                                                <th style={{ borderBottom: "1px solid #ddd", textAlign: "center", width: "15%" }}>{t("VIEW")}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                attachmentFile.map((item, i) => {
                                                                    return (<tr>
                                                                        <td style={{ textAlign: "center" }}><i className="fas fa-times-circle" style={{ fontSize: "x-large", cursor: "pointer", color: "red" }} onClick={() => {
                                                                            if (item.id !== undefined) {
                                                                                attachmentToRemoved.push(item.id);
                                                                                setAttachmentToRemoved([...attachmentToRemoved]);
                                                                            }
                                                                            attachment.splice(i, 1);
                                                                            attachmentFile.splice(i, 1);

                                                                            setAttachment([...attachment]);
                                                                            setAttachmentFile([...attachmentFile]);
                                                                        }}></i></td>
                                                                        <td style={{ textAlign: "left" }}>{item.name}</td>
                                                                        <td style={{ textAlign: "center" }}><i className="fas fa-eye" style={{ fontSize: "x-large", cursor: "pointer", color: "orange" }} onClick={() => {
                                                                            let pdfWindow = window.open("")
                                                                            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + attachment[i].data + "'></iframe>");
                                                                        }}></i></td>
                                                                    </tr>);
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <hr />


                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("SHOP_NAME")}</b></label>
                                                <input type="text" name="customerName" ref={register({
                                                    required: true
                                                })} defaultValue={customerName} className="form-control m-b-5" />
                                                {errors.customerName && <div className="invalid-feedback">{t(getValidationMessage(errors.customerName))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("SHOP_OWNER")}</b></label>
                                                <input type="text" name="contactName" ref={register({
                                                    required: true
                                                })} defaultValue={contactName} className="form-control m-b-5" />
                                                {errors.contactName && <div className="invalid-feedback">{t(getValidationMessage(errors.contactName))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("PHONE")}</b></label>
                                                <input type="text" name="phoneNumber" ref={register({
                                                    required: true
                                                })} defaultValue={phoneNumber} className="form-control m-b-5" />
                                                {errors.phoneNumber && <div className="invalid-feedback">{t(getValidationMessage(errors.phoneNumber))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("APPOINTED_TIME")}</b></label>
                                                <DateTime
                                                    value={appointedTime}
                                                    dateFormat="YYYY-MM-DD"
                                                    closeOnSelect={true}
                                                    timeFormat={"HH:mm"}
                                                    inputProps={{
                                                        disabled: !_writePermission,
                                                        className: 'form-control',
                                                        style: { cursor: 'pointer', background: _writePermission ? "transparent" : "#e9ecef" }
                                                    }}
                                                    isValidDate={(e) => {
                                                        return e.isSameOrAfter(moment().format("YYYY-MM-DD"))
                                                    }}
                                                    onChange={(e) => {
                                                        if (e instanceof moment) {
                                                            setAppointedTime(e.format("YYYY-MM-DD HH:mm"));
                                                            setValue("appointedTime", e.format("YYYY-MM-DD HH:mm"));
                                                        }
                                                        else {
                                                            setAppointedTime("");
                                                            setValue("appointedTime", "");
                                                        }
                                                    }} />
                                                {errors.appointedTime && <div className="invalid-feedback">{t(getValidationMessage(errors.appointedTime))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("SALES_REP")}</b></label>
                                                <Select name="userId" options={salesRepOption} isDisabled={!_writePermission && _userData.userId == selectedSalesRep}
                                                    placeholder={(salesRepOption.filter(option => option.value == selectedSalesRep)[0] !== undefined) ? (salesRepOption.filter(option => option.value == selectedSalesRep)[0].label) : ""}
                                                    value={salesRepOption.filter(option => option.value == selectedSalesRep)}
                                                    onChange={(e) => {
                                                        setSelectedSalesRep(e.value);
                                                        setValue("userId", e.value)
                                                    }} />
                                                {errors.userId && <div className="invalid-feedback">{t(getValidationMessage(errors.userId))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <label><b>{t("CITY")}</b></label>
                                                <Select name="cityId" options={cities}
                                                    placeholder={(rawCityOption.filter(option => option.value == cityId)[0] !== undefined) ? (rawCityOption.filter(option => option.value == cityId)[0].label) : ""}
                                                    value={rawCityOption.filter(option => option.value == cityId)}
                                                    isClearable={false}
                                                    onChange={(e) => {
                                                        if (e != null) {
                                                            setCityId(e.value);
                                                            setValue("cityId", e.value);
                                                        }
                                                        else {
                                                            setCityId("");
                                                            setValue("cityId", "");
                                                        }
                                                    }} />
                                                {errors.cityId && <div className="invalid-feedback">{t(getValidationMessage(errors.cityId))}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label><b>{t("ADDRESS")}</b></label>
                                                <Autocomplete
                                                    className="form-control m-b-5"
                                                    id="address"
                                                    onInput={(e) => { onInputCustomerAddress(e.target.value); }}
                                                    onPlaceSelected={(place) => { selectCustomerAddress(place); }}
                                                    types={['address']}
                                                    defaultValue={address}
                                                    options={{
                                                        types: ["address"],
                                                        componentRestrictions: { country: "my" },
                                                    }}
                                                    name="address" />
                                                {errors.address && <div className="invalid-feedback">{t(getValidationMessage(errors.address))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label><b>{t("ALTERNATE_ADDRESS")}</b></label>
                                                <input type="text"
                                                    name="addressAlternative"
                                                    defaultValue={addressAlternative}
                                                    ref={register}
                                                    className="form-control m-b-5"
                                                    autoComplete="off" />
                                                {errors.addressAlternative && <div className="invalid-feedback">{t(getValidationMessage(errors.addressAlternative))}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label><b>{t("LATITUDE")}</b></label>
                                            <input type="text"
                                                defaultValue={latitude}
                                                ref={register}
                                                className="form-control m-b-5"
                                                name="latitude"
                                                autoComplete="off" />
                                            {errors.latitude && <div className="invalid-feedback">{t(getValidationMessage(errors.latitude))}</div>}
                                        </div>
                                        <div className="col-md-6">
                                            <label><b>{t("LONGITUDE")}</b></label>
                                            <input type="text"
                                                name="longitude"
                                                defaultValue={longitude}
                                                ref={register}
                                                className="form-control m-b-5"
                                                autoComplete="off" />
                                            {errors.longitude && <div className="invalid-feedback">{t(getValidationMessage(errors.longitude))}</div>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label><b>{t("REMARK")}</b></label>
                                                <textarea type="text" name="leadRemark" ref={register} defaultValue={leadRemark} className="form-control m-b-5" />
                                                {errors.leadRemark && <div className="invalid-feedback">{t(getValidationMessage(errors.leadRemark))}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </PanelBody>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
            <LeadAppointment isModalVisible={appointmentModalVisible} leadId={leadId} onFinish={(e) => {
                setAppointmentModalVisible(false);
                if (e) {
                    init();
                }
            }} />
            <CompleteLead isModalVisible={isCompleteModalVisible} leadId={leadId} onFinish={(e) => {
                setIsCompleteModalVisible(false);
                if (e) {
                    init();
                }
            }} />
        </div>
    )
}

export default withRouter(LeadDetail);