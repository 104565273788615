import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from '../../components/custom/ReactTable';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { navigateTo, createMultiPartFormBody, getValidationMessage, stringIsNullOrEmpty } from "../../util/Util";
import { ApiKey, WebUrl, ApiUrl, AccessRight, AlertTypes, DataName, ViewMode } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showResponseMessage, showMessage } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine.js';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
import Dropzone from 'react-dropzone';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const ManageTask = props => {
    const { t } = useTranslation();
    let _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, reset } = useForm();
    const {  handleSubmit:handleFilterSubmit, register:registerFilter } = useForm();
    const [taskData, setTaskData] = useState([]);
    const [selectedTaskId, setSelectedTaskId] = useState();
    const [isEndOfWork, setIsEndOfWork] = useState();
    const [remark, setRemark] = useState();
    const [workHistory, setWorkHistory] = useState();
    const [workStatus, setWorkStatus] = useState([]);
    const [isWorkHistoryModalVisible, setIsWorkHistoryModalVisible] = useState(false);
    const [endExistingTask, setEndExistingTask] = useState(false);
    const [isWorkLogModalVisible, setIsWorkLogModalVisible] = useState(false);
    const _language = useSelector(state => state.appState.language);
    const _userData = useSelector(state => state.authState.userData);
    const [supportingDocumentFile, setSupportingDocumentFile] = useState("");
    const [supportingDocumentFileName, setSupportingDocumentFileName] = useState("");
    const [isNewDocument, setIsNewDocument] = useState(false);

    const _WORK_HISTORY_TAB = "1";
    const _WORKING_STATUS_TAB = "2";
    const [visibleTab, setVisibleTab] = useState(_WORK_HISTORY_TAB);

    let _tableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                return (<div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { navigateTo(_history, WebUrl._URL_TASK_DETAIL, row.original) }}><span>{t(checkIfPermissionExist(AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION, true) && !row.original.taskStatus && (_userData.userId == row.original.createdById || _userData.isAdmin) ? "EDIT" : "VIEW")}</span></DropdownItem>
                            {<DropdownItem onClick={() => ViewTaskProgress(row.original.id)}><span>{t("VIEW_TASK_PROGRESS")}</span></DropdownItem>}
                            {row.original.assignedUser.filter(i => i == _userData.userId).length != 0 && row.original.onGoingProgressModel == null &&
                                < DropdownItem onClick={() => startTask(row.original)}><span>{t("START_WORK")}</span></DropdownItem>
                            }
                            {row.original.assignedUser.filter(i => i == _userData.userId).length != 0 && row.original.onGoingProgressModel != null &&
                                <DropdownItem onClick={() => endTask(row.original)}><span>{t("END_WORK")}</span></DropdownItem>}
                            {checkIfPermissionExist(AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION, true) && !row.original.taskStatus && (_userData.userId == row.original.createdById || _userData.isAdmin) && <DropdownItem onClick={() => deleteTask(row.original.id)}><span className="text-danger">{t("DELETE")}</span></DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>);
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "NAME",
            accessor: "name",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 100
        },
        {
            Header: t("DESCRIPTION"),
            accessor: "description",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 200
        },
        {
            Header: t("DEADLINE"),
            accessor: "deadline",
            disableSortBy: true,
            disableFilters: true,
            minWidth: 200
        },
        {
            Header: t("ASSIGNEE"),
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return <span>{row.original.assignedUserDetail.map(i => (<span>{i.username}<br /></span>))}</span>
            },
            minWidth: 200
        },
        {
            Header: t("IS_COMPLETE"),
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return <span>{row.original.taskStatus ? <span className="badge badge-secondary badge-green">{t("COMPLETED")}</span> : <span className="badge badge-secondary badge-warning">{t("IN_PROGRESS")}</span>}</span>
            },
            minWidth: 200
        },
        {
            Header: t("OVERDUE"),
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return <span>{!row.original.isOverdue || row.original.taskStatus ? <span className="badge badge-secondary badge-green">{t("NO")}</span> : <span className="badge badge-secondary badge-danger">{t("YES")}</span>}</span>
            },
        },
    ], [_language]);

    let _workHistoryTableColumns = useMemo(() => [
        {
            Header: t("ACTION"),
            id: "action",
            Cell: ({ row }) => {
                if (!stringIsNullOrEmpty(row.original.attachment) || !stringIsNullOrEmpty(row.original.endAttachment)) {
                    return (<div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                            <DropdownMenu>
                                {!stringIsNullOrEmpty(row.original.attachment) && <DropdownItem onClick={() => { window.open(row.original.attachment); }}><span>{t("VIEW_ATTACHMENT")}</span></DropdownItem>}
                                {!stringIsNullOrEmpty(row.original.endAttachment) && <DropdownItem onClick={() => { window.open(row.original.endAttachment); }}><span>{t("VIEW_END_TASK_ATTACHMENT")}</span></DropdownItem>}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>);
                }
                else {
                    return " - ";
                }
            },
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        },
        {
            Header: "USER",
            accessor: "user",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("TASK"),
            accessor: "task",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("START_TIME"),
            accessor: "startTime",
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: t("END_TIME"),
            accessor: "endTime",
            disableSortBy: true,
            disableFilters: true
        },

        {
            Header: t("TOTAL_WORKING_TIME"),
            accessor: "totalWorkingTime",
            disableSortBy: true,
            disableFilters: true
        },
    ], [_language]);

    let _workStatusTableColumns = useMemo(() => [
        {
            Header: "USER",
            accessor: "username",
            disableSortBy: true,
            disableFilters: true
        },
        {
            Header: t("START_TIME"),
            accessor: "startTime",
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: t("STATUS"),
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => {
                return <span>{row.original.status ? <span className="badge badge-secondary badge-green">&nbsp;</span> : <span className="badge badge-secondary bg-default">&nbsp;</span>}</span>
            },
        },
    ], [_language]);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'attachment' });
        handleFilterSubmit(onSubmit)();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function startTask(task) {
        reset();
        setIsEndOfWork(false);
        setSelectedTaskId(task.id);
        setIsWorkLogModalVisible(true);
        setEndExistingTask(false);

        setRemark("");
        setSupportingDocumentFileName("");
        setSupportingDocumentFile("");
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function endTask(task) {
        reset();
        setIsEndOfWork(true);

        setSelectedTaskId(task.id);
        setIsWorkLogModalVisible(true);
        setEndExistingTask(false);

        setRemark("");
        setSupportingDocumentFileName("");
        setSupportingDocumentFile("");
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function deleteTask(taskId) {
        _dispatch(showMessage({
            type: AlertTypes._WARNING,
            content: t("DELETE_SETTING_CONFIRMATION"),
            onConfirm: () => performDeleteTask(taskId),
            showCancel: true
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function performDeleteTask(taskId) {
        let params = {
            "status": false,
            "id": taskId
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE + "?type=" + DataName._TASK, createMultiPartFormBody(params));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            handleFilterSubmit(onSubmit)();
        }

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function ViewTaskProgress(taskId) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_TASK_WORK_HISTORY + "?taskId=" + taskId);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setWorkHistory(responseJson[ApiKey._API_DATA_KEY]);
            setIsWorkHistoryModalVisible(true);

            setSelectedTaskId(taskId);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setSupportingDocumentFile(file);
            setSupportingDocumentFileName(file.name);
            setIsNewDocument(true);
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitWorkHistory = async (data, e) => {
        let params = {
            isEndOfWork: data.isEndOfWork,
            taskId: data.taskId,
            userId: _userData.userId,
            remark: data.remark,
        };

        if (endExistingTask) {
            params["endExistingTask"] = true;
        }

        if (isNewDocument && !stringIsNullOrEmpty(supportingDocumentFile)) {
            params["document"] = supportingDocumentFile;
        }

        if (stringIsNullOrEmpty(supportingDocumentFile)) {
            params["document"] = "";
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_WORK_PROGRESS, createMultiPartFormBody(params));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
        }));

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setIsWorkLogModalVisible(false);
            handleFilterSubmit(onSubmit)();
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const onSubmit = async (data, e) => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ASSIGNED_TASK + "?viewMode=" + data.viewMode);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTaskData(responseJson[ApiKey._API_DATA_KEY]);
        }
    };

    useEffect(() => {
        if (workHistory && isWorkHistoryModalVisible && selectedTaskId) {
            const selectedTask = taskData.find(i => i.id === selectedTaskId);
            let userWorkingStatus = [...selectedTask.assignedUserDetail];

            userWorkingStatus.forEach(data => {
                data.status = workHistory.some(i => stringIsNullOrEmpty(i.endTime));
                data.startTime = workHistory.find(i => stringIsNullOrEmpty(i.endTime))?.startTime || "-";
            })

            setWorkStatus(userWorkingStatus);
        }

    }, [taskData, workHistory, isWorkHistoryModalVisible, selectedTaskId])

    useEffect(() => {
        if (!isWorkHistoryModalVisible) {
            setWorkHistory(undefined);
        }
    }, [isWorkHistoryModalVisible])

    console.log(workHistory)
    return (
        <div>
            <div className="header-wrapper m-0 flex-lg-row flex-column align-items-start">
                <h1 className="page-header">{t("TASK")}</h1>
                {
                    checkIfPermissionExist(AccessRight._TASK_MANAGEMENT_CONTROL_PERMISSION, true) &&
                    <button type="button" onClick={() => { navigateTo(_history, WebUrl._URL_TASK_DETAIL) }} className="btn btn-primary">{t('ADD_TASK')}</button>
                }
            </div>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleFilterSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("TASK_TYPE")}</label>
                                    <select class="form-control" name="viewMode" ref={registerFilter} defaultValue={_location.state?.ViewMode}>
                                        <option value="">{t("ALL")}</option>
                                        <option value={ViewMode._VIEW_MODE_CREATED}>{t("CREATED_TASK")}</option>
                                        <option value={ViewMode._VIEW_MODE_ASSIGNED}>{t("ASSIGNED_TASK")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group form-button">
                                    <button type="submit" class="btn btn-primary">{t('APPLY_FILTER')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    <ReactTable data={taskData} columns={_tableColumns} filterable={true} />
                </PanelBody>
            </Panel>
            <Modal isOpen={isWorkHistoryModalVisible} fade={true} size="lg">
                <ModalHeader toggle={() => { setIsWorkHistoryModalVisible(!isWorkHistoryModalVisible) }}>
                    {t("WORK_DETAIL")}
                </ModalHeader>
                <ModalBody>
                    <Nav className="mb-3" pills>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _WORK_HISTORY_TAB })}
                                onClick={() => { setVisibleTab(_WORK_HISTORY_TAB); }} >
                                <span className="d-sm-none">{t("WORK_HISTORY")}</span>
                                <span className="d-sm-block d-none">{t("WORK_HISTORY")}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: visibleTab == _WORKING_STATUS_TAB })}
                                onClick={() => { setVisibleTab(_WORKING_STATUS_TAB); }} >
                                <span className="d-sm-none">{t("WORKING_STATUS")}</span>
                                <span className="d-sm-block d-none">{t("WORKING_STATUS")}</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent className="p-15 rounded bg-white mb-4" activeTab={visibleTab}>
                        <TabPane tabId={_WORK_HISTORY_TAB}>
                            <div className="form-group row m-b-15">
                                <div className="col-md-12">
                                    <ReactTable data={workHistory || []} columns={_workHistoryTableColumns} filterable={true} />
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId={_WORKING_STATUS_TAB}>
                            <div className="form-group row m-b-15">
                                <div className="col-md-12">
                                    <ReactTable data={workStatus} columns={_workStatusTableColumns} filterable={true} />
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" onClick={() => { setIsWorkHistoryModalVisible(!isWorkHistoryModalVisible); }}>{t('BTN_CLOSE')}</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isWorkLogModalVisible} fade={true}>
                <form onSubmit={handleSubmit(submitWorkHistory)}>
                    <ModalHeader toggle={() => { setIsWorkLogModalVisible(!isWorkLogModalVisible) }}>
                        {t("DETAIL")}
                    </ModalHeader>
                    <ModalBody>
                        <input type="hidden" name="isEndOfWork" ref={register} value={isEndOfWork} />
                        <input type="hidden" name="taskId" ref={register} value={selectedTaskId } />
                        <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("REMARK")}</b></label>
                            <div className="col-md-8">
                                <textarea type="text"
                                    ref={register}
                                    name="remark"
                                    defaultValue={remark}
                                    autoComplete="off"
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("ATTACHMENT")}</b></label>
                            <div className="col-md-8">
                                <a href="javascript:;" className="btn btn-danger btn-icon btn-circle" style={{ position: "absolute", left: "0%", top: "0%", margin: "5px 5px 5px 15px" }}
                                    onClick={() => {
                                        setSupportingDocumentFile("");
                                        setSupportingDocumentFileName("");
                                    }}><i className="fa fa-times"></i></a>
                                <Dropzone accept={'.png,.jpg,.jpeg,.pdf'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div className="dropzone" style={{ textAlign: "center" }} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <b style={{ color: "grey", margin: "10px" }}>{stringIsNullOrEmpty(supportingDocumentFileName) ? t("DROPZONE_MESSAGE") : supportingDocumentFileName}</b>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                        {!isEndOfWork && <div className="form-group row m-b-15">
                            <label className="col-md-4 col-form-label"><b>{t("END_EXISTING_TASK")}</b></label>
                            <div className="col-md-8">
                                <div className="switcher">
                                    <input type="checkbox" name="endExistingTask" id="endExistingTask"
                                        onChange={(e) => {
                                            setEndExistingTask(e.target.checked);
                                        }}
                                        value={true}
                                        checked={endExistingTask}
                                        ref={register} />
                                    <label htmlFor="endExistingTask"></label>
                                </div>
                            </div>
                        </div>}
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                        <button type="button" className="btn btn-danger" onClick={() => { setIsWorkLogModalVisible(!isWorkLogModalVisible); }}>{t('BTN_CLOSE')}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
}

export default ManageTask;