import { createStore, applyMiddleware, combineReducers } from "redux";
import authReducer from "./redux/AuthReducer";
import appReducer from "./redux/AppReducer";
import thunk from 'redux-thunk';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const store = createStore(combineReducers({
    authState: authReducer,
    appState: appReducer,
}), applyMiddleware(thunk));

export default store;