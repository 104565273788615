import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import DualListBox from 'react-dual-listbox';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ReactTable from "../../components/custom/ReactTable";
import { ApiKey, ApiUrl, Role, DataName } from "../../util/Constant";
import { stringIsNullOrEmpty, getValidationMessage, arrayGroupByKey } from "../../util/Util";
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine.js';
import { useDispatch, useSelector } from 'react-redux';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import classnames from 'classnames';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const CustomerVisitSummaryReport = props => {
    const { t } = useTranslation();
    var _dispatch = useDispatch();
    const { register, handleSubmit, setValue, errors } = useForm();
    const [apiUrl, setApiUrl] = useState('');
    const [selectedUser, setSelectedUser] = useState([]);
    const [userOption, setUserOption] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').format("YYYY MMM"));
    const [endDate, setEndDate] = useState(moment().endOf('month').format("YYYY MMM"));
    const [postData, setPostData] = useState({});

    var _dispatch = useDispatch();

    let _tableColumns = useMemo(() => {
        let columns = [
            {
                Header: "SERVICER",
                accessor: "salesName",
                disableSortBy: true
            },
            {
                Header: "CUSTOMER",
                accessor: "customerName",
                disableSortBy: true
            },
        ];

        const currentMonth = moment(startDate, "YYYY MMM");
        while (currentMonth.isBefore(moment(endDate, "YYYY MMM")) || currentMonth.isSame(moment(endDate, "YYYY MMM"), 'month')) {
            columns.push({
                Header: currentMonth.format("YYYY MMM"),
                accessor: currentMonth.format("YYYYMM"),
                disableSortBy: true,
                Cell: (data) => {
                    return data.value || "-";
                },
            })

            currentMonth.add(1, 'month');
        }

        return columns;
    }, [ startDate, endDate ])


    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    useEffect(() => {
        register({ name: 'startDate' }, { required: true });
        register({ name: 'user' });
        register({ name: 'endDate' }, { required: true });

        setValue('user', []);
        setValue('startDate', moment().startOf('month').format("YYYY-MM-DD HH:mm:ss"));
        setValue('endDate', moment().endOf('month').format("YYYY-MM-DD HH:mm:ss"));
        init();
    }, []);

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    async function init() {
        try {
            _dispatch(setBusy());
            const responseJson = await ApiEngine.get(ApiUrl._API_GET_USERS + "?includeSelf=true&role=" + Role._ROLE_WITH_CUSTOMER);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var userListByRole = arrayGroupByKey(responseJson[ApiKey._API_DATA_KEY], "roleName");
                var grouppedUserList = [];

                for (var key in userListByRole) {
                    var userList = [];

                    userListByRole[key].map(user => {
                        userList.push({ label: user.userFullName, value: user.id });
                    });

                    grouppedUserList.push({
                        label: key,
                        value: key,
                        options: userList
                    })
                }

                setUserOption(grouppedUserList);
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            _dispatch(setIdle());
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const submitForm = async (data, e) => {
        var params = {
            "StartDate": data.startDate,
            "EndDate": data.endDate,
            "User": data.user,
            "IsValidVisit": parseInt(data.isValidVisit)
        };

        setPostData(params);
        setApiUrl(ApiUrl._API_GET_CUSTOMER_VISIT_SUMMARY_REPORT);
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    const PaginationTable = useMemo(() => <ReactTable columns={_tableColumns} fetchUrl={apiUrl} postData={postData} exportRequired={true} fileName={t("CUSTOMER_VISIT_SUMMARY_REPORT")} />, [apiUrl, postData])

    return (
        <div>
            <h1 className="page-header">{t("CUSTOMER_VISIT_SUMMARY_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="row m-b-10">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("START_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="startDate"
                                        dateFormat="YYYY MMM"
                                        closeOnSelect={true}
                                        value={startDate}
                                        timeFormat={false}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setStartDate(e.startOf('month').format("YYYY MMM"));
                                                setValue("startDate", e.startOf('month').format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("startDate", "");
                                            }
                                        }} />
                                    {errors.startDate && <div className="invalid-feedback">{t(getValidationMessage(errors.startDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("END_DATE")}</label>
                                    <DateTime
                                        inputProps={{ className: 'form-control bg-white', readOnly: true }}
                                        name="endDate"
                                        dateFormat="YYYY MMM"
                                        closeOnSelect={true}
                                        value={endDate}
                                        isValidDate={(e) => {
                                            return !e.isBefore(moment(startDate, "YYYY MMM").startOf("month"));
                                        }}
                                        timeFormat={false}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setEndDate(e.endOf('month').format("YYYY MMM"));
                                                setValue("endDate", e.endOf('month').format("YYYY-MM-DD HH:mm:ss"));
                                            }
                                            else {
                                                setValue("endDate", "");
                                            }
                                        }} />
                                    {errors.endDate && <div className="invalid-feedback">{t(getValidationMessage(errors.endDate))}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("VISIT_VALIDITY")}</label>
                                    <select name="isValidVisit" ref={register} className="form-control">
                                        <option value={-1}>{t("ALL")}</option>
                                        <option value={1}>{t("VALID")}</option>
                                        <option value={0}>{t("INVALID")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-10">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>{t("SERVICER")}</label>
                                    <DualListBox
                                        canFilter
                                        name="user"
                                        options={userOption}
                                        selected={selectedUser}
                                        onChange={(e) => {
                                            setValue('user', e);
                                            setSelectedUser(e);
                                        }}
                                        ref={register}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <button type="submit" className="btn btn-primary">{t('APPLY_FILTER')}</button>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Panel>
                <PanelBody>
                    {PaginationTable}
                </PanelBody>
            </Panel>
        </div>
    );
};

export default CustomerVisitSummaryReport;