import React, { useRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { stringIsNullOrEmpty, createMultiPartFormBody, navigateTo, getValidationMessage } from "../../util/Util";
import { ApiKey, ApiUrl, AlertTypes, AccessRight, Status, DataName } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { showMessage } from '../../redux/AppAction';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine.js';
import moment from 'moment';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

/// <summary>
/// Author : Yong Sheng Chuan
/// </summary>
const CompleteLead = (props) => {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, errors, setValue, unregister, watch } = useForm();
    var _dispatch = useDispatch();

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// </summary>
    function onFinish(status) {
        if (props.onFinish) {
            props.onFinish(status)
        }
    }

    /// <summary>
    /// Author : Yong Sheng Chuan
    /// <summary>
    const submitForm = async (data, e) => {
        let formCollection = {
            userRemark: data["remark"],
            isComplete: true,
            id: props.leadId
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_LEAD, createMultiPartFormBody(formCollection));

        onFinish(responseJson[ApiKey._API_SUCCESS_KEY]);

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY])
        }));
    }

    return (
        <Modal isOpen={props.isModalVisible} toggle={() => { onFinish() }}>
            <form onSubmit={handleSubmit(submitForm)}>
                <ModalHeader toggle={() => { onFinish() }}>
                    <span>{t("COMPLETE_LEAD")}</span>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group row m-b-15">
                                <label className="col-md-4 col-form-label"><b>{t("REMARK")}</b></label>
                                <div className="col-md-8">
                                    <textarea type="text" className="form-control" name="remark" ref={register} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="submit" className="btn btn-success" style={{ marginRight: "10px" }}>{t("COMPLETE")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => { onFinish(); }}>{t('BTN_CANCEL')}</button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default CompleteLead